import React, { useState, useEffect, useRef } from 'react';
import './TruNorthDashboard/TruNorthDashboard.less';
import { formatUrl } from '../../libs/helper/HelperFunctions';

const IframeTest = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const oniframeLoad = () => {
    const cssPath = `${formatUrl(window.location.origin)}/externalTheme.css`;
    const cssPath1 = `${formatUrl(window.location.origin)}/externalTheme1.css`;
    iframeRef?.current?.contentWindow?.postMessage(
      cssPath1,
      'https://test-iframe.dca.deloitte.com/',
    );
    let link = document.createElement('link');
    link.href = cssPath; /**** your CSS file ****/
    link.rel = 'stylesheet';
    link.type = 'text/css';
    frames[0].document.head.appendChild(link); /**** 0 is an index of your iframe ****/
  };

  return (
    <div className="trunorth-dashboard-wrapper">
      <iframe
        ref={iframeRef}
        id="assess"
        className="assess-iframe"
        title="Assess"
        src="https://test-iframe.dca.deloitte.com/"
        onLoad={() => {
          oniframeLoad();
        }}
      />
    </div>
  );
};

export default IframeTest;
