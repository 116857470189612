import { Reducer } from 'redux';
import {
  I5YrPlDataResponse,
  IAnalyticsChartResponse,
  IComputeCostDataResponse,
  IDimensionList,
  IStorageCostDataResponse,
} from '../../models/AnalyticsModels';
import { AnalyticsAction, AnalyticsActionTypes } from '../actions/AnalyticsActions';
import AnalyticsState, { IDimensionState } from '../state/AnalyticsState';

const defaultChartDetails: IAnalyticsChartResponse = {
  bauPerc: 0,
  expense: 0,
  mapOfBAUCost: {
    Compute: 0,
    Database: 0,
    Labor: 0,
    Network: 0,
    Storage: 0,
  },
  mapOfCloudCost: {
    Compute: 0,
    Database: 0,
    Labor: 0,
    Network: 0,
    Storage: 0,
  },
  totalBAUCost: 0,
  variance: 0,
  awsPerc: 0,
  gcpPerc: 0,
  totalAWSCost: 0,
  totalGCPCost: 0,
};

const defaultFiveYearPlData: I5YrPlDataResponse = {
  baseYear: '',
  profitAndLossMap: {},
};

const defaultComputeCostData: IComputeCostDataResponse = {
  baseYear: '',
  listofBAUCompute: [],
  listofCloudCompute: [],
};

const defaultStorageCostData: IStorageCostDataResponse = {
  baseYear: '',
  listofBAUStorage: [],
  listofCloudStorage: [],
};

export const initialState: AnalyticsState = {
  error: '',
  loading: false,
  chartDetails: defaultChartDetails,
  bauVsCloudDataLoading : false,
  fiveYearPlData: defaultFiveYearPlData,
  computeCostData: defaultComputeCostData,
  storageCostData: defaultStorageCostData,
  assessmentDimensionsData: undefined,
  assessmentCategoriesData: undefined,
};

export const AnalyticsReducer: Reducer<AnalyticsState> = (
  state = initialState,
  action: AnalyticsAction,
) => {
  switch (action.type) {
    case AnalyticsActionTypes.GET_CHART_DATA_STARTED:
      return {
        ...state,
        bauVsCloudDataLoading: true,
        error: undefined,
      };
    case AnalyticsActionTypes.GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        bauVsCloudDataLoading: false,
        error: undefined,
        chartDetails: action.data as IAnalyticsChartResponse,
      };
    case AnalyticsActionTypes.GET_CHART_DATA_ERROR:
      return {
        ...state,
        bauVsCloudDataLoading: false,
        error: action.data,
        chartDetails: defaultChartDetails,
      };

    case AnalyticsActionTypes.GET_FIVE_YR_PL_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case AnalyticsActionTypes.GET_FIVE_YR_PL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        fiveYearPlData: action.data as I5YrPlDataResponse,
      };
    case AnalyticsActionTypes.GET_FIVE_YR_PL_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        fiveYearPlData: defaultFiveYearPlData,
      };

    case AnalyticsActionTypes.GET_COMPUTE_COST_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case AnalyticsActionTypes.GET_COMPUTE_COST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        computeCostData: action.data as IComputeCostDataResponse,
      };
    case AnalyticsActionTypes.GET_COMPUTE_COST_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        computeCostData: defaultComputeCostData,
      };

    case AnalyticsActionTypes.GET_STORAGE_COST_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case AnalyticsActionTypes.GET_STORAGE_COST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        storageCostData: action.data as IStorageCostDataResponse,
      };
    case AnalyticsActionTypes.GET_STORAGE_COST_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        storageCostData: defaultStorageCostData,
      };
    case AnalyticsActionTypes.GET_ASSESSMENT_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case AnalyticsActionTypes.GET_ASSESSMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        assessmentDimensionsData: action.data as IDimensionState,
      };
    case AnalyticsActionTypes.GET_ASSESSMENT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case AnalyticsActionTypes.GET_CATEGORIES_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case AnalyticsActionTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        assessmentCategoriesData: action.data as IDimensionState,
      };
    case AnalyticsActionTypes.GET_CHART_DATA_ERROR:
      return{
        ...state,
        loading: false,
        error: action.data,
      };
    case AnalyticsActionTypes.RESET_ANALYTICS_STATE_DATA:
      return{
        ...state,        
        fiveYearPlData: defaultFiveYearPlData,
        computeCostData: defaultComputeCostData,
        storageCostData: defaultStorageCostData,
        chartDetails: defaultChartDetails,
        
      }

    default:
      return state;
  }
};
