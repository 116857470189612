import React, { useState, useEffect } from 'react';
import Loader from '../../../components/Loader/Loader';
import '../TruNorthDashboard/TruNorthDashboard.less';
import { handleZoom } from '../../../libs/helper/HelperFunctions';
import { useDispatch } from 'react-redux';
import {
  IVisitedPagePayload,
  SectionType,
  TrafficAnalyticsActions,
} from '../../../libs/store/actions/WebAnalytics/TrafficAnalyticsActions';
import { IProduct } from '../../../libs/models/cbo/EngagementModels';
import { apiVaultDemoInstanceConfig } from '../KeyAssetsConstant';

interface IAssetProps {
  selectedAssetConfig?: IProduct;
  isEngagement?: boolean;
  selectedEnagagementName?: string;
  selectedEnagagementId?: string;
}

const ApiVaultDashboard = ({
  isEngagement,
  selectedAssetConfig,
  selectedEnagagementName,
  selectedEnagagementId,
}: IAssetProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const dispatcher = {
    postPageVisited: (data: IVisitedPagePayload) =>
      dispatch(TrafficAnalyticsActions.postVisitedPageStart(data)),
  };

  useEffect(() => {
    handleZoom('assess-iframe');
    dispatcher.postPageVisited({
      sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
      assetName: SectionType.APIVAULT,
      name: selectedEnagagementName,
      id: selectedEnagagementId,
    });
    return () => {
      dispatcher.postPageVisited({
        sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
        assetName: SectionType.APIVAULT,
        name: selectedEnagagementName,
        id: selectedEnagagementId,
      });
    };
  }, []);

  return (
    <div className="trunorth-dashboard-wrapper">
      <Loader loaderName="Authorizing..." loading={isLoading}>
        <iframe
          id="assess"
          className="assess-iframe"
          title="Assess"
          src={
            isEngagement
              ? selectedAssetConfig?.instanceUrl
              : selectedAssetConfig?.configDone
              ? selectedAssetConfig?.instanceUrl
              : apiVaultDemoInstanceConfig.demoInstanceUrl
          }
          onLoad={() => setIsLoading(false)}
        />
      </Loader>
    </div>
  );
};

export default ApiVaultDashboard;
