import { IStoreAction } from '../../StoreHelper';

export enum HitsAnalyticsActionTypes {
  GET_NEW_VS_RETURNING_USER_DATA_START = 'GET_NEW_VS_RETURNING_USER_DATA_START',
  GET_NEW_VS_RETURNING_USER_DATA_SUCCESS = 'GET_NEW_VS_RETURNING_USER_DATA_SUCCESS',
  GET_NEW_VS_RETURNING_USER_DATA_ERROR = 'GET_NEW_VS_RETURNING_USER_DATA_ERROR',

  GET_ACTIVE_USERS_DATA_START = 'GET_ACTIVE_USERS_DATA_START',
  GET_ACTIVE_USERS_DATA_SUCCESS = 'GET_ACTIVE_USERS_DATA_SUCCESS',
  GET_ACTIVE_USERS_DATA_ERROR = 'GET_ACTIVE_USERS_DATA_ERROR',

  GET_USERS_VISITS_DATA_START = 'GET_USERS_VISITS_DATA_START',
  GET_USERS_VISITS_DATA_SUCCESS = 'GET_USERS_VISITS_DATA_SUCCESS',
  GET_USERS_VISITS_DATA_ERROR = 'GET_USERS_VISITS_DATA_ERROR',

  GET_PAGE_VIEWS_DATA_START = 'GET_PAGE_VIEWS_DATA_START',
  GET_PAGE_VIEWS_DATA_SUCCESS = 'GET_PAGE_VIEWS_DATA_SUCCESS',
  GET_PAGE_VIEWS_DATA_ERROR = 'GET_PAGE_VIEWS_DATA_ERROR',

  GET_BOUNCE_RATE_DATA_START = 'GET_BOUNCE_RATE_DATA_START',
  GET_BOUNCE_RATE_DATA_SUCCESS = 'GET_BOUNCE_RATE_DATA_SUCCESS',
  GET_BOUNCE_RATE_DATA_ERROR = 'GET_BOUNCE_RATE_DATA_ERROR',
}

export interface IAnalyticsPieChartResponse {
  name: number;
  value: number;
}

export interface IChartValues {
  x: string;
  y: number;
}

export interface IAnalyticsAreaChartResponse {
  chartValues: IChartValues[];
  totalCount: number;
}
export interface IActiveUsersPayload {
  sortBy: string;
}

export type HitsAnalyticsActionPayload =
  | Error
  | string
  | IAnalyticsPieChartResponse[]
  | IAnalyticsAreaChartResponse
  | IActiveUsersPayload;

export type HitsAnalyticsAction = IStoreAction<
  HitsAnalyticsActionTypes,
  HitsAnalyticsActionPayload
>;

export class HitsAnalyticsActions {
  public static getNewVsReturningUsersStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_START,
    };
  }
  public static getNewVsReturningUsersSuccess(
    data: IAnalyticsPieChartResponse[],
  ): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_SUCCESS,
      data,
    };
  }
  public static getNewVsReturningUsersError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_ERROR,
      data: error,
    };
  }

  public static getActiveUserDataStart(data: IActiveUsersPayload): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_START,
      data,
    };
  }
  public static getActiveUserDataSuccess(data: IAnalyticsAreaChartResponse): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_SUCCESS,
      data,
    };
  }
  public static getActiveUserDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_ERROR,
      data: error,
    };
  }

  public static getUserVisitsDataStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_START,
    };
  }
  public static getUserVisitsDataSuccess(data: IAnalyticsAreaChartResponse): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_SUCCESS,
      data,
    };
  }
  public static getUserVisitsDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_ERROR,
      data: error,
    };
  }
  public static getPageViewsDataStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_START,
    };
  }
  public static getPageViewsDataSuccess(data: IAnalyticsAreaChartResponse): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_SUCCESS,
      data,
    };
  }
  public static getPageViewsDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_ERROR,
      data: error,
    };
  }

  public static getBounceRateDataStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_BOUNCE_RATE_DATA_START,
    };
  }
  public static getBounceRateDataSuccess(data: IAnalyticsAreaChartResponse): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_BOUNCE_RATE_DATA_SUCCESS,
      data,
    };
  }
  public static getBounceRateDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_BOUNCE_RATE_DATA_ERROR,
      data: error,
    };
  }
}
