import {
  I5YrPlDataResponse,
  IAnalyticsChartResponse,
  IChartPayload,
  IComputeCostDataResponse,
  IDimensionList,
  IStorageCostDataResponse,
} from '../../models/AnalyticsModels';
import { IDimensionState } from '../state/AnalyticsState';
import { IStoreAction } from '../StoreHelper';

export enum AnalyticsActionTypes {
  GET_CHART_DATA_STARTED = 'GET_CHART_DATA_REQUEST',
  GET_CHART_DATA_SUCCESS = 'GET_CHART_DATA_SUCCESS',
  GET_CHART_DATA_ERROR = 'GET_CHART_DATA_ERROR',
  GET_FIVE_YR_PL_DATA_STARTED = 'GET_FIVE_YR_PL_DATA_STARTED',
  GET_FIVE_YR_PL_DATA_SUCCESS = 'GET_FIVE_YR_PL_DATA_SUCCESS',
  GET_FIVE_YR_PL_DATA_ERROR = 'GET_FIVE_YR_PL_DATA_ERROR',
  GET_COMPUTE_COST_DATA_STARTED = 'GET_COMPUTE_COST_DATA_STARTED',
  GET_COMPUTE_COST_DATA_SUCCESS = 'GET_COMPUTE_COST_DATA_SUCCESS',
  GET_COMPUTE_COST_DATA_ERROR = 'GET_COMPUTE_COST_DATA_ERROR',
  GET_STORAGE_COST_DATA_STARTED = 'GET_STORAGE_COST_DATA_STARTED',
  GET_STORAGE_COST_DATA_SUCCESS = 'GET_STORAGE_COST_DATA_SUCCESS',
  GET_STORAGE_COST_DATA_ERROR = 'GET_STORAGE_COST_DATA_ERROR',
  GET_ASSESSMENT_DETAILS_STARTED = 'GET_ASSESSMENT_DETAILS_STARTED',
  GET_ASSESSMENT_DETAILS_SUCCESS = 'GET_ASSESSMENT_DETAILS_SUCCESS',
  GET_ASSESSMENT_DETAILS_ERROR = 'GET_ASSESSMENT_DETAILS_ERROR',
  GET_CATEGORIES_STARTED = 'GET_CATEGORIES_STARTED',
  GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR',
  
  RESET_ANALYTICS_STATE_DATA = 'RESET_ANALYTICS_STATE_DATA',
}

export type AnalyticsActionPayload =
  | IChartPayload
  | Error
  | IAnalyticsChartResponse
  | I5YrPlDataResponse
  | IComputeCostDataResponse
  | IStorageCostDataResponse
  | IDimensionState
  | string
  | null;

export type AnalyticsAction = IStoreAction<AnalyticsActionTypes, AnalyticsActionPayload>;
export class AnalyticsActions {
  public static getChartStart(data: IChartPayload): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_CHART_DATA_STARTED,
      data,
    };
  }
  public static getChartSuccess(data: IAnalyticsChartResponse): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_CHART_DATA_SUCCESS,
      data,
    };
  }
  public static getChartError(error: Error): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_CHART_DATA_ERROR,
      data: error,
    };
  }

  public static get5YrPlDataStart(data: IChartPayload): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_FIVE_YR_PL_DATA_STARTED,
      data,
    };
  }
  public static get5YrPlDataSuccess(data: I5YrPlDataResponse): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_FIVE_YR_PL_DATA_SUCCESS,
      data,
    };
  }
  public static get5YrPlDataError(error: Error): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_FIVE_YR_PL_DATA_ERROR,
      data: error,
    };
  }

  public static getComputeCostDataStart(data: IChartPayload): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_COMPUTE_COST_DATA_STARTED,
      data,
    };
  }
  public static getComputeCostDataSuccess(data: IComputeCostDataResponse): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_COMPUTE_COST_DATA_SUCCESS,
      data,
    };
  }
  public static getComputeCostDataError(error: Error): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_COMPUTE_COST_DATA_ERROR,
      data: error,
    };
  }

  public static getStorageCostDataStart(data: IChartPayload): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_STORAGE_COST_DATA_STARTED,
      data,
    };
  }
  public static getStorageCostDataSuccess(data: IStorageCostDataResponse): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_STORAGE_COST_DATA_SUCCESS,
      data,
    };
  }
  public static getStorageCostDataError(error: Error): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_STORAGE_COST_DATA_ERROR,
      data: error,
    };
  }
  public static getAssessmentDataStart(data: string): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_ASSESSMENT_DETAILS_STARTED,
      data,
    };
  }
  public static getAssessmentDataSuccess(data: IDimensionState): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_ASSESSMENT_DETAILS_SUCCESS,
      data,
    };
  }
  public static getAssessmentDataError(error: Error): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_ASSESSMENT_DETAILS_ERROR,
      data: error,
    };
  }
  public static getCategoriesDataStart(data: string): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_CATEGORIES_STARTED,
      data,
    };
  }
  public static getCategoriesDataSuccess(data: IDimensionState): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_CATEGORIES_SUCCESS,
      data,
    };
  }
  public static getCategoriesDataError(error: Error): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.GET_CHART_DATA_ERROR,
      data: error,
    };
  }

  public static resetAnalyticsStateData(): AnalyticsAction {
    return {
      type: AnalyticsActionTypes.RESET_ANALYTICS_STATE_DATA,
    };
  }
}
