import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import {
  IEngagementConfigurationResponse,
  IEngagementConfigurationPayload,
  IEngagementConfigurationSaveResponse,
  IPredefinedJourneyResponse,
  IADAccessTokenResponse,
  IKeyAssetsIconResponseListObject,
  IProduct,
  IConversionPayload,
  IConfigurePayload,
  IEngagementListForAdminRequestPayload,
  IEngagementResponse,
  IEngagementListForAdminResponse,
  IValidatePursuitPayload,
  IValidatePursuitResponse,
  IJourneyCapabilitiesResponse,
} from '../../../models/cbo/EngagementModels';
import EngagementService from '../../../services/cbo/EnagementService/EngagementService';
import {
  EngagementAction,
  EngagementActions,
  EngagementActionTypes,
} from '../../actions/cbo/EngagementAction';
import { ToastActions } from '../../actions/ToastActions';
import { IMarketplaceAssetPayload } from '../../../models/cbo/CboMarketplaceModels';
import { storage, STORAGE_CONSTANTS } from '../../../services/LocalStorage';
import engagementService from '../../../services/cbo/EnagementService/EngagementService';
import { EngagementSummaryActions } from '../../actions/cbo/EngagementSummaryAction';

export function* getPredefinedJourneyListWorker(action: EngagementAction) {
  try {
    const response: IPredefinedJourneyResponse[] =
      (yield EngagementService.getPredefinedJourneyList())!;
    yield put(EngagementActions.getPredefinedJourneyListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.getPredefinedJourneyListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getKeyAssetListWorker(action: EngagementAction) {
  try {
    const data = action.data as IMarketplaceAssetPayload;
    const response: IProduct[] = (yield EngagementService.getKeyAssetList(data))!;
    yield put(EngagementActions.getKeyAssetsListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.getKeyAssetsListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getJourneyCapabilityDataWorker(action: EngagementAction) {
  try {
    const data = action.data as string[];
    const response: IJourneyCapabilitiesResponse = (yield EngagementService.getJourneyCapability(
      data,
    ))!;
    yield put(EngagementActions.getjourneyCapabilityDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.getjourneyCapabilityDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getEngagementConfigDataWorker(action: EngagementAction) {
  try {
    const response: IEngagementConfigurationResponse =
      yield EngagementService.getEngagementConfiguration(action.data as IConfigurePayload)!;
    yield put(
      EngagementActions.getEngagementConfigDataSuccess(
        response,
        (action.data as IConfigurePayload).isEngagement ?? false,
      ),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.getEngagementConfigDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* saveEngagementConfigDataWorker(action: EngagementAction) {
  try {
    //const { data } = action;
    const response: IEngagementConfigurationSaveResponse =
      (yield EngagementService.saveEngagementConfiguration(
        action.data as IEngagementConfigurationPayload,
      ))!;
    yield put(EngagementActions.saveEngagementConfigDataSuccess(response));
    yield put(
      ToastActions.showToast({
        description: 'Pursuit Configuration saved successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.saveEngagementConfigDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getADAccessToken(action: EngagementAction) {
  try {
    const data = action.data as string;
    const response: IADAccessTokenResponse = yield EngagementService.getADAccessToken(data);
    yield put(EngagementActions.getADAccessTokenSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.getADAccessTokenError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getKeyAssetsIconListWorker(action: EngagementAction) {
  try {
    const response: IKeyAssetsIconResponseListObject[] =
      yield EngagementService.getKeyAssetsIconList();
    yield put(EngagementActions.getKeyAssetsIconListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.getKeyAssetsIconListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description:
            'Something went wrong while fetching Key Assets Icon list! Please close the drawer and try gain! ',
          // description: 'Something went wrong while fetching Key Assets Icon! Please close the drawer and try gain! ' + error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* saveConversionDataWorker(action: EngagementAction) {
  try {
    // const { data } = action;
    const userGroup = storage.getItem(STORAGE_CONSTANTS.userGroup)
      ? storage.getItem(STORAGE_CONSTANTS.userGroup)?.split(',') ?? ['']
      : [''];
    const data = action.data as IConversionPayload;
    yield EngagementService.postConversionData(data as IConversionPayload);
    yield put(
      ToastActions.showToast({
        description: data.isEngagement
          ? 'Engagement deleted successfully'
          : !data.isEngagement && data.stage == 'won'
          ? 'Pursuit is converted to engagement successfully'
          : 'Pursuit status is updated successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    yield put(EngagementActions.getConversionDataSuccess());
    if (data.isEngagement || data.stage !== 'won') {
      if (!(userGroup.indexOf('ADMIN') > -1)) {
        try {
          const engagementList: IEngagementResponse = yield engagementService.getEngagementList(
            data.isEngagement ?? false,
          );
          yield put(
            EngagementSummaryActions.getEngagemenSuccess(
              engagementList,
              (action.data as IConversionPayload)?.isEngagement ?? false,
            ),
          );
        } catch (error) {
          if (error instanceof Error) {
            yield put(EngagementSummaryActions.getEngagementError(error));
          }
        }
      } else {
        try {
          const engagementListForAdmin: IEngagementListForAdminResponse =
            yield engagementService.getEngagementListForAdmin({
              limit: 10,
              page: 1,
              isEngagement: data.isEngagement ?? false,
            } as IEngagementListForAdminRequestPayload);
          yield put(
            EngagementSummaryActions.getEngagementListForAdminSuccess(
              engagementListForAdmin,
              (action.data as IConversionPayload)?.isEngagement ?? false,
            ),
          );
        } catch (error) {
          if (error instanceof Error) {
            yield put(EngagementSummaryActions.getEngagementListForAdminError(error));
            yield put(
              ToastActions.showToast({
                message: 'Error!!!',
                description: error.message,
                type: NotificationTypes.ERROR,
              }),
            );
          }
        }
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.getConversionDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getDomainListDataWorker(action: EngagementAction) {
  try {
    const response: String[] = (yield EngagementService.getDomailList())!;
    yield put(EngagementActions.getDomainListDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.getDomainListDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

/**
 * Worker function for validating pursuit name.
 * CBO-3027_AI_AUTO_ENHANCE_CODE
 * @param action - The engagement action.
 */
export function* validatePursuitNameWorker(action: EngagementAction) {
  try {
    const response: IValidatePursuitResponse = (yield EngagementService.validatePursuitName(
      action.data as IValidatePursuitPayload,
    ))!;
    yield put(EngagementActions.validatePursuitNameSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementActions.validatePursuitNameError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* engagementWatcher() {
  yield takeEvery(
    EngagementActionTypes.GET_CE_PREDEFINED_JOURNEY_LIST_STARTED,
    getPredefinedJourneyListWorker,
  );
  yield takeEvery(
    EngagementActionTypes.GET_JOURNEYCAPABILITY_DATA_STARTED,
    getJourneyCapabilityDataWorker,
  );
  yield takeEvery(
    EngagementActionTypes.GET_ENGAGEMENTCONFIG_DATA_STARTED,
    getEngagementConfigDataWorker,
  );
  yield takeEvery(
    EngagementActionTypes.SAVE_ENGAGEMENTCONFIG_DATA_STARTED,
    saveEngagementConfigDataWorker,
  );
  yield takeEvery(EngagementActionTypes.GET_AD_ACCESS_TOKEN_STARTED, getADAccessToken);
  yield takeEvery(
    EngagementActionTypes.GET_KEY_ASSETS_ICONS_LIST_STARTED,
    getKeyAssetsIconListWorker,
  );
  yield takeEvery(EngagementActionTypes.GET_KEY_ASSETS_LIST_STARTED, getKeyAssetListWorker);
  yield takeEvery(EngagementActionTypes.GET_CONVERSION_DATA_STARTED, saveConversionDataWorker);
  yield takeEvery(EngagementActionTypes.GET_DOMAIN_DATA_STARTED, getDomainListDataWorker);
  yield takeEvery(EngagementActionTypes.VALIDATE_PURSUIT_NAME_STARTED, validatePursuitNameWorker);
}
