import { put, takeEvery } from 'redux-saga/effects';
import RBACService from '../../../services/cbo/RBACService/RBACService';
import {
  RBACAction, RBACActions, RBACActionTypes,
} from '../../actions/cbo/RBACActions';
import { ToastActions } from "../../actions/ToastActions";
import { NotificationTypes } from "../../../../components/Notifications/NotificationsConstants";

export function* getRBACDataWorker(action: RBACAction) {
  try {
    const response: string[] = yield RBACService.getRBACData(action.data as string[]);
    yield put(RBACActions.getRBACDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(RBACActions.getRBACDataError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* rbacWatcher() {
  yield takeEvery(RBACActionTypes.GET_RBAC_DATA_STARTED, getRBACDataWorker);
}
