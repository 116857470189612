import Modal from 'antd/lib/modal/Modal';
import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { shallowEqual, useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { logoutModalText, timeOutTime } from '../../AppConstants';
import HomePage from '../../containers/HomePage/HomePage';
import { convertImageToBase64, isAuthenticated } from '../../libs/helper/HelperFunctions';
import { STORAGE_CONSTANTS, storage } from '../../libs/services/LocalStorage';
import { LoginActions } from '../../libs/store/actions/LoginActions';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { RBACActions } from '../../libs/store/actions/cbo/RBACActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { RefreshTokenActions } from '../../libs/store/actions/cbo/RefreshTokenActions';
import AxiosInstance from '../../libs/services/AxiosInstance';
import { EngagementActions } from '../../libs/store/actions/cbo/EngagementAction';

interface IPrivateRouteProps {
  children: React.ReactNode;
  hideHomePage?: boolean;
  isMobile?: boolean;
  showProjectSelector?: boolean;
  pageTitle?: string;
}
const PrivateRoute = ({
  children,
  hideHomePage,
  isMobile = false,
  showProjectSelector = true,
  pageTitle = '',
}: IPrivateRouteProps) => {
  const instance = AxiosInstance.create();
  const [visible, setVisible] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState(
    storage.getItem(STORAGE_CONSTANTS.accessToken),
  );
  // const [truNorthCredFetched,setTruNorthCredFetched] =
  const dispatch = useDispatch();
  const history = useHistory();

  const { refreshData, adAccessTokenData } = useAppSelector(
    ({ refreshData: { refreshData }, engagement: { adAccessToken } }: IApplicationState) => ({
      refreshData,
      adAccessTokenData: adAccessToken,
    }),
    shallowEqual,
  );

  const dispatcher = {
    postSessionToken: () => dispatch(LoginActions.sessionTokenPostStart()),
    logoutTimer: (data: boolean) => dispatch(SidebarActions.toggleLogout(data)),
    getUpdatedToken: (refreshToken: string) =>
      dispatch(RefreshTokenActions.refreshTokenStart(refreshToken)),
    getUserDetails: (data: string[]) => dispatch(RBACActions.getRBACDataStart(data)),
    getADToken: (adRefreshToken: string) =>
      dispatch(EngagementActions.getADAccessTokenStart(adRefreshToken)),
  };

  const getADToken = (adRefreshToken: string) => dispatcher.getADToken(adRefreshToken);

  const onIdle = () => {
    setVisible(true);
  };

  const handleLogout = () => {
    dispatcher.logoutTimer(false);
    storage.clearAll();
    history.replace('/');
  };

  const idleTimer = useIdleTimer({ onIdle, timeout: timeOutTime }); // calling the session

  const isTokenExpired = (token: string) => {
    const decodedAccessToken: IDecodedToken = jwt_decode(token);
    return decodedAccessToken.exp < Math.round(Date.now() / 1000);
  };

  const getUserDetails = (data: string[]) => dispatcher.getUserDetails(data);

  /**
   * function to call AD graph API to get the user photo
   *
   * @param {string} userMail
   */
  const getADUserPhoto = () => {
    const adAccessToken = storage.getItem(STORAGE_CONSTANTS.adAccessToken) ?? '';
    const userEmail = storage.getItem(STORAGE_CONSTANTS.email) ?? '';
    const adUserPhotoURL = `https://graph.microsoft.com/v1.0/users/${userEmail}/photos/48x48/$value`;

    instance
      .get(adUserPhotoURL, {
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${adAccessToken}` },
      })
      .then((response) => {
        const { data } = response;
        convertImageToBase64(data)
          .then((base64String) => {
            storage.setItem(STORAGE_CONSTANTS.useProfilePic, base64String);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (accessToken) {
      if (!isTokenExpired(accessToken)) {
        const userGroup = storage.getItem(STORAGE_CONSTANTS.userGroup)
          ? (storage.getItem(STORAGE_CONSTANTS.userGroup)?.split(',') ?? [''])
          : [''];
        getUserDetails(userGroup);
      }
    }
  }, [accessToken]);

  React.useEffect(() => {
    if (storage.getItem(STORAGE_CONSTANTS.adRefreshToken)) {
      const adRefreshToken = storage.getItem(STORAGE_CONSTANTS.adRefreshToken) ?? '';
      getADToken(adRefreshToken);
    }
  }, []);

  React.useEffect(() => {
    if (adAccessTokenData?.access_token) {
      storage.setItem(STORAGE_CONSTANTS.adAccessToken, adAccessTokenData?.access_token ?? '');
      getADUserPhoto();
    }
  }, [adAccessTokenData]);

  type IDecodedToken = {
    auth_time: number;
    client_id: string;
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    scope: string;
    sub: string;
    token_use: string;
    username: string;
    version: number;
  };

  return (
    <>
      <Route
        render={(props) =>
          isAuthenticated() ? (
            hideHomePage ? (
              children
            ) : (
              <HomePage>{children}</HomePage>
            )
          ) : (
            <Redirect
              to={{
                pathname: '/cbo-login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
      {visible && (
        <Modal
          visible={visible}
          onOk={() => {
            setVisible(false);
          }}
          onCancel={handleLogout}
          okText="Stay Logged In"
          cancelText="Log Out"
          closable={false}
          className="logout-modal"
          maskClosable={false}
        >
          {logoutModalText}
        </Modal>
      )}
    </>
  );
};

export default PrivateRoute;
