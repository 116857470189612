import { IRefreshTokenResponse } from '../../state/cbo/RefreshTokenState';
import { IStoreAction } from '../../StoreHelper';

export enum RefreshTokenActionTypes {
    REFRESH_TOKEN_STARTED = 'REFRESH_TOKEN_STARTED',
    REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR',
}
export type RefreshTokenActionPayload =
  | Error
  | null
  | IRefreshTokenResponse
  | string

export type RefreshTokenAction = IStoreAction<RefreshTokenActionTypes, RefreshTokenActionPayload>;
 export class RefreshTokenActions {
    public static refreshTokenStart(data: string ): RefreshTokenAction {
      return {
        type: RefreshTokenActionTypes.REFRESH_TOKEN_STARTED,
        data
      };
    }
    public static refreshTokenSuccess(data: IRefreshTokenResponse): RefreshTokenAction {
      return {
        type: RefreshTokenActionTypes.REFRESH_TOKEN_SUCCESS,
        data,
      };
    }
    public static refreshTokenError(error: Error): RefreshTokenAction {
      return {
        type: RefreshTokenActionTypes.REFRESH_TOKEN_ERROR,
        data: error,
      };
    }
 }
