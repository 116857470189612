import React from 'react';
import JoyRide, { Step } from 'react-joyride';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { joyRideGlobalStyles } from './ProductTourConstants';
import Tooltip from './Tooltip';
import { adminTourStepsOnly, tourSteps } from './TourSteps';

interface IProductTourProps {
  startTour: boolean;
}

/**
 * The ProductTour component renders a guided tour of the application's features.
 * It uses the react-joyride library to display tooltips and step-by-step instructions.
 *
 * @param {IProductTourProps} props - The component props.
 * @param {boolean} props.startTour - Indicates whether the tour should be started.
 * @returns {JSX.Element} The rendered ProductTour component.
 */
const ProductTour = ({ startTour }: IProductTourProps): JSX.Element => {
  const { userDetails } = useAppSelector(({ user: { userDetails } }: IApplicationState) => ({
    userDetails,
  }));
  let userTourSteps: Step[];

  if (!userDetails?.user_permissions.add_account) {
    userTourSteps = tourSteps.filter((toursteps) =>
      adminTourStepsOnly.every((steps) => steps !== toursteps.title),
    );
  } else {
    userTourSteps = tourSteps;
  }

  return (
    <>
      <JoyRide
        steps={userTourSteps}
        continuous
        tooltipComponent={Tooltip}
        showProgress
        styles={joyRideGlobalStyles}
        run={startTour}
        disableScrolling={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
      />
    </>
  );
};

export default ProductTour;
