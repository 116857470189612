/**
 * This file contains the definition of tour steps for the product tour in the CBO platform.
 * It exports an array of `Step` objects that represent each step in the tour.
 */

import { Step } from 'react-joyride';
export const adminTourStepsOnly = ['Messages'];
export const tourSteps: Step[] = [
  {
    title: `Profile`,
    target: '.tour-profile',
    content:
      'Profile contains a brief description of your name, setting and roles in the CBO platform',
    disableBeacon: true,
  },
  {
    title: `Notification`,
    target: '.tour-notification',
    content:
      'User has Critical information when they are engaged in time-sensitive tasks. The main goals for users quickly and clearly and display their status.',
    disableBeacon: true,
  },
  {
    title: `Add Pursuit`,
    target: '.tour-add-pursuit',
    content:
      'Personalize your pursuit with a pre-defined journey and corresponding capabilities under Advise, Implement and Operate philosophy',
    disableBeacon: true,
  },
  {
    title: `Search`,
    target: '.tour-search',
    content: 'Search your pursuits from the pursuit listing dashboard',
    disableBeacon: true,
  },
  {
    title: `Pursuit Dashboard`,
    target: '.tour-cboDashboard',
    content: 'View all your pursuits based on your configuration.',
    disableBeacon: true,
  },
  {
    title: `Authorization`,
    target: '.tour-authorization',
    content: 'Authorize all the assets, you want to start using',
    disableBeacon: true,
  },
  {
    title: `Settings`,
    target: '.tour-settings',
    content:
      'Manage the Client, Pursuit, User Configurations, Master Data, User Client Mappings, Journey Builder and Web Analytics',
    disableBeacon: true,
  },
  {
    title: `Documentation`,
    target: '.tour-documentation',
    content: 'Upload documents for your reference',
    disableBeacon: true,
  },
  {
    title: `Marketplace`,
    target: '.tour-marketplace',
    content:
      'Refer to all the supporting documents, quals, presentation and toolkits for your selected pre-defined journey.',
    disableBeacon: true,
  },
  {
    title: `Actions`,
    target: '.tour-actions',
    content: 'Edit and delete your pursuit',
    disableBeacon: true,
  },
  {
    title: `View Summary`,
    target: '.tour-summary',
    content: 'View your summary dashboard for your journey.',
    disableBeacon: true,
  },
];
