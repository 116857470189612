import { put, takeEvery } from 'redux-saga/effects';
import {
  I5YrPlDataResponse,
  IAnalyticsChartResponse,
  IChartPayload,
  IComputeCostDataResponse,
  IStorageCostDataResponse,
} from '../../models/AnalyticsModels';
import AnalyticsService from '../../services/analytics/AnalyticsService';
import {
  AnalyticsAction,
  AnalyticsActions,
  AnalyticsActionTypes,
} from '../actions/AnalyticsActions';
import { IDimensionState } from '../state/AnalyticsState';

export function* getChartDataWorker(action: AnalyticsAction) {
  try {
    const data = action.data as IChartPayload;
    const response: IAnalyticsChartResponse = (yield AnalyticsService.getChartData(data))!;
    yield put(AnalyticsActions.getChartSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(AnalyticsActions.getChartError(error));
    }
  }
}

export function* get5YrPlDataWorker(action: AnalyticsAction) {
  try {
    const data = action.data as IChartPayload;
    const response: I5YrPlDataResponse = (yield AnalyticsService.get5YrPlData(data))!;
    yield put(AnalyticsActions.get5YrPlDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(AnalyticsActions.get5YrPlDataError(error));
    }
  }
}

export function* getComputeCostDataWorker(action: AnalyticsAction) {
  try {
    const data = action.data as IChartPayload;
    const response: IComputeCostDataResponse = (yield AnalyticsService.getComputeCostData(data))!;
    yield put(AnalyticsActions.getComputeCostDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(AnalyticsActions.getComputeCostDataError(error));
    }
  }
}

export function* getStorageCostDataWorker(action: AnalyticsAction) {
  try {
    const data = action.data as IChartPayload;
    const response: IStorageCostDataResponse = (yield AnalyticsService.getStorageCostData(data))!;
    yield put(AnalyticsActions.getStorageCostDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(AnalyticsActions.getStorageCostDataError(error));
    }
  }
}

export function* getDimensionsDataWorker(action: AnalyticsAction) {
  try {
    const projectId = action.data as string;
    const response: IDimensionState = (yield AnalyticsService.getAssessmentData(projectId))!;
    yield put(AnalyticsActions.getAssessmentDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(AnalyticsActions.getAssessmentDataError(error));
    }
  }
}

export function* getCategoriesDataWorker(action: AnalyticsAction) {
  try {
    const projectId = action.data as string;
    const response: IDimensionState = (yield AnalyticsService.getCategoriesData(projectId))!;
    yield put(AnalyticsActions.getCategoriesDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(AnalyticsActions.getCategoriesDataError(error));
    }
  }
}

export function* analyticsWatcher() {
  yield takeEvery(AnalyticsActionTypes.GET_CHART_DATA_STARTED, getChartDataWorker);
  yield takeEvery(AnalyticsActionTypes.GET_FIVE_YR_PL_DATA_STARTED, get5YrPlDataWorker);
  yield takeEvery(AnalyticsActionTypes.GET_COMPUTE_COST_DATA_STARTED, getComputeCostDataWorker);
  yield takeEvery(AnalyticsActionTypes.GET_STORAGE_COST_DATA_STARTED, getStorageCostDataWorker);
  yield takeEvery(AnalyticsActionTypes.GET_ASSESSMENT_DETAILS_STARTED, getDimensionsDataWorker);
  yield takeEvery(AnalyticsActionTypes.GET_CATEGORIES_STARTED, getCategoriesDataWorker);
}
