import { CBOKeyAssets } from '../../../containers/CBOSidebar/CBOSidebarConstants';
import {
  I5YrPlDataResponse,
  IAnalyticsChartResponse,
  IChartPayload,
  IComputeCostDataResponse,
  IStorageCostDataResponse,
} from '../../models/AnalyticsModels';
import { IProduct } from '../../models/cbo/EngagementModels';
import {  API_ROUTE_CBO } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { STORAGE_CONSTANTS, storage } from '../LocalStorage';
import { ServiceType, ServerType } from '../ServiceType.data';

const serviceType = ServiceType.TCO;

export class AnalyticsService {
  private readonly CWBservice: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);
  private readonly localService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.localStack);


  public getChartData(
    payload: IChartPayload,
  ): Promise<IAnalyticsChartResponse> | IAnalyticsChartResponse {
    const isEngagement = JSON.parse(storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false') as boolean;
    const keyAssetList = JSON.parse(storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]') as IProduct[];
    const cwbAsset =  keyAssetList?.find((keyAsset)=>keyAsset.product == CBOKeyAssets.cboCWBAsset);
    if (isEngagement) {
      return this.CWBservice.get({
        route: [`${API_ROUTE_CBO.GET_ENG_CHART_DATA.replace(':projectId', payload?.projectId!)}`]
      });
    } else {
        return this.CWBservice.get({
          route: [`${API_ROUTE_CBO.GET_PURSUIT_CHART_DATA.replace(':pursuitId', payload?.projectId!)}`]
        });
  }
  }

  public get5YrPlData(payload: IChartPayload): Promise<I5YrPlDataResponse> | I5YrPlDataResponse {
    const isEngagement = JSON.parse(storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false') as boolean;
    const keyAssetList = JSON.parse(storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]') as IProduct[];
    const cwbAsset =  keyAssetList?.find((keyAsset)=>keyAsset.product == CBOKeyAssets.cboCWBAsset);
    if (isEngagement) {
      return this.CWBservice.get({
        route: [`${API_ROUTE_CBO.GET_ENG_FIVE_YR_PL_DATA.replace(':projectId', payload?.projectId!)}`]
      });
    } else {
        return this.CWBservice.get({
          route: [`${API_ROUTE_CBO.GET_PURSUIT_FIVE_YR_PL_DATA.replace(':pursuitId', payload?.projectId!)}`]
        });
    }
  }
  


  public getComputeCostData(
    payload: IChartPayload,
  ): Promise<IComputeCostDataResponse> | IComputeCostDataResponse {
    const isEngagement = JSON.parse(storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false') as boolean;
    const keyAssetList = JSON.parse(storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]') as IProduct[];
    const cwbAsset =  keyAssetList?.find((keyAsset)=>keyAsset.product == CBOKeyAssets.cboCWBAsset);
    if (isEngagement) {
      return this.CWBservice.get({
        route: [`${API_ROUTE_CBO.GET_ENG_COMPUTE_COST_DATA.replace(':projectId', payload?.projectId!)}`]
      });
    } else {
        return this.CWBservice.get({
          route: [`${API_ROUTE_CBO.GET_PURSUIT_COMPUTE_COST_DATA.replace(':pursuitId', payload?.projectId!)}`]
        });
    }
  }

  public getStorageCostData(
    payload: IChartPayload,
  ): Promise<IStorageCostDataResponse> | IStorageCostDataResponse {
    const isEngagement = JSON.parse(storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false') as boolean;
    const keyAssetList = JSON.parse(storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]') as IProduct[];
    const cwbAsset =  keyAssetList?.find((keyAsset)=>keyAsset.product == CBOKeyAssets.cboCWBAsset);
    if (isEngagement) {
      return this.CWBservice.get({
        route: [`${API_ROUTE_CBO.GET_ENG_STORAGE_COST_DATA.replace(':projectId', payload?.projectId!)}`]
      });
    } else {
        return this.CWBservice.get({
          route: [`${API_ROUTE_CBO.GET_PURSUIT_STORAGE_COST_DATA.replace(':pursuitId', payload?.projectId!)}`]
        });
    }
  }

  public getAssessmentData(
    payload: string,
  ): Promise<IComputeCostDataResponse> | IComputeCostDataResponse {
    return this.CWBservice.get({
      route: [`${API_ROUTE_CBO.CWB.GET_ASSESSMENT_DATA}`],
    });
  }
  public getCategoriesData(
    payload: string,
  ): Promise<IComputeCostDataResponse> | IComputeCostDataResponse {
    const isEngagement = JSON.parse(storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false') as boolean;
    const keyAssetList = JSON.parse(storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]') as IProduct[];
    const cwbAsset =  keyAssetList?.find((keyAsset)=>keyAsset.product == CBOKeyAssets.cboCWBAsset);
    if(isEngagement){
      return this.CWBservice.get({
        route: [`${API_ROUTE_CBO.GET_ENG_CATEGORIES_DATA.replace(':projectId', cwbAsset?.engagementId!)}`]
      });
    }
    else{
    return this.CWBservice.get({
      route: [`${API_ROUTE_CBO.GET_PURSUIT_CATEGORIES_DATA.replace(':pursuitId', payload!)}`]
    });
  }
  }
}

const analyticsService = new AnalyticsService();
export default analyticsService;
