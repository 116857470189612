import { CBOKeyAssets } from '../../../containers/CBOSidebar/CBOSidebarConstants';
import {IHeatmapPayload, IHeatmapResponse} from '../../models/CloudSuitabilityModels';
import { IProduct } from '../../models/cbo/EngagementModels';
import {  API_ROUTE_CBO } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { STORAGE_CONSTANTS, storage } from '../LocalStorage';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.CSP;

export class CloudSuitabilityService {
  //private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.cwb);
  private readonly CWBservice: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);
  private readonly localService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.localStack);


  public getHeatmapData(payload: IHeatmapPayload): Promise<IHeatmapResponse> | IHeatmapResponse {
    const keyAssetList = JSON.parse(storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]') as IProduct[];
    const cwbAsset =  keyAssetList?.find((keyAsset)=>keyAsset.product == CBOKeyAssets.cboCWBAsset);
    const isEngagement = JSON.parse(storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false') as boolean;
    if (isEngagement) {
      return this.CWBservice.get({
        route: [`${API_ROUTE_CBO.GET_ENG_HEATMAP_DATA.replace(':projectId', payload?.projectId!)}`]
      });
    } else {
        return this.CWBservice.get({
          route: [`${API_ROUTE_CBO.GET_PURSUIT_HEATMAP_DATA.replace(':pursuitId', payload?.projectId!)}`]
        });
      
    }
  }
}

const cloudSuitabilityService = new CloudSuitabilityService();
export default cloudSuitabilityService;
