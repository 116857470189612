/// <summary>
/// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
export type IDecodedToken = {
  auth_time: number;
  client_id: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  scope: string;
  sub: string;
  token_use: string;
  username: string;
  version: number;
};
export enum ServiceType {
  Login = 'login',
  RefreshToken = 'icp/user/refreshToken',
  TCO = 'tco',
  CSP = 'csp',
  BuildDeploy = 'dca',
  user = 'user',
  DCP = 'dcp',
  None = '',
  Prod = 'Prod',
  OMA = 'oma',
  ANALYTICS = 'analytics',
  Mining = 'mining',
}

// Add base URLs and refresh token logic(if the server supports)
// in ApiServiceBase.ts for a new server added here
export enum ServerType {
  cbo = 'cboBE',
  cwb = 'CWB',
  dca = 'DCA',
  tangraDCA = 'TangraDCA',
  dcp = 'DCP',
  none = '',
  tangra = 'TangraBE',
  dcaAdmin = 'DCAAdmin',
  localStack = 'localStack',
  operateEdge = 'operateEdge',
  cboProxyServer = 'cboProxyServer',
}

export interface IApiAdditionalData {
  assetName: string;
}
