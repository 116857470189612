import { all, call, spawn } from 'redux-saga/effects';
import { analyticsWatcher } from './AnalyticsSaga';
import { buildDeployWatcher } from './BuildDeploySaga';
import { cloudSuitabilityWatcher } from './CloudSuitabilitySaga';
import { loginWatcher } from './LoginSaga';

import { userWatcher } from './UserSaga';
import { hitsAnalyticsWatcher } from './WebAnalyticsSaga/HitAnalyticsSaga';
import { trafficAnalyticsWatcher } from './WebAnalyticsSaga/TrafficAnalyticsSaga';
import { engagementWatcher } from './cbo/EngagementSaga';
import { engagementSummaryWatcher } from './cbo/EngagementSummarySaga';
import { cboViewSummaryWatcher } from './cbo/cboViewSummarySaga';
import { clientEngagementManagementWatcher } from './cbo/ClientEngagementManagementSaga';
import { cboUserManagementWatcher } from './cbo/UserEngagementSaga';
import { rbacWatcher } from './cbo/RBACSaga';
import { refreshTokenWatcher } from './cbo/RefreshTokenSaga';
import { cboMarketplaceWatcher } from './cbo/CboMarketplaceSaga';
import { journeyBuilderWatcher } from './cbo/JourneyBuilderSaga';
import { capabilityManagementWatcher } from './cbo/CapabilityManagementSaga';
import { engagementSummaryGraphWatcher } from './cbo/EngagementSummaryGraphSaga';
import { marketplaceSearchWatcher } from './cbo/CboMarketplaceSearchSaga';

export default function* sagas() {
  const sagas = [
    loginWatcher,
    userWatcher,
    buildDeployWatcher,
    cboViewSummaryWatcher,
    analyticsWatcher,
    cloudSuitabilityWatcher,
    trafficAnalyticsWatcher,
    hitsAnalyticsWatcher,
    engagementWatcher,
    engagementSummaryWatcher,
    clientEngagementManagementWatcher,
    cboUserManagementWatcher,
    rbacWatcher,
    refreshTokenWatcher,
    cboMarketplaceWatcher,
    journeyBuilderWatcher,
    capabilityManagementWatcher,
    engagementSummaryGraphWatcher,
    marketplaceSearchWatcher,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            // eslint-disable-next-line
            console.log(e);
          }
        }
      }),
    ),
  );
}
