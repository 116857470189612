/* eslint-disable max-lines */
import {
  IApplicationDipositioning,
  IWavePlan,
} from '../../../../containers/CBOEngagementSummary/CboTrunorthGraphs/CboTrunorthConstants';
import {
  IDiscoveryMetrics,
  ISurveyMetrics,
} from '../../../../containers/CBOEngagementSummary/CboTrunorthGraphs/CboTrunorthConstants';
import {
  ILearningCenterPayload,
  IDashboardCourseStatusResponse,
  ISummaryPractitionerDataResp,
} from '../../../../containers/CBOEngagementSummary/operate/LearningCenter/LearningCenterConstants';
import { IProduct } from '../../../models/cbo/EngagementModels';
import {
  IJiraInitiativesResponse,
  ILiftAndShiftObject,
  IJiraInitiativesPayload,
  IJiraEpicsPayload,
  IJiraEpicsResponse,
  IJiraTasksPayload,
  IJiraTasksResponse,
  ISummaryMetricsPayload,
  IJiraTaskListResponse,
  IJiraIssueResponse,
  IOperateEndgeIncidentPayload,
  ICarbonFootPrintResponse,
  ICarbonCreditsResponse,
  IEnergySavingsResponse,
  IGreenCloudPayload,
  ILegacyGraphData,
  ILegacyDbData,
  ISummaryMetricsResp,
  IArtifactsGraphPayload,
  IArtifactsLOCResponse,
  IArtifactsResponse,
  IDecompositionResponse,
  ISQLGraphDataResponse,
  IAllSQLGraphPayload,
  IExecutionMetricDetailsResponse,
  ITurbocodeSprintResponse,
  ITurbocodeStoriesPayload,
  IWorkflowListPayload,
  IWorkflowListResponse,
  IWorkflowReportResponse,
  ITurbocodeStoriesFetchPayload,
  IDEPAlertScanResponse,
  IDEPAlertScanPayload,
  ICodeQuality,
  ICodeQualityPayload,
  IIncidentChartsType,
  IIncidentDetails,
  IIncidentDetailsPayload,
  IAvailableDecompisitionsResponse,
  ICodespacesResponse,
  IProblemGroupOverviewResponse,
  IProblemInsightsAssignmentGroupData,
  IProblemInsightsPayload,
  IProblemInsightsRunDetailsData,
  IKMSResponse,
  IActiveSummaryResponse,
  IIssueSummaryResponse,
  IRiskSummaryResponse,
  ITransitionTableResponseData,
  IJiraStoriesPayload,
  IJiraStoryListResponse,
  IServersProgressionResponse,
  IAppsProgressionResponse,
  IMigrationPayload,
  IAppsProgressionMonthlyResponse,
  IServersProgressionMonthlyResponse,
  IMigrationArcheTypeListResponse,
  ITCOInsightsResponse,
  ICostTrendResponse,
  ICostAllocationSummaryResponse,
  IBlockersAndCausesResponse,
  IAllocationTrendResponse,
  ICloudCostSummaryCostTrendResponse,
  IServersByUpgradeResponse,
  IDispositionsByWaveData,
  IOtherSQLPayload,
  ISQLDMLPayload,
  IWorkflowPayload,
} from '../../../models/cbo/EngagementSummaryGraphModel';
import { IStoreAction } from '../../StoreHelper';
export enum EngagementSummaryGraphActionTypes {
  GET_LIFT_SHIFT_CHART_STARTED = 'GET_LIFT_SHIFT_STARTED',
  GET_LIFT_SHIFT_CHART_SUCCESS = 'GET_LIFT_SHIFT_SUCCESS',
  GET_LIFT_SHIFT_CHART_ERROR = 'GET_LIFT_SHIFT_ERROR',
  GET_JIRA_INITIATIVE_STARTED = 'GET_JIRA_INITIATIVE_STARTED',
  GET_JIRA_INITIATIVE_SUCCESS = 'GET_JIRA_INITIATIVE_SUCCESS',
  GET_JIRA_INITIATIVE_ERROR = 'GET_JIRA_INITIATIVE_ERROR',
  GET_JIRA_EPICS_STARTED = 'GET_JIRA_EPICS_STARTED',
  GET_JIRA_EPICS_SUCCESS = 'GET_JIRA_EPICS_SUCCESS',
  GET_JIRA_EPICS_ERROR = 'GET_JIRA_EPICS_ERROR',
  GET_JIRA_TASKS_STARTED = 'GET_JIRA_TASKS_STARTED',
  GET_JIRA_TASKS_SUCCESS = 'GET_JIRA_TASKS_SUCCESS',
  GET_JIRA_TASKS_ERROR = 'GET_JIRA_TASKS_ERROR',
  GET_ALL_SQL_GRAPH_DATA_START = 'GET_ALL_SQL_GRAPH_DATA_START',
  GET_ALL_SQL_GRAPH_DATA_SUCCESS = 'GET_ALL_SQL_GRAPH_DATA_SUCCESS',
  GET_ALL_SQL_GRAPH_DATA_ERROR = 'GET_ALL_SQL_GRAPH_DATA_ERROR',
  GET_DML_SQL_GRAPH_DATA_START = 'GET_DML_SQL_GRAPH_DATA_START',
  GET_DML_SQL_GRAPH_DATA_SUCCESS = 'GET_DML_SQL_GRAPH_DATA_SUCCESS',
  GET_DML_SQL_GRAPH_DATA_ERROR = 'GET_DML_SQL_GRAPH_DATA_ERROR',
  GET_OTHER_SQL_GRAPH_DATA_START = 'GET_OTHER_SQL_GRAPH_DATA_START',
  GET_OTHER_SQL_GRAPH_DATA_SUCCESS = 'GET_OTHER_SQL_GRAPH_DATA_SUCCESS',
  GET_OTHER_SQL_GRAPH_DATA_ERROR = 'GET_OTHER_SQL_GRAPH_DATA_ERROR',
  GET_DCMPTN_GRAPH_DATA_START = 'GET_DCMPTN_GRAPH_DATA_START',
  GET_DCMPTN_GRAPH_DATA_SUCCESS = 'GET_DCMPTN_GRAPH_DATA_SUCCESS',
  GET_DCMPTN_GRAPH_DATA_ERROR = 'GET_DCMPTN_GRAPH_DATA_ERROR',
  GET_MISSING_ARTIFACT_DATA_START = 'GET_MISSING_ARTIFACT_DATA_START',
  GET_MISSING_ARTIFACT_DATA_SUCCESS = 'GET_MISSING_ARTIFACT_DATA_SUCCESS',
  GET_MISSING_ARTIFACT_DATA_ERROR = 'GET_MISSING_ARTIFACT_DATA_ERROR',
  GET_ANALYZED_ARTIFACT_DATA_START = 'GET_ANALYZED_ARTIFACT_DATA_START',
  GET_ANALYZED_ARTIFACT_DATA_SUCCESS = 'GET_ANALYZED_ARTIFACT_DATA_SUCCESS',
  GET_ANALYZED_ARTIFACT_DATA_ERROR = 'GET_ANALYZED_ARTIFACT_DATA_ERROR',
  GET_NUMBER_LOC_DATA_START = 'GET_NUMBER_LOC_DATA_START',
  GET_NUMBER_LOC_DATA_SUCCESS = 'GET_NUMBER_LOC_DATA_SUCCESS',
  GET_NUMBER_LOC_DATA_ERROR = 'GET_NUMBER_LOC_DATA_ERROR',

  GET_CODE_QUALITY_LOW_GRAPH_DATA_START = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_START',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_SUCCESS = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_SUCCESS',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_ERROR = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_ERROR',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_START = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_START',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_SUCCESS = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_SUCCESS',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_ERROR = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_ERROR',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_START = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_START',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_SUCCESS = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_SUCCESS',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_ERROR = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_ERROR',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_START = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_START',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_SUCCESS = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_SUCCESS',
  GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_ERROR = 'GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_ERROR',

  GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_START = 'GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_START',
  GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_SUCCESS = 'GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_SUCCESS',
  GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_ERROR = 'GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_ERROR',
  GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_START = 'GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_START',
  GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_SUCCESS = 'GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_SUCCESS',
  GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_ERROR = 'GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_ERROR',
  GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_START = 'GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_START',
  GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_SUCCESS = 'GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_SUCCESS',
  GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_ERROR = 'GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_ERROR',

  GET_CODE_QUALITY_HIGH_GRAPH_DATA_START = 'GET_CODE_QUALITY_HIGH_GRAPH_DATA_START',
  GET_CODE_QUALITY_HIGH_GRAPH_DATA_SUCCESS = 'GET_CODE_QUALITY_HIGH_GRAPH_DATA_SUCCESS',
  GET_CODE_QUALITY_HIGH_GRAPH_DATA_ERROR = 'GET_CODE_QUALITY_HIGH_GRAPH_DATA_ERROR',
  GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_START = 'GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_START',
  GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_SUCCESS = 'GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_SUCCESS',
  GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_ERROR = 'GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_ERROR',
  GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_START = 'GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_START',
  GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_SUCCESS = 'GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_SUCCESS',
  GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_ERROR = 'GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_ERROR',

  GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_START = 'GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_START',
  GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_SUCCESS = 'GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_SUCCESS',
  GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_ERROR = 'GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_ERROR',
  GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_START = 'GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_START',
  GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_SUCCESS = 'GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_SUCCESS',
  GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_ERROR = 'GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_ERROR',
  GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_START = 'GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_START',
  GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_SUCCESS = 'GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_SUCCESS',
  GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_ERROR = 'GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_ERROR',

  GET_METRICS_MODULE_DATA_START = 'GET_METRICS_MODULE_DATA_START',
  GET_METRICS_MODULE_DATA_SUCCESS = 'GET_METRICS_MODULE_DATA_SUCCESS',
  GET_METRICS_MODULE_DATA_ERROR = 'GET_METRICS_MODULE_DATA_ERROR',
  GET_PHYSICAL_FILES_DATA_START = 'GET_PHYSICAL_FILES_DATA_START',
  GET_PHYSICAL_FILES_DATA_SUCCESS = 'GET_PHYSICAL_FILES_DATA_SUCCESS',
  GET_PHYSICAL_FILES_DATA_ERROR = 'GET_PHYSICAL_FILES_DATA_ERROR',
  GET_PHYSICAL_LOC_DATA_START = 'GET_PHYSICAL_LOC_DATA_START',
  GET_PHYSICAL_LOC_DATA_SUCCESS = 'GET_PHYSICAL_LOC_DATA_SUCCESS',
  GET_PHYSICAL_LOC_DATA_ERROR = 'GET_PHYSICAL_LOC_DATA_ERROR',
  GET_SOURCE_LOC_DATA_START = 'GET_SOURCE_LOC_DATA_START',
  GET_SOURCE_LOC_DATA_SUCCESS = 'GET_SOURCE_LOC_DATA_SUCCESS',
  GET_SOURCE_LOC_DATA_ERROR = 'GET_SOURCE_LOC_DATA_ERROR',
  GET_COMMENT_LOC_DATA_START = 'GET_COMMENT_LOC_DATA_START',
  GET_COMMENT_LOC_DATA_SUCCESS = 'GET_COMMENT_LOC_DATA_SUCCESS',
  GET_COMMENT_LOC_DATA_ERROR = 'GET_COMMENT_LOC_DATA_ERROR',
  GET_DEAD_CODE_DATA_START = 'GET_DEAD_CODE_DATA_START',
  GET_DEAD_CODE_DATA_SUCCESS = 'GET_DEAD_CODE_DATA_SUCCESS',
  GET_DEAD_CODE_DATA_ERROR = 'GET_DEAD_CODE_DATA_ERROR',
  GET_MISSING_MODULE_DATA_START = 'GET_MISSING_MODULE_DATA_START',
  GET_MISSING_MODULE_DATA_SUCCESS = 'GET_MISSING_MODULE_DATA_SUCCESS',
  GET_MISSING_MODULE_DATA_ERROR = 'GET_MISSING_MODULE_DATA_ERROR',
  GET_ERRORS_DATA_START = 'GET_ERRORS_DATA_START',
  GET_ERRORS_DATA_SUCCESS = 'GET_ERRORS_DATA_SUCCESS',
  GET_ERRORS_DATA_ERROR = 'GET_ERRORS_DATA_ERROR',
  GET_JIRA_TABLE_INITIATIVE_STARTED = 'GET_JIRA_TABLE_INITIATIVE_STARTED',
  GET_JIRA_TABLE_INITIATIVE_SUCCESS = 'GET_JIRA_TABLE_INITIATIVE_SUCCESS',
  GET_JIRA_TABLE_INITIATIVE_ERROR = 'GET_JIRA_TABLE_INITIATIVE_ERROR',
  GET_JIRA_TABLE_EPICS_STARTED = 'GET_JIRA_TABLE_EPICS_STARTED',
  GET_JIRA_TABLE_EPICS_SUCCESS = 'GET_JIRA_TABLE_EPICS_SUCCESS',
  GET_JIRA_TABLE_EPICS_ERROR = 'GET_JIRA_TABLE_EPICS_ERROR',
  GET_APPLICATION_DISPOSITIONING_ERROR = 'GET_APPLICATION_DISPOSITIONING_ERROR',
  GET_APPLICATION_DISPOSITIONING_STARTED = 'GET_APPLICATION_DISPOSITIONING_STARTED',
  GET_APPLICATION_DISPOSITIONING_SUCCESS = 'GET_APPLICATION_DISPOSITIONING_SUCCESS',
  GET_WAVE_PLANE_ERROR = 'GET_WAVE_PLANE_ERROR',
  GET_WAVE_PLANE_STARTED = 'GET_WAVE_PLANE_STARTED',
  GET_WAVE_PLANE_SUCCESS = 'GET_WAVE_PLANE_SUCCESS',
  GET_JIRA_TASK_LIST_STARTED = 'GET_JIRA_TASK_LIST_STARTED',
  GET_JIRA_TASK_LIST_SUCCESS = 'GET_JIRA_TASK_LIST_SUCCESS',
  GET_JIRA_TASK_LIST_ERROR = 'GET_JIRA_TASK_LIST_ERROR',
  GET_JIRA_TABLE_STORY_LIST_STARTED = 'GET_JIRA_TABLE_STORY_LIST_STARTED',
  GET_JIRA_TABLE_STORY_LIST_SUCCESS = 'GET_JIRA_TABLE_STORY_LIST_SUCCESS',
  GET_JIRA_TABLE_STORY_LIST_ERROR = 'GET_JIRA_TABLE_STORY_LIST_ERROR',
  GET_JIRA_TABLE_TASK_LIST_STARTED = 'GET_JIRA_TABLE_TASK_LIST_STARTED',
  GET_JIRA_TABLE_TASK_LIST_SUCCESS = 'GET_JIRA_TABLE_TASK_LIST_SUCCESS',
  GET_JIRA_TABLE_TASK_LIST_ERROR = 'GET_JIRA_TABLE_TASK_LIST_ERROR',
  GET_INVENTORY_SURVEY_DATA_START = 'GET_INVENTORY_SURVEY_DATA_START',
  GET_INVENTORY_SURVEY_DATA_SUCCESS = 'GET_INVENTORY_SURVEY_DATA_SUCCESS',
  GET_INVENTORY_SURVEY_DATA_ERROR = 'GET_INVENTORY_SURVEY_DATA_ERROR',
  GET_BUSINESS_SURVEY_DATA_START = 'GET_BUSINESS_SURVEY_DATA_START',
  GET_BUSINESS_SURVEY_DATA_SUCCESS = 'GET_BUSINESS_SURVEY_DATA_SUCCESS',
  GET_BUSINESS_SURVEY_DATA_ERROR = 'GET_BUSINESS_SURVEY_DATA_ERROR',
  GET_DISCOVERY_METRICS_DATA_START = 'GET_DISCOVERY_METRICS_DATA_START',
  GET_DISCOVERY_METRICS_DATA_SUCCESS = 'GET_DISCOVERY_METRICS_DATA_SUCCESS',
  GET_DISCOVERY_METRICS_DATA_ERROR = 'GET_DISCOVERY_METRICS_DATA_ERROR',
  POST_INCIDENT_SUMMARY_TRENDS_STARTED = 'POST_INCIDENT_SUMMARY_TRENDS_STARTED',
  POST_INCIDENT_SUMMARY_TRENDS_SUCCESS = 'POST_INCIDENT_SUMMARY_TRENDS_SUCCESS',
  POST_INCIDENT_SUMMARY_TRENDS_ERROR = 'POST_INCIDENT_SUMMARY_TRENDS_ERROR',

  GET_INCIDENT_DETAILS_STARTED = 'GET_INCIDENT_DETAILS_STARTED',
  GET_INCIDENT_DETAILS_SUCCESS = 'GET_INCIDENT_DETAILS_SUCCESS',
  GET_INCIDENT_DETAILS_ERROR = 'GET_INCIDENT_DETAILS_ERROR',

  SET_KEY_ASSET_CONFIG_STARTED = 'SET_KEY_ASSET_CONFIG_STARTED',
  SET_KEY_ASSET_CONFIG_SUCCESS = 'SET_KEY_ASSET_CONFIG_SUCCESS',
  SET_KEY_ASSET_CONFIG_ERROR = 'SET_KEY_ASSET_CONFIG_ERROR',

  RESST_SUMMARY_GRAPH_DATA = 'RESST_SUMMARY_GRAPH_DATA',
  GET_JIRA_CURRENT_SPRINT_STARTED = 'GET_JIRA_CURRENT_SPRINT_STARTED',
  GET_JIRA_CURRENT_SPRINT_SUCCESS = 'GET_JIRA_CURRENT_SPRINT_SUCCESS',
  GET_JIRA_CURRENT_SPRINT_ERROR = 'GET_JIRA_CURRENT_SPRINT_ERROR',

  GET_CARBON_FOOT_PRINT_START = 'GET_CARBON_FOOT_PRINT_START',
  GET_CARBON_FOOT_PRINT_SUCCESS = 'GET_CARBON_FOOT_PRINT_SUCCESS',
  GET_CARBON_FOOT_PRINT_ERROR = 'GET_CARBON_FOOT_PRINT_ERROR',
  GET_CARBON_CREDITS_START = 'GET_CARBON_CREDITS_START',
  GET_CARBON_CREDITS_SUCCESS = 'GET_CARBON_CREDITS_SUCCESS',
  GET_CARBON_CREDITS_ERROR = 'GET_CARBON_CREDITS_ERROR',
  GET_ENERGY_SAVINGS_START = 'GET_ENERGY_SAVINGS_START',
  GET_ENERGY_SAVINGS_SUCCESS = 'GET_ENERGY_SAVINGS_SUCCESS',
  GET_ENERGY_SAVINGS_ERROR = 'GET_ENERGY_SAVINGS_ERROR',

  GET_TURBOCODE_STORIES_START = 'GET_TURBOCODE_STORIES_START',
  GET_TURBOCODE_STORIES_SUCCESS = 'GET_TURBOCODE_STORIES_SUCCESS',
  GET_TURBOCODE_STORIES_ERROR = 'GET_TURBOCODE_STORIES_ERROR',

  GET_LEARNING_CENTER_SUMMARY_START = 'GET_LEARNING_CENTER_SUMMARY_START',
  GET_LEARNING_CENTER_SUMMARY_SUCCESS = 'GET_LEARNING_CENTER_SUMMARY_SUCCESS',
  GET_LEARNING_CENTER_SUMMARY_ERROR = 'GET_LEARNING_CENTER_SUMMARY_ERROR',

  GET_COURSES_STATUS_START = 'GET_COURSES_STATUS_START',
  GET_COURSES_STATUS_SUCCESS = 'GET_COURSES_STATUS_SUCCESS',
  GET_COURSES_STATUS_ERROR = 'GET_COURSES_STATUS_ERROR',

  GET_AVAILABLE_DECOMPOSITIONS_START = 'GET_AVAILABLE_DECOMPOSITIONS_START',
  GET_AVAILABLE_DECOMPOSITIONS_SUCCESS = 'GET_AVAILABLE_DECOMPOSITIONS_SUCCESS',
  GET_AVAILABLE_DECOMPOSITIONS_ERROR = 'GET_AVAILABLE_DECOMPOSITIONS_ERROR',

  GET_LEGACY_DB_SUCCESS = 'GET_LEGACY_DB_SUCCESS',
  GET_LEGACY_DB_STARTED = 'GET_LEGACY_DB_STARTED',
  GET_LEGACY_DB_ERROR = 'GET_LEGACY_DB_ERROR',

  GET_LEGACY_GRAPH_SUCCESS = 'GET_LEGACY_GRAPH_SUCCESS',
  GET_LEGACY_GRAPH_STARTED = 'GET_LEGACY_GRAPH_STARTED',
  GET_LEGACY_GRAPH_ERROR = 'GET_LEGACY_GRAPH_ERROR',

  GET_WORKFLOW_LIST_STARTED = 'GET_WORKFLOW_LIST_STARTED',
  GET_WORKFLOW_LIST_SUCCESS = 'GET_WORKFLOW_LIST_SUCCESS',
  GET_WORKFLOW_LIST_ERROR = 'GET_WORKFLOW_LIST_ERROR',

  GET_WORKFLOW_METRICS_STARTED = 'GET_WORKFLOW_METRICS_STARTED',
  GET_WORKFLOW_METRICS_SUCCESS = 'GET_WORKFLOW_METRICS_SUCCESS',
  GET_WORKFLOW_METRICS_ERROR = 'GET_WORKFLOW_METRICS_ERROR',

  GET_WORKFLOW_REPORTS_STARTED = 'GET_WORKFLOW_REPORTS_STARTED',
  GET_WORKFLOW_REPORTS_SUCCESS = 'GET_WORKFLOW_REPORTS_SUCCESS',
  GET_WORKFLOW_REPORTS_ERROR = 'GET_WORKFLOW_REPORTS_ERROR',

  GET_DEPSCAN_ALERTS_STARTED = 'GET_DEPSCAN_ALERTS_STARTED',
  GET_DEPSCAN_ALERTS_SUCCESS = 'GET_DEPSCAN_ALERTS_SUCCESS',
  GET_DEPSCAN_ALERTS_ERROR = 'GET_DEPSCAN_ALERTS_ERROR',

  GET_DEP_CODES_SPACES_STARTED = 'GET_DEP_CODES_SPACES_STARTED',
  GET_DEP_CODES_SPACES_SUCCESS = 'GET_DEP_CODES_SPACES_SUCCESS',
  GET_DEP_CODES_SPACES_ERROR = 'GET_DEP_CODES_SPACES_ERROR',

  GET_RUN_ASSIGNMENT_LIST_STARTED = 'GET_RUN_ASSIGNMENT_LIST_STARTED',
  GET_RUN_ASSIGNMENT_LIST_SUCCESS = 'GET_RUN_ASSIGNMENT_LIST_SUCCESS',
  GET_RUN_ASSIGNMENT_LIST_ERROR = 'GET_RUN_ASSIGNMENT_LIST_ERROR',

  GET_RUN_DETAILS_STARTED = 'GET_RUN_DETAILS_STARTED',
  GET_RUN_DETAILS_SUCCESS = 'GET_RUN_DETAILS_SUCCESS',
  GET_RUN_DETAILS_ERROR = 'GET_RUN_DETAILS_ERROR',

  GET_PROBLEM_GROUP_OVERVIEW_STARTED = 'GET_PROBLEM_GROUP_OVERVIEW_STARTED',
  GET_PROBLEM_GROUP_OVERVIEW_SUCCESS = 'GET_PROBLEM_GROUP_OVERVIEW_SUCCESS',
  GET_PROBLEM_GROUP_OVERVIEW_ERROR = 'GET_PROBLEM_GROUP_OVERVIEW_ERROR',

  GET_KMS_RESPONSE_STARTED = 'GET_KMS_RESPONSE_STARTED',
  GET_KMS_RESPONSE_SUCCESS = 'GET_KMS_RESPONSE_SUCCESS',
  GET_KMS_RESPONSE_ERROR = 'GET_KMS_RESPONSE_ERROR',

  GET_RISK_SUMMARY_DATA_STARTED = 'GET_RISK_SUMMARY_DATA_STARTED',
  GET_RISK_SUMMARY_DATA_SUCCESS = 'GET_RISK_SUMMARY_DATA_SUCCESS',
  GET_RISK_SUMMARY_DATA_ERROR = 'GET_RISK_SUMMARY_DATA_ERROR',

  GET_ISSUE_SUMMARY_DATA_STARTED = 'GET_ISSUE_SUMMARY_DATA_STARTED',
  GET_ISSUE_SUMMARY_DATA_SUCCESS = 'GET_ISSUE_SUMMARY_DATA_SUCCESS',
  GET_ISSUE_SUMMARY_DATA_ERROR = 'GET_ISSUE_SUMMARY_DATA_ERROR',

  GET_ACTIVE_SUMMARY_DATA_STARTED = 'GET_ACTIVE_SUMMARY_DATA_STARTED',
  GET_ACTIVE_SUMMARY_DATA_SUCCESS = 'GET_ACTIVE_SUMMARY_DATA_SUCCESS',
  GET_ACTIVE_SUMMARY_DATA_ERROR = 'GET_ACTIVE_SUMMARY_DATA_ERROR',

  GET_TRANSITIONS_DATA_STARTED = 'GET_TRANSITIONS_DATA_STARTED',
  GET_TRANSITIONS_DATA_SUCCESS = 'GET_TRANSITIONS_DATA_SUCCESS',
  GET_TRANSITIONS_DATA_ERROR = 'GET_TRANSITIONS_DATA_ERROR',
  //CBO-2901_AI_AUTO_NEW_CODE : Added new action Types for AppProgressionData, ServerProgressionData and ArchetypeList

  GET_APPS_PROGRESSION_DATA_STARTED = 'GET_APPS_PROGRESSION_DATA_STARTED',
  GET_APPS_PROGRESSION_DATA_SUCCESS = 'GET_APPS_PROGRESSION_DATA_SUCCESS',
  GET_APPS_PROGRESSION_DATA_ERROR = 'GET_APPS_PROGRESSION_DATA_ERROR',

  GET_SERVERS_PROGRESSION_DATA_STARTED = 'GET_SERVERS_PROGRESSION_DATA_STARTED',
  GET_SERVERS_PROGRESSION_DATA_SUCCESS = 'GET_SERVERS_PROGRESSION_DATA_SUCCESS',
  GET_SERVERS_PROGRESSION_DATA_ERROR = 'GET_SERVERS_PROGRESSION_DATA_ERROR',

  GET_ARCHETYPE_LIST_STARTED = 'GET_ARCHETYPE_LIST_STARTED',
  GET_ARCHETYPE_LIST_SUCCESS = 'GET_ARCHETYPE_LIST_SUCCESS',
  GET_ARCHETYPE_LIST_ERROR = 'GET_ARCHETYPE_LIST_ERROR',

  //CBO-2901_AI_AUTO_NEW_CODE : Added new action Types for AppProgressionMonthlyData, ServerProgressionMonthlyData
  GET_APPS_PROGRESSION_MONTHLY_DATA_STARTED = 'GET_APPS_PROGRESSION_MONTHLY_DATA_STARTED',
  GET_APPS_PROGRESSION_MONTHLY_DATA_SUCCESS = 'GET_APPS_PROGRESSION_MONTHLY_DATA_SUCCESS',
  GET_APPS_PROGRESSION_MONTHLY_DATA_ERROR = 'GET_APPS_PROGRESSION_MONTHLY_DATA_ERROR',
  GET_SERVERS_PROGRESSION_MONTHLY_DATA_STARTED = 'GET_SERVERS_PROGRESSION_MONTHLY_DATA_STARTED',
  GET_SERVERS_PROGRESSION_MONTHLY_DATA_SUCCESS = 'GET_SERVERS_PROGRESSION_MONTHLY_DATA_SUCCESS',
  GET_SERVERS_PROGRESSION_MONTHLY_DATA_ERROR = 'GET_SERVERS_PROGRESSION_MONTHLY_DATA_ERROR',

  GET_TCO_INSIGHTS_STARTED = 'GET_TCO_INSIGHTS_STARTED',
  GET_TCO_INSIGHTS_SUCCESS = 'GET_TCO_INSIGHTS_SUCCESS',
  GET_TCO_INSIGHTS_ERROR = 'GET_TCO_INSIGHTS_ERROR',
  GET_COST_TRENDS_STARTED = 'GET_COST_TRENDS_STARTED',
  GET_COST_TRENDS_SUCCESS = 'GET_COST_TRENDS_SUCCESS',
  GET_COST_TRENDS_ERROR = 'GET_COST_TRENDS_ERROR',
  GET_COST_ALLOCATION_SUMMARY_STARTED = 'GET_COST_ALLOCATION_SUMMARY_STARTED',
  GET_COST_ALLOCATION_SUMMARY_SUCCESS = 'GET_COST_ALLOCATION_SUMMARY_SUCCESS',
  GET_COST_ALLOCATION_SUMMARY_ERROR = 'GET_COST_ALLOCATION_SUMMARY_ERROR',
  GET_BLOCKERS_CAUSES_DATA_STARTED = 'GET_BLOCKERS_CAUSES_DATA_STARTED',
  GET_BLOCKERS_CAUSES_DATA_SUCCESS = 'GET_BLOCKERS_CAUSES_DATA_SUCCESS',
  GET_BLOCKERS_CAUSES_DATA_ERROR = 'GET_BLOCKERS_CAUSES_DATA_ERROR',

  //CB360
  GET_ALLOCATION_TRENDS_DATA_STARTED = 'GET_ALLOCATION_TRENDS_DATA_STARTED',
  GET_ALLOCATION_TRENDS_DATA_SUCCESS = 'GET_ALLOCATION_TRENDS_DATA_SUCCESS',
  GET_ALLOCATION_TRENDS_DATA_ERROR = 'GET_ALLOCATION_TRENDS_DATA_ERROR',
  GET_CLOUD_COST_SUMMARY_COST_TREND_STARTED = 'GET_CLOUD_COST_SUMMARY_COST_TREND_STARTED',
  GET_CLOUD_COST_SUMMARY_COST_TREND_SUCCESS = 'GET_CLOUD_COST_SUMMARY_COST_TREND_SUCCESS',
  GET_CLOUD_COST_SUMMARY_COST_TREND_ERROR = 'GET_CLOUD_COST_SUMMARY_COST_TREND_ERROR',

  // CBO-3061_AI_AUTO_ENHANCE_CODE:Created new action types for ServersByUpgrade
  GET_SERVERS_BY_UPGRADE_STARTED = 'GET_SERVERS_BY_UPGRADE_STARTED',
  GET_SERVERS_BY_UPGRADE_SUCCESS = 'GET_SERVERS_BY_UPGRADE_SUCCESS',
  GET_SERVERS_BY_UPGRADE_ERROR = 'GET_SERVERS_BY_UPGRADE_ERROR',

  GET_DISPOSITIONS_BY_WAVE_STARTED = 'GET_DISPOSITIONS_BY_WAVE_STARTED',
  GET_DISPOSITIONS_BY_WAVE_SUCCESS = 'GET_DISPOSITIONS_BY_WAVE_SUCCESS',
  GET_DISPOSITIONS_BY_WAVE_ERROR = 'GET_DISPOSITIONS_BY_WAVE_ERROR',
}

export type EngagementSummaryGraphPayload =
  | string
  | string[]
  | ILiftAndShiftObject
  | Error
  | IJiraInitiativesResponse[]
  | IJiraInitiativesPayload
  | IJiraEpicsPayload
  | IJiraEpicsResponse
  | IJiraStoriesPayload
  | IJiraStoryListResponse
  | IJiraTasksPayload
  | IJiraTasksResponse
  | IAllSQLGraphPayload
  | ISQLDMLPayload
  | IOtherSQLPayload
  | ISQLGraphDataResponse[]
  | IDecompositionResponse[]
  | IArtifactsResponse[]
  | IArtifactsGraphPayload
  | IArtifactsLOCResponse[]
  | ICodeQualityPayload
  | ICodeQuality[]
  | ISummaryMetricsPayload
  | ISummaryMetricsResp[]
  | IJiraIssueResponse
  | IJiraTaskListResponse
  | IApplicationDipositioning
  | ISurveyMetrics
  | IDiscoveryMetrics
  | IWavePlan
  | IOperateEndgeIncidentPayload
  | IIncidentChartsType
  | IIncidentDetailsPayload
  | IIncidentDetails
  | IProduct
  | IGreenCloudPayload
  | ICarbonFootPrintResponse
  | ICarbonCreditsResponse
  | IEnergySavingsResponse
  | ITurbocodeSprintResponse
  | ITurbocodeStoriesFetchPayload
  | ILearningCenterPayload
  | IDashboardCourseStatusResponse[]
  | ISummaryPractitionerDataResp
  | IAvailableDecompisitionsResponse[]
  | ILegacyDbData[]
  | ILegacyGraphData
  | IWorkflowListPayload
  | IWorkflowListResponse[]
  | IExecutionMetricDetailsResponse
  | IWorkflowReportResponse
  | IWorkflowPayload
  | IDEPAlertScanResponse
  | IDEPAlertScanPayload
  | ICodespacesResponse[]
  | IProblemInsightsPayload
  | IProblemGroupOverviewResponse
  | IProblemInsightsRunDetailsData
  | IProblemInsightsAssignmentGroupData[]
  | IKMSResponse[]
  | IRiskSummaryResponse[]
  | IIssueSummaryResponse[]
  | IActiveSummaryResponse
  | ITransitionTableResponseData[]
  | IAppsProgressionResponse[]
  | IServersProgressionResponse[]
  | IAppsProgressionMonthlyResponse[]
  | IServersProgressionMonthlyResponse[]
  | IMigrationPayload
  | IMigrationArcheTypeListResponse
  | ITCOInsightsResponse
  | ICostTrendResponse[]
  | ICostAllocationSummaryResponse
  | IBlockersAndCausesResponse
  | IAllocationTrendResponse
  | ICloudCostSummaryCostTrendResponse[]
  | IServersByUpgradeResponse[]
  | IDispositionsByWaveData;

export type EngagementSummaryGraphAction = IStoreAction<
  EngagementSummaryGraphActionTypes,
  EngagementSummaryGraphPayload
>;
/* export type CboMarketplaceDocsAction = IStoreAction<CboMarketplaceActionTypes,object>; */
export class EngagementSummaryGraphActions {
  //get liftshift pie-chart against journey
  public static getLiftAndShiftStart(): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LIFT_SHIFT_CHART_STARTED,
    };
  }

  public static getLiftAndShiftSuccess(data: ILiftAndShiftObject): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LIFT_SHIFT_CHART_SUCCESS,
      data,
    };
  }
  public static getLiftAndShiftError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LIFT_SHIFT_CHART_ERROR,
      data: error,
    };
  }

  //get liftshift issue-table against
  public static getJiraInititativeStart(
    data: IJiraInitiativesPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_INITIATIVE_STARTED,
      data,
    };
  }

  public static getJiraInititativeSuccess(
    data: IJiraInitiativesResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_INITIATIVE_SUCCESS,
      data,
    };
  }
  public static getJiraInititativeError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_INITIATIVE_ERROR,
      data: error,
    };
  }

  //Epics Action
  public static getJiraEpicsStart(data: IJiraEpicsPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_EPICS_STARTED,
      data,
    };
  }
  public static getJiraEpicsSuccess(data: IJiraEpicsResponse): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_EPICS_SUCCESS,
      data,
    };
  }
  public static getJiraEpicsError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_EPICS_ERROR,
      data: error,
    };
  }

  //Jira tasks Action
  public static getJiraTasksStart(data: IJiraTasksPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TASKS_STARTED,
      data,
    };
  }
  public static getJiraTasksSuccess(data: IJiraTasksResponse): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TASKS_SUCCESS,
      data,
    };
  }
  public static getJiraTasksError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TASKS_ERROR,
      data: error,
    };
  }

  //get Data for All SQL Graph of Language Migration
  public static getSQLGraphDataStart(data: IAllSQLGraphPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ALL_SQL_GRAPH_DATA_START,
      data,
    };
  }

  public static getSQLGraphDataSuccess(
    data: ISQLGraphDataResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ALL_SQL_GRAPH_DATA_SUCCESS,
      data,
    };
  }
  public static getSQLGraphDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ALL_SQL_GRAPH_DATA_ERROR,
      data: error,
    };
  }

  //get Data for DML SQL Graph of Language Migration
  public static getDMLSQLGraphDataStart(data: ISQLDMLPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DML_SQL_GRAPH_DATA_START,
      data,
    };
  }

  public static getDMLSQLGraphDataSuccess(
    data: ISQLGraphDataResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DML_SQL_GRAPH_DATA_SUCCESS,
      data,
    };
  }
  public static getDMLSQLGraphDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DML_SQL_GRAPH_DATA_ERROR,
      data: error,
    };
  }

  //get Data for Other SQL Graph of Language Migration
  public static getOtherSQLGraphDataStart(data: IOtherSQLPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_OTHER_SQL_GRAPH_DATA_START,
      data,
    };
  }

  public static getOtherSQLGraphDataSuccess(
    data: ISQLGraphDataResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_OTHER_SQL_GRAPH_DATA_SUCCESS,
      data,
    };
  }
  public static getOtherSQLGraphDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_OTHER_SQL_GRAPH_DATA_ERROR,
      data: error,
    };
  }

  //get Data for Decomposition Graph of Language Migration
  public static getDecompositionDataStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DCMPTN_GRAPH_DATA_START,
      data,
    };
  }

  public static getDecompositionDataSuccess(
    data: IDecompositionResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DCMPTN_GRAPH_DATA_SUCCESS,
      data,
    };
  }
  public static getDecompositionDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DCMPTN_GRAPH_DATA_ERROR,
      data: error,
    };
  }

  public static getMissingArtifactsDataStart(
    data: IArtifactsGraphPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_MISSING_ARTIFACT_DATA_START,
      data,
    };
  }

  public static getMissingArtifactsDataSuccess(
    data: IArtifactsResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_MISSING_ARTIFACT_DATA_SUCCESS,
      data,
    };
  }

  public static getMissingArtifactsDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_MISSING_ARTIFACT_DATA_ERROR,
      data: error,
    };
  }

  public static getAnalysedArtifactsDataStart(
    data: IArtifactsGraphPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ANALYZED_ARTIFACT_DATA_START,
      data,
    };
  }
  public static getAnalysedArtifactsDataSuccess(
    data: IArtifactsResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ANALYZED_ARTIFACT_DATA_SUCCESS,
      data,
    };
  }

  public static getAnalysedArtifactsDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ANALYZED_ARTIFACT_DATA_ERROR,
      data: error,
    };
  }

  public static getArtifactsLOCDataStart(
    data: IArtifactsGraphPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_NUMBER_LOC_DATA_START,
      data,
    };
  }
  public static getArtifactsLOCDataSuccess(
    data: IArtifactsLOCResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_NUMBER_LOC_DATA_SUCCESS,
      data,
    };
  }

  public static getArtifactsLOCDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_NUMBER_LOC_DATA_ERROR,
      data: error,
    };
  }

  //code quality
  //LOW
  public static getCodeQualityLowDataStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_START,
      data,
    };
  }

  public static getCodeQualityLowDataSuccess(data: ICodeQuality[]): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_SUCCESS,
      data,
    };
  }

  public static getCodeQualityLowDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_ERROR,
      data: error,
    };
  }
  //LOW FCC
  public static getCodeQualityLowDataForFCCStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_START,
      data,
    };
  }

  public static getCodeQualityLowDataForFCCSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_SUCCESS,
      data,
    };
  }

  public static getCodeQualityLowDataForFCCError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_ERROR,
      data: error,
    };
  }

  //LOW LCC
  public static getCodeQualityLowDataForLCCStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_START,
      data,
    };
  }

  public static getCodeQualityLowDataForLCCSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_SUCCESS,
      data,
    };
  }

  public static getCodeQualityLowDataForLCCError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_ERROR,
      data: error,
    };
  }

  //LOW SC
  public static getCodeQualityLowDataForSCStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_START,
      data,
    };
  }

  public static getCodeQualityLowDataForSCSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_SUCCESS,
      data,
    };
  }

  public static getCodeQualityLowDataForSCError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_ERROR,
      data: error,
    };
  }

  //MEDIUM
  public static getCodeQualityMediumDataStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_START,
      data,
    };
  }

  public static getCodeQualityMediumDataSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_SUCCESS,
      data,
    };
  }
  public static getCodeQualityMediumDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_ERROR,
      data: error,
    };
  }

  //MEDIUM FCC
  public static getCodeQualityMediumDataForFCCStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_START,
      data,
    };
  }

  public static getCodeQualityMediumDataForFCCSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_SUCCESS,
      data,
    };
  }
  public static getCodeQualityMediumDataForFCCError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_ERROR,
      data: error,
    };
  }

  //MEDIUM LCC
  public static getCodeQualityMediumDataForLCCStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_START,
      data,
    };
  }

  public static getCodeQualityMediumDataForLCCSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_SUCCESS,
      data,
    };
  }
  public static getCodeQualityMediumDataForLCCError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_ERROR,
      data: error,
    };
  }

  //HIGH
  public static getCodeQualityHighDataStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_START,
      data,
    };
  }

  public static getCodeQualityHighDataSuccess(data: ICodeQuality[]): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_SUCCESS,
      data,
    };
  }
  public static getCodeQualityHighDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_ERROR,
      data: error,
    };
  }

  //HIGH FCC
  public static getCodeQualityHighDataForFCCStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_START,
      data,
    };
  }

  public static getCodeQualityHighDataForFCCSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_SUCCESS,
      data,
    };
  }
  public static getCodeQualityHighDataForFCCError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_ERROR,
      data: error,
    };
  }

  //HIGH LCC
  public static getCodeQualityHighDataForLCCStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_START,
      data,
    };
  }

  public static getCodeQualityHighDataForLCCSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_SUCCESS,
      data,
    };
  }
  public static getCodeQualityHighDataForLCCError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_ERROR,
      data: error,
    };
  }

  //very high
  public static getCodeQualityVeryHighDataStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_START,
      data,
    };
  }

  public static getCodeQualityVeryHighDataSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_SUCCESS,
      data,
    };
  }
  public static getCodeQualityVeryHighDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_ERROR,
      data: error,
    };
  }

  //very high FCC
  public static getCodeQualityVeryHighDataForFCCStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_START,
      data,
    };
  }

  public static getCodeQualityVeryHighDataForFCCSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_SUCCESS,
      data,
    };
  }
  public static getCodeQualityVeryHighDataForFCCError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_ERROR,
      data: error,
    };
  }

  //very high LCC
  public static getCodeQualityVeryHighDataForLCCStart(
    data: ICodeQualityPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_START,
      data,
    };
  }

  public static getCodeQualityVeryHighDataForLCCSuccess(
    data: ICodeQuality[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_SUCCESS,
      data,
    };
  }
  public static getCodeQualityVeryHighDataForLCCError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_ERROR,
      data: error,
    };
  }

  //SUMMARY METRICS
  //Modules
  public static getMetricsModuleDataStart(
    data: ISummaryMetricsPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_METRICS_MODULE_DATA_START,
      data,
    };
  }

  public static getMetricsModuleDataSuccess(
    data: ISummaryMetricsResp[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_METRICS_MODULE_DATA_SUCCESS,
      data,
    };
  }
  public static getMetricsModuleDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_METRICS_MODULE_DATA_ERROR,
      data: error,
    };
  }

  //Physical files
  public static getPhysicalFilesDataStart(
    data: ISummaryMetricsPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_PHYSICAL_FILES_DATA_START,
      data,
    };
  }

  public static getPhysicalFilesDataSuccess(
    data: ISummaryMetricsResp[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_PHYSICAL_FILES_DATA_SUCCESS,
      data,
    };
  }
  public static getPhysicalFilesDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_PHYSICAL_FILES_DATA_ERROR,
      data: error,
    };
  }

  //Physical LOC
  public static getPhysicalLOCDataStart(
    data: ISummaryMetricsPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_PHYSICAL_LOC_DATA_START,
      data,
    };
  }

  public static getPhysicalLOCDataSuccess(
    data: ISummaryMetricsResp[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_PHYSICAL_LOC_DATA_SUCCESS,
      data,
    };
  }
  public static getPhysicalLOCDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_PHYSICAL_LOC_DATA_ERROR,
      data: error,
    };
  }

  //Source LOC
  public static getSourceLOCDataStart(data: ISummaryMetricsPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SOURCE_LOC_DATA_START,
      data,
    };
  }
  public static getSourceLOCDataSuccess(data: ISummaryMetricsResp[]): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SOURCE_LOC_DATA_SUCCESS,
      data,
    };
  }
  public static getSourceLOCDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SOURCE_LOC_DATA_ERROR,
      data: error,
    };
  }

  //Comment LOC
  public static getCommentLOCDataStart(data: ISummaryMetricsPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COMMENT_LOC_DATA_START,
      data,
    };
  }
  public static getCommentLOCDataSuccess(
    data: ISummaryMetricsResp[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COMMENT_LOC_DATA_SUCCESS,
      data,
    };
  }
  public static getCommentLOCDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COMMENT_LOC_DATA_ERROR,
      data: error,
    };
  }

  //dead CODE
  public static getDeadCodeDataStart(data: ISummaryMetricsPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DEAD_CODE_DATA_START,
      data,
    };
  }
  public static getDeadCodeDataSuccess(data: ISummaryMetricsResp[]): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DEAD_CODE_DATA_SUCCESS,
      data,
    };
  }
  public static getDeadCodeDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DEAD_CODE_DATA_ERROR,
      data: error,
    };
  }

  //Missing Modules
  public static getMissingModulesDataStart(
    data: ISummaryMetricsPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_MISSING_MODULE_DATA_START,
      data,
    };
  }

  public static getMissingModulesDataSuccess(
    data: ISummaryMetricsResp[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_MISSING_MODULE_DATA_SUCCESS,
      data,
    };
  }
  public static getMissingModulesDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_MISSING_MODULE_DATA_ERROR,
      data: error,
    };
  }

  //Errors
  public static geErrorsDataStart(data: ISummaryMetricsPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ERRORS_DATA_START,
      data,
    };
  }

  public static geErrorsDataSuccess(data: ISummaryMetricsResp[]): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ERRORS_DATA_SUCCESS,
      data,
    };
  }
  public static geErrorsDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ERRORS_DATA_ERROR,
      data: error,
    };
  }

  public static getJiraTableInititativeStart(
    data: IJiraInitiativesPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_INITIATIVE_STARTED,
      data,
    };
  }

  public static getJiraTableInititativeSuccess(
    data: IJiraIssueResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_INITIATIVE_SUCCESS,
      data,
    };
  }
  public static getJiraTableInititativeError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_INITIATIVE_ERROR,
      data: error,
    };
  }

  //Jira Table Epics
  public static getJiraTableEpicsStart(data: IJiraEpicsPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_EPICS_STARTED,
      data,
    };
  }
  public static getJiraTableEpicsSuccess(data: IJiraIssueResponse): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_EPICS_SUCCESS,
      data,
    };
  }
  public static getJiraTableEpicsError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_EPICS_ERROR,
      data: error,
    };
  }

  // Cloud Migration Fecthing Tasks based on epic Id
  public static getJiraTaskListStart(data: IJiraTasksPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TASK_LIST_STARTED,
      data,
    };
  }
  public static getJiraTaskListSuccess(data: IJiraTaskListResponse): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TASK_LIST_SUCCESS,
      data,
    };
  }
  public static getJiraTaskListError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TASK_LIST_ERROR,
      data: error,
    };
  }

  //Jira Table Story list
  public static getJiraTableStoryListStart(
    data: IJiraStoriesPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_STORY_LIST_STARTED,
      data,
    };
  }
  public static getJiraTableStoryListSuccess(
    data: IJiraStoryListResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_STORY_LIST_SUCCESS,
      data,
    };
  }
  public static getJiraTableStoryListError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_STORY_LIST_ERROR,
      data: error,
    };
  }

  //Jira Table Task list
  public static getJiraIssueTaskListStart(data: IJiraTasksPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_TASK_LIST_STARTED,
      data,
    };
  }
  public static getJiraIssueTaskListSuccess(
    data: IJiraTaskListResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_TASK_LIST_SUCCESS,
      data,
    };
  }
  public static getJiraIssueTaskListError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_TASK_LIST_ERROR,
      data: error,
    };
  }

  // current sprint
  //Jira Table Task list
  public static getCurrentSprintStart(
    data: IJiraStoriesPayload | IJiraTasksPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_CURRENT_SPRINT_STARTED,
      data,
    };
  }
  public static getCurrentSprintSuccess(data: IJiraIssueResponse): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_CURRENT_SPRINT_SUCCESS,
      data,
    };
  }
  public static getCurrentSprintError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_JIRA_CURRENT_SPRINT_ERROR,
      data: error,
    };
  }
  public static getApplicationDispositioningStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_APPLICATION_DISPOSITIONING_STARTED,
      data,
    };
  }

  public static getApplicationDispositioningSuccess(
    data: IApplicationDipositioning,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_APPLICATION_DISPOSITIONING_SUCCESS,
      data,
    };
  }
  public static getApplicationDispositioningError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_APPLICATION_DISPOSITIONING_ERROR,
    };
  }
  //wave plan
  public static getWavePlanStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WAVE_PLANE_STARTED,
      data,
    };
  }

  public static getWavePlanSuccess(data: IWavePlan): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WAVE_PLANE_SUCCESS,
      data,
    };
  }
  public static getWavePlanError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WAVE_PLANE_ERROR,
    };
  }
  //inventory survey
  public static getInventortSurveyDataStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_INVENTORY_SURVEY_DATA_START,
      data,
    };
  }

  public static getInventortSurveyDataSuccess(data: ISurveyMetrics): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_INVENTORY_SURVEY_DATA_SUCCESS,
      data,
    };
  }
  public static getInventortSurveyDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_INVENTORY_SURVEY_DATA_ERROR,
      data: error,
    };
  }

  //business survey
  public static getBusinessSurveyDataStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_BUSINESS_SURVEY_DATA_START,
      data,
    };
  }

  public static getBusinessSurveyDataSuccess(data: ISurveyMetrics): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_BUSINESS_SURVEY_DATA_SUCCESS,
      data,
    };
  }
  public static getBusinessSurveyDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_BUSINESS_SURVEY_DATA_ERROR,
      data: error,
    };
  }

  //discovery metrics
  public static getDiscoveryMetricsDataStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DISCOVERY_METRICS_DATA_START,
      data,
    };
  }

  public static getDiscoveryMetricsDataSuccess(
    data: IDiscoveryMetrics,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DISCOVERY_METRICS_DATA_SUCCESS,
      data,
    };
  }
  public static getDiscoveryMetricsDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DISCOVERY_METRICS_DATA_ERROR,
      data: error,
    };
  }

  public static postIncidentSummaryAndTrendDataStart(
    data: IOperateEndgeIncidentPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.POST_INCIDENT_SUMMARY_TRENDS_STARTED,
      data,
    };
  }

  public static postIncidentSummaryAndTrendDataSuccess(
    data: IIncidentChartsType,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.POST_INCIDENT_SUMMARY_TRENDS_SUCCESS,
      data,
    };
  }
  public static postIncidentSummaryAndTrendDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.POST_INCIDENT_SUMMARY_TRENDS_ERROR,
      data: error,
    };
  }

  //INCIDENT- DETAILS
  public static postIncidentDetailsDataStart(
    data: IIncidentDetailsPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_INCIDENT_DETAILS_STARTED,
      data,
    };
  }
  public static postIncidentDetailsDataSuccess(
    data: IIncidentDetails,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_INCIDENT_DETAILS_SUCCESS,
      data,
    };
  }
  public static postIncidentDetailsDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_INCIDENT_DETAILS_ERROR,
      data: error,
    };
  }

  public static setKeyAssetConfigStarted(data: IProduct): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.SET_KEY_ASSET_CONFIG_STARTED,
      data,
    };
  }
  public static setKeyAssetConfigSuccess(): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.SET_KEY_ASSET_CONFIG_SUCCESS,
    };
  }
  public static setKeyAssetConfigError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.SET_KEY_ASSET_CONFIG_ERROR,
      data: error,
    };
  }

  public static resetSummaryGraphData(): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.RESST_SUMMARY_GRAPH_DATA,
    };
  }

  //Green Cloud
  public static getCarbonFootPrintDataStart(
    data: IGreenCloudPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CARBON_FOOT_PRINT_START,
      data,
    };
  }

  public static getCarbonFootPrintDataSuccess(
    data: ICarbonFootPrintResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CARBON_FOOT_PRINT_SUCCESS,
      data,
    };
  }
  public static getCarbonFootPrintDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CARBON_FOOT_PRINT_ERROR,
      data: error,
    };
  }

  public static getCarbonCreditsDataStart(data: IGreenCloudPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CARBON_CREDITS_START,
      data,
    };
  }

  public static getCarbonCreditsDataSuccess(
    data: ICarbonCreditsResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CARBON_CREDITS_SUCCESS,
      data,
    };
  }
  public static getCarbonCreditsDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CARBON_CREDITS_ERROR,
      data: error,
    };
  }

  public static getEnergySavingsDataStart(data: IGreenCloudPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ENERGY_SAVINGS_START,
      data,
    };
  }

  public static getEnergySavingsDataSuccess(
    data: IEnergySavingsResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ENERGY_SAVINGS_SUCCESS,
      data,
    };
  }
  public static getEnergySavingsDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ENERGY_SAVINGS_ERROR,
      data: error,
    };
  }

  public static getTurbocodeStoriesStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_TURBOCODE_STORIES_START,
      data,
    };
  }

  public static getTurbocodeStoriesSuccess(
    data: ITurbocodeSprintResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_TURBOCODE_STORIES_SUCCESS,
      data,
    };
  }
  public static getTurbocodeStoriesError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_TURBOCODE_STORIES_ERROR,
      data: error,
    };
  }

  public static getLearningSummaryPractitionerStart(
    data: ILearningCenterPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LEARNING_CENTER_SUMMARY_START,
      data,
    };
  }

  public static getLearningSummaryPractitionerSuccess(
    data: ISummaryPractitionerDataResp,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LEARNING_CENTER_SUMMARY_SUCCESS,
      data,
    };
  }
  public static getLearningSummaryPractitionerError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LEARNING_CENTER_SUMMARY_ERROR,
      data: error,
    };
  }

  public static getCourseStatusPractitionerStart(
    data: ILearningCenterPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COURSES_STATUS_START,
      data,
    };
  }

  public static getCourseStatusPractitionerSuccess(
    data: IDashboardCourseStatusResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COURSES_STATUS_SUCCESS,
      data,
    };
  }
  public static getCourseStatusPractitionerError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COURSES_STATUS_ERROR,
      data: error,
    };
  }

  public static getAvailableDecompositionsStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_AVAILABLE_DECOMPOSITIONS_START,
      data,
    };
  }

  public static getAvailableDecompositionsSuccess(
    data: IAvailableDecompisitionsResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_AVAILABLE_DECOMPOSITIONS_SUCCESS,
      data,
    };
  }
  public static getAvailableDecompositionsError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_AVAILABLE_DECOMPOSITIONS_ERROR,
      data: error,
    };
  }
  // Legacy Graph Data
  public static getLegacyViewStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LEGACY_DB_STARTED,
      data,
    };
  }

  public static getLegacyViewSuccess(data: ILegacyDbData[]): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LEGACY_DB_SUCCESS,
      data,
    };
  }

  public static getLegacyViewError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LEGACY_DB_ERROR,
      data: error,
    };
  }

  // Legacy Graph Data
  public static getLegacyGraphStart(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LEGACY_GRAPH_STARTED,
      data,
    };
  }

  public static getLegacyGraphSuccess(data: ILegacyGraphData): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LEGACY_GRAPH_SUCCESS,
      data,
    };
  }

  public static getLegacyGraphError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_LEGACY_GRAPH_ERROR,
      data: error,
    };
  }

  public static getWorkflowsListStart(data: IWorkflowListPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WORKFLOW_LIST_STARTED,
      data,
    };
  }

  public static getWorkflowsListSuccess(
    data: IWorkflowListResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WORKFLOW_LIST_SUCCESS,
      data,
    };
  }
  public static getWorkflowsListError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WORKFLOW_LIST_ERROR,
      data: error,
    };
  }

  public static getWorkflowMetricDetailsStart(
    data: IWorkflowPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WORKFLOW_METRICS_STARTED,
      data,
    };
  }

  public static getWorkflowMetricDetailsSuccess(
    data: IExecutionMetricDetailsResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WORKFLOW_METRICS_SUCCESS,
      data,
    };
  }
  public static getWorkflowMetricDetailsError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WORKFLOW_METRICS_ERROR,
      data: error,
    };
  }

  public static getWorkflowReportStart(data: IWorkflowPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WORKFLOW_REPORTS_STARTED,
      data,
    };
  }

  public static getWorkflowReportSuccess(
    data: IWorkflowReportResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WORKFLOW_REPORTS_SUCCESS,
      data,
    };
  }
  public static getWorkflowReportError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_WORKFLOW_REPORTS_ERROR,
      data: error,
    };
  }

  public static startDepAlertsScanData(data: IDEPAlertScanPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DEPSCAN_ALERTS_STARTED,
      data,
    };
  }

  public static successDepAlertsScanData(
    data: IDEPAlertScanResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DEPSCAN_ALERTS_SUCCESS,
      data,
    };
  }

  public static errorDepAlertsScanData(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DEPSCAN_ALERTS_ERROR,
      data: error,
    };
  }
  public static getDepCodesSpacesStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DEP_CODES_SPACES_STARTED,
      data,
    };
  }

  public static getDepCodesSpacesSuccess(
    data: ICodespacesResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DEP_CODES_SPACES_SUCCESS,
      data,
    };
  }

  public static getDepCodesSpacesError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DEP_CODES_SPACES_ERROR,
      data: error,
    };
  }

  public static getRunAssignmentListStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_RUN_ASSIGNMENT_LIST_STARTED,
      data,
    };
  }

  public static getRunAssignmentListSuccess(
    data: IProblemInsightsAssignmentGroupData[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_RUN_ASSIGNMENT_LIST_SUCCESS,
      data,
    };
  }

  public static getRunAssignmentListError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_RUN_ASSIGNMENT_LIST_ERROR,
      data: error,
    };
  }

  public static getRunDetailsStarted(data: IProblemInsightsPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_RUN_DETAILS_STARTED,
      data,
    };
  }

  public static getRunDetailsSuccess(
    data: IProblemInsightsRunDetailsData,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_RUN_DETAILS_SUCCESS,
      data,
    };
  }

  public static getRunDetailsError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_RUN_DETAILS_ERROR,
      data: error,
    };
  }

  public static getProblemGroupOverviewStarted(
    data: IProblemInsightsPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_PROBLEM_GROUP_OVERVIEW_STARTED,
      data,
    };
  }

  public static getProblemGroupOverviewSuccess(
    data: IProblemGroupOverviewResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_PROBLEM_GROUP_OVERVIEW_SUCCESS,
      data,
    };
  }

  public static getProblemGroupOverviewError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_PROBLEM_GROUP_OVERVIEW_ERROR,
      data: error,
    };
  }

  public static getKMSApplicationsStarted(): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_KMS_RESPONSE_STARTED,
    };
  }

  public static getKMSApplicationsSuccess(data: IKMSResponse[]): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_KMS_RESPONSE_SUCCESS,
      data,
    };
  }

  public static getKMSApplicationsError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_KMS_RESPONSE_ERROR,
      data: error,
    };
  }

  public static riskSummaryDataStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_RISK_SUMMARY_DATA_STARTED,
      data,
    };
  }

  public static riskSummaryDataSuccess(data: IRiskSummaryResponse[]): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_RISK_SUMMARY_DATA_SUCCESS,
      data,
    };
  }

  public static riskSummaryDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_RISK_SUMMARY_DATA_ERROR,
      data: error,
    };
  }

  public static issueSummaryDataStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ISSUE_SUMMARY_DATA_STARTED,
      data,
    };
  }

  public static issueSummaryDataSuccess(
    data: IIssueSummaryResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ISSUE_SUMMARY_DATA_SUCCESS,
      data,
    };
  }

  public static issueSummaryDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ISSUE_SUMMARY_DATA_ERROR,
      data: error,
    };
  }

  public static activeSummaryDataStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ACTIVE_SUMMARY_DATA_STARTED,
      data,
    };
  }

  public static activeSummaryDataSuccess(
    data: IActiveSummaryResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ACTIVE_SUMMARY_DATA_SUCCESS,
      data,
    };
  }

  public static activeSummaryDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ACTIVE_SUMMARY_DATA_ERROR,
      data: error,
    };
  }

  public static transitionsDataStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_TRANSITIONS_DATA_STARTED,
      data,
    };
  }

  public static transitionsDataSuccess(
    data: ITransitionTableResponseData[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_TRANSITIONS_DATA_SUCCESS,
      data,
    };
  }

  public static transitionsDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_TRANSITIONS_DATA_ERROR,
      data: error,
    };
  }
  //CBO-2901_AI_AUTO_NEW_CODE : Added new action for AppProgressionData, ServerProgressionData and ArchetypeList
  public static getAppProgressionStarted(data: IMigrationPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_DATA_STARTED,
      data,
    };
  }
  public static getAppProgressionSuccess(
    data: IAppsProgressionResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_DATA_SUCCESS,
      data,
    };
  }
  public static getAppProgressionError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_DATA_ERROR,
      data: error,
    };
  }

  public static getServersProgressionStarted(
    data: IMigrationPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_DATA_STARTED,
      data,
    };
  }
  public static getServersProgressionSuccess(
    data: IServersProgressionResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_DATA_SUCCESS,
      data,
    };
  }
  public static getServersProgressionError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_DATA_ERROR,
      data: error,
    };
  }

  public static getArchetypeListStarted(data: IMigrationPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ARCHETYPE_LIST_STARTED,
      data,
    };
  }
  public static getArchetypeListSuccess(
    data: IMigrationArcheTypeListResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ARCHETYPE_LIST_SUCCESS,
      data,
    };
  }
  public static getArchetypeListError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ARCHETYPE_LIST_ERROR,
      data: error,
    };
  }

  //CBO-2901_AI_AUTO_NEW_CODE : Added new actions for AppProgressionMonthlyData, ServerProgressionMonthlyData

  public static getAppProgressionMonthlyDataStarted(
    data: IMigrationPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_MONTHLY_DATA_STARTED,
      data,
    };
  }
  public static getAppProgressionMonthlyDataSuccess(
    data: IAppsProgressionMonthlyResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_MONTHLY_DATA_SUCCESS,
      data,
    };
  }
  public static getAppProgressionMonthlyDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_MONTHLY_DATA_ERROR,
      data: error,
    };
  }
  public static getServerProgressionMonthlyDataStarted(
    data: IMigrationPayload,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_MONTHLY_DATA_STARTED,
      data,
    };
  }
  public static getServerProgressionMonthlyDataSuccess(
    data: IServersProgressionMonthlyResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_MONTHLY_DATA_SUCCESS,
      data,
    };
  }
  public static getServerProgressionMonthlyDataError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_MONTHLY_DATA_ERROR,
      data: error,
    };
  }
  public static getTCOInsightsStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_TCO_INSIGHTS_STARTED,
      data,
    };
  }
  public static getTCOInsightsSuccess(data: ITCOInsightsResponse): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_TCO_INSIGHTS_SUCCESS,
      data,
    };
  }
  public static getTCOInsightsError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_TCO_INSIGHTS_ERROR,
      data: error,
    };
  }
  //COST TRENDS
  public static getCostTrendsStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COST_TRENDS_STARTED,
      data,
    };
  }
  public static getCostTrendsSuccess(data: ICostTrendResponse[]): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COST_TRENDS_SUCCESS,
      data,
    };
  }
  public static getCostTrendsError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COST_TRENDS_ERROR,
      data: error,
    };
  }
  //ALLOCATIONSUMARY
  public static getCostAllocationSummaryStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COST_ALLOCATION_SUMMARY_STARTED,
      data,
    };
  }
  public static getCostAllocationSummarySuccess(
    data: ICostAllocationSummaryResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COST_ALLOCATION_SUMMARY_SUCCESS,
      data,
    };
  }
  public static getCostAllocationSummaryError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_COST_ALLOCATION_SUMMARY_ERROR,
      data: error,
    };
  }

  public static getBlockersandCausesStarted(data: IMigrationPayload): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_BLOCKERS_CAUSES_DATA_STARTED,
      data,
    };
  }

  public static getBlockersandCausesSuccess(
    data: IBlockersAndCausesResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_BLOCKERS_CAUSES_DATA_SUCCESS,
      data,
    };
  }

  public static getBlockersandCausesError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_BLOCKERS_CAUSES_DATA_ERROR,
      data: error,
    };
  }

  public static getAllocationTrendStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ALLOCATION_TRENDS_DATA_STARTED,
      data,
    };
  }
  public static getAllocationTrendSuccess(
    data: IAllocationTrendResponse,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ALLOCATION_TRENDS_DATA_SUCCESS,
      data,
    };
  }
  public static getAllocationTrendError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_ALLOCATION_TRENDS_DATA_ERROR,
      data: error,
    };
  }
  public static getCloudCostSummaryCostTrendStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CLOUD_COST_SUMMARY_COST_TREND_STARTED,
      data,
    };
  }
  public static getCloudCostSummaryCostTrendSuccess(
    data: ICloudCostSummaryCostTrendResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CLOUD_COST_SUMMARY_COST_TREND_SUCCESS,
      data,
    };
  }
  public static getCloudCostSummaryCostTrendError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_CLOUD_COST_SUMMARY_COST_TREND_ERROR,
      data: error,
    };
  }
  // CBO-3061_AI_AUTO_ENHANCE_CODE:Created new actions for ServersByUpgrade

  public static getServersByUpgradeStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SERVERS_BY_UPGRADE_STARTED,
      data,
    };
  }
  public static getServersByUpgradeSuccess(
    data: IServersByUpgradeResponse[],
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SERVERS_BY_UPGRADE_SUCCESS,
      data,
    };
  }
  public static getServersByUpgradeError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_SERVERS_BY_UPGRADE_ERROR,
      data: error,
    };
  }
  public static getDispositionsByWaveStarted(data: string): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DISPOSITIONS_BY_WAVE_STARTED,
      data,
    };
  }
  public static getDispositionsByWaveSuccess(
    data: IDispositionsByWaveData,
  ): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DISPOSITIONS_BY_WAVE_SUCCESS,
      data,
    };
  }
  public static getDispositionsByWaveError(error: Error): EngagementSummaryGraphAction {
    return {
      type: EngagementSummaryGraphActionTypes.GET_DISPOSITIONS_BY_WAVE_ERROR,
      data: error,
    };
  }
}
