import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import '../TruNorthDashboard/TruNorthDashboard.less';
import { STORAGE_CONSTANTS, storage } from '../../../libs/services/LocalStorage';
import { routes } from '../../../navigation/Routes';
import { useAppSelector, IApplicationState } from '../../../libs/store/reducers';
import { getDCAAuthUrl } from '../../CBOLogin/CBOLoginConstants';
import { formatUrl, handleZoom } from '../../../libs/helper/HelperFunctions';
import {
  IVisitedPagePayload,
  SectionType,
  TrafficAnalyticsActions,
} from '../../../libs/store/actions/WebAnalytics/TrafficAnalyticsActions';
import { useDispatch } from 'react-redux';
import { IProduct } from '../../../libs/models/cbo/EngagementModels';
import { dcaDemoInstanceConfig } from '../KeyAssetsConstant';

interface IAssetProps {
  selectedAssetConfig?: IProduct;
  isEngagement?: boolean;
  selectedEnagagementName?: string;
  selectedEnagagementId?: string;
}

const DCADashboard = ({
  isEngagement,
  selectedAssetConfig,
  selectedEnagagementName,
  selectedEnagagementId,
}: IAssetProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState<boolean>();

  const dispatcher = {
    postPageVisited: (data: IVisitedPagePayload) =>
      dispatch(TrafficAnalyticsActions.postVisitedPageStart(data)),
  };
  const { selectedPhase } = useAppSelector((state: IApplicationState) => ({
    selectedPhase: state.cboViewSummary.selectedPhase,
  }));

  useEffect(() => {
    dispatcher.postPageVisited({
      sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
      assetName: SectionType.DCA,
      name: selectedEnagagementName,
      id: selectedEnagagementId,
    });

    const dcaAuthUrl = getDCAAuthUrl();
    const dcaAuthenticated: any = isEngagement
      ? storage.getItemSession(STORAGE_CONSTANTS.dcaEngAuthenticated)
      : storage.getItemSession(STORAGE_CONSTANTS.dcaAuthenticated);
    if (dcaAuthenticated == 'true') {
      console.log('DCA Dashboard- DCA authenticated');
      setAuthenticated(true);
    } else {
      console.log('DCA not authenticated');
      setAuthenticated(false);
      const path =
        window.location?.pathname == routes.cboEngagementSummary
          ? `${window.location?.pathname}#${selectedPhase}`
          : window.location?.pathname;
      storage.setItem(STORAGE_CONSTANTS.authOriginatorUrl, path);
      window.location.href = dcaAuthUrl;
    }
    return () => {
      dispatcher.postPageVisited({
        sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
        assetName: SectionType.DCA,
        name: selectedEnagagementName,
        id: selectedEnagagementId,
      });
    };
  }, []);

  const getIframeUrl = (instanceUrl?: string) => {
    return `${formatUrl(instanceUrl ?? '')}/login#cboone`;
  };

  return (
    <div className="trunorth-dashboard-wrapper">
      {authenticated && (
        <Loader loaderName="Authorizing..." loading={isLoading}>
          <iframe
            id="assess"
            className="assess-iframe"
            title="Assess"
            src={
              isEngagement
                ? getIframeUrl(selectedAssetConfig?.instanceUrl)
                : selectedAssetConfig?.configDone
                ? getIframeUrl(selectedAssetConfig?.instanceUrl)
                : getIframeUrl(dcaDemoInstanceConfig.demoInstanceUrl)
            }
            onLoad={() => {
              setIsLoading(false);
              handleZoom('assess-iframe');
            }}
          />
        </Loader>
      )}
    </div>
  );
};

export default DCADashboard;
