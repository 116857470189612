/**
 * This file contains the Layout component which serves as the main layout for the application.
 * It renders the appropriate component based on the current route.
 * The Layout component also handles displaying toast notifications.
 *
 * Example usage:
 * <Layout />
 */

import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Toast } from '../../components/Notifications/Notifications';
import PrivateRoute from '../../components/privateRoute/PrivateRoute';
import useDeviceDetect from '../../hooks/UseDeviceDetect';
import { isAuthenticated } from '../../libs/helper/HelperFunctions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { routes } from '../../navigation/Routes';
import ProtectedRoute from '../../components/protectedRoute/ProtectedRoute';
import { RBACMapping } from '../../components/Auth/AuthenticationPageConstants';
import Loader from '../../components/Loader/Loader';
import IframeTest from '../KeyAssets/iframe-test';

const AuthenticationPage = lazy(() => import('../../components/Auth/AuthenticationPage'));
const BlockedScreen = lazy(() => import('../BlockedScreen/BlockedScreen'));
const AssetAuthenticationRoutes = lazy(
  () => import('../AssetAuthenticationRoutes/AssetAuthenticationRoutes'),
);
const CBOUserManagement = lazy(() => import('../CBOUserManagement/CBOUserManagement'));
const CBOUserClientMapping = lazy(() => import('../CBOUserClientMapping/CBOUserClientMapping'));
const CBODashboardPursuitLead = lazy(
  () => import('../CBODashboardPursuitLead/CBODashboardPursuitLead'),
);
const CBOJourneyBuilder = lazy(() => import('../CBOJourneyBuilder/CBOJourneyBuilder'));
const CapabilityManagement = lazy(() => import('../CBOCapabilityManagement/CapabilityManagement'));
const UnauthorizedModal = lazy(
  () => import('../../components/Auth/UnauthorizedModal/UnauthorizedModal'),
);
const CBOWebAnalytics = lazy(() => import('../CBOWebAnanlytics/CBOWebAnalytics'));
const CBOEngagementSummary = lazy(() => import('../CBOEngagementSummary/CBOEngagementSummary'));
const ClientEngagementManagement = lazy(
  () => import('../CBOClientEngagementManagement/ClientEngagementManagement'),
);
const EngMarketplaceAssets = lazy(() => import('../CBOMarketplace/EngMarketplaceAssets'));
const CBOLogin = lazy(() => import('../CBOLogin/CBOLogin'));
const CBOMarketplaceSearch = lazy(() => import('../CBOMarketplaceSearch/CBOMarketplaceSearch'));
const CBOTruNorthDashboard = lazy(() => import('../KeyAssets/TruNorthDashboard/TruNorthDashboard'));
const CBOSupport = lazy(() => import('../CBOSupport/CBOSupport'));
const CBOEngagementDashboard = lazy(
  () => import('../CBOEngagementDashboard/CBOEngagementDashboard'),
);

const Layout = () => {
  const { isMobile } = useDeviceDetect();

  const { showToast, description, message, type, duration, placement } = useAppSelector(
    ({
      toast: { showToast, description, message, type, duration, placement },
    }: IApplicationState) => ({
      showToast,
      description,
      message,
      type,
      duration,
      placement,
    }),
  );

  return (
    <>
      {showToast && Toast(description, message, type, duration, placement)}
      <Router>
        <Suspense
          fallback={
            <Loader loading loaderName={''}>
              <></>
            </Loader>
          }
        >
          <Switch>
            <Route exact path={routes.authentication}>
              <AuthenticationPage />
            </Route>
            <Route exact path={routes.login.error}>
              <BlockedScreen />
            </Route>
            <Route path={routes.authentication}>
              <PrivateRoute hideHomePage>
                <AssetAuthenticationRoutes />
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboUserManagement}>
              <PrivateRoute>
                <CBOUserManagement />
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboUserClientMapping}>
              <PrivateRoute>
                <CBOUserClientMapping />
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboDashboard}>
              <PrivateRoute>
                <CBODashboardPursuitLead />
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboEngagementDashboard}>
              <PrivateRoute>
                <CBOEngagementDashboard />
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboJourneyBuilder}>
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.journeyList}>
                  <CBOJourneyBuilder />
                </ProtectedRoute>
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboCapabilityManagement}>
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.capabilityList}>
                  <CapabilityManagement />
                </ProtectedRoute>
              </PrivateRoute>
            </Route>
            <Route exact path={routes.accessDenied}>
              <PrivateRoute>
                <UnauthorizedModal />
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboWebAnalytics}>
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.webAnalytics}>
                  <CBOWebAnalytics />
                </ProtectedRoute>
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboEngagementSummary}>
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.engagementSummary}>
                  <CBOEngagementSummary />
                </ProtectedRoute>
              </PrivateRoute>
            </Route>
            <Route exact path={routes.clientEngagementManagement}>
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.clientEngagementView}>
                  <ClientEngagementManagement />
                </ProtectedRoute>
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboMarketplace}>
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.engagementMarketplace}>
                  <EngMarketplaceAssets />
                </ProtectedRoute>
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboMarketplaceSearch}>
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.marketplaceSearch}>
                  <CBOMarketplaceSearch />
                </ProtectedRoute>
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboTruNorthDashboard}>
              <PrivateRoute>
                <CBOTruNorthDashboard />
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboFeedback}>
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.feedback}>
                  <CBOSupport />
                </ProtectedRoute>
              </PrivateRoute>
            </Route>
            <Route exact path={routes.cboIframeTest}>
              <PrivateRoute>
                <IframeTest />
              </PrivateRoute>
            </Route>

            <Route exact path={routes.cbologin}>
              <CBOLogin />
            </Route>
            <Route path="/">
              <Redirect to={isAuthenticated() ? routes.cboDashboard : routes.cbologin} />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default Layout;
