import { Reducer } from 'redux';
import { SidebarAction, SidebarActionTypes } from '../actions/SidebarActions';
import SidebarState from '../state/SidebarState';
import { routes } from '../../../navigation/Routes';

export const initialState: SidebarState = {
  error: '',
  loading: false,
  activeKey: routes.cboDashboard,
  logout: false,
};

export const sidebarReducer: Reducer<SidebarState> = (
  state = initialState,
  action: SidebarAction,
) => {
  switch (action.type) {
    case SidebarActionTypes.SET_SIDEBAR_KEY:
      return {
        ...state,
        activeKey: action.data as string,
      };

    case SidebarActionTypes.TOGGLE_LOGOUT:
      return {
        ...state,
        logout: (action.data as boolean) || false,
      };

    default:
      return state;
  }
};
