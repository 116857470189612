import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import assetAuthorizationService from '../../../libs/services/cbo/CboAssetAuthorizationService/CboAssetAuthorizationService';
import '../TruNorthDashboard/TruNorthDashboard.less';
import { handleZoom } from '../../../libs/helper/HelperFunctions';
import {
  IVisitedPagePayload,
  SectionType,
  TrafficAnalyticsActions,
} from '../../../libs/store/actions/WebAnalytics/TrafficAnalyticsActions';
import { useDispatch } from 'react-redux';
import { REDIRECT_URI_PARAM_KEY } from '../../../components/Auth/AuthenticationPageConstants';
import { getCWBAuthUrl } from '../../CBOLogin/CBOLoginConstants';
import { STORAGE_CONSTANTS, storage } from '../../../libs/services/LocalStorage';
import { routes } from '../../../navigation/Routes';
import { IApplicationState, useAppSelector } from '../../../libs/store/reducers';
import { cwbemoInstanceConfig } from '../KeyAssetsConstant';
interface IAssetProps {
  selectedEnagagementName?: string;
  selectedEnagagementId?: string;
  isEngagement?: boolean;
}
const CwbDashboard = ({
  selectedEnagagementName,
  selectedEnagagementId,
  isEngagement,
}: IAssetProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [cwbURL, setCwbURL] = useState<string>('');
  const [authenticated, setAuthenticated] = useState<boolean>();

  const dispatcher = {
    postPageVisited: (data: IVisitedPagePayload) =>
      dispatch(TrafficAnalyticsActions.postVisitedPageStart(data)),
  };

  const { selectedPhase } = useAppSelector((state: IApplicationState) => ({
    selectedPhase: state.cboViewSummary.selectedPhase,
  }));

  useEffect(() => {
    dispatcher.postPageVisited({
      sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
      assetName: SectionType.CWB,
      name: selectedEnagagementName,
      id: selectedEnagagementId,
    });

    const cwbAuthUrl = getCWBAuthUrl();
    const cwbAuthenticated = JSON.parse(
      storage.getItemSession(STORAGE_CONSTANTS.cwbAuthenticated) ?? 'false',
    ) as boolean;
    if (cwbAuthenticated) {
      console.log('CWB Dashboard- CWB authenticated');
      setAuthenticated(true);
    } else {
      console.log('CWB not authenticated');
      setAuthenticated(false);
      const path =
        window.location?.pathname == routes.cboEngagementSummary
          ? `${window.location?.pathname}#${selectedPhase}`
          : window.location?.pathname;
      storage.setItem(STORAGE_CONSTANTS.authOriginatorUrl, path);
      window.location.href = cwbAuthUrl;
    }
    return () => {
      dispatcher.postPageVisited({
        sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
        assetName: SectionType.CWB,
        name: selectedEnagagementName,
        id: selectedEnagagementId,
      });
    };
  }, []);

  return (
    <div className="trunorth-dashboard-wrapper">
      {authenticated && (
        <Loader loaderName="Authorizing..." loading={isLoading}>
          <iframe
            id="assess"
            className="assess-iframe"
            title="Assess"
            src={getCWBAuthUrl(true)}
            onLoad={() => {
              setIsLoading(false);
              handleZoom('assess-iframe');
            }}
          />
        </Loader>
      )}
    </div>
  );
};

export default CwbDashboard;
