import { IStoreAction } from '../../StoreHelper';

export enum RBACActionTypes {
    GET_RBAC_DATA_STARTED = 'GET_RBAC_DATA_STARTED',
    GET_RBAC_DATA_SUCCESS = 'GET_RBAC_DATA_SUCCESS',
    GET_RBAC_DATA_ERROR = 'GET_RBAC_DATA_ERROR',
}
export type RBACActionPayload =
  | Error
  | null
  | string[]

export type RBACAction = IStoreAction<RBACActionTypes, RBACActionPayload>;
 export class RBACActions {
    public static getRBACDataStart(data: string[] ): RBACAction {
        return {
          type: RBACActionTypes.GET_RBAC_DATA_STARTED,
          data
        };
      }
      public static getRBACDataSuccess(data: string[]): RBACAction {
        return {
          type: RBACActionTypes.GET_RBAC_DATA_SUCCESS,
          data,
        };
      }
      public static getRBACDataError(error: Error): RBACAction {
        return {
          type: RBACActionTypes.GET_RBAC_DATA_ERROR,
          data: error,
        };
      }
 }
