import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import {
  IClientResponse,
  IEngagementResponse,
  IClientAddPayload,
  IClientUpdatePayload,
  IEngagementAddPayload,
  IEngagementUpdatePayload,
  IEngagementDeletePayload,
} from '../../../models/cbo/ClientEngagementManagementModel';
import ClientEngagementManagementService from '../../../services/cbo/ClientEngagementManagementService/ClientEngagementManagementService';
import engagementService from '../../../services/cbo/EnagementService/EngagementService';
import {
  ClientEngagementManagementAction,
  ClientEngagementManagementActions,
  ClientEngagementActionTypes,
} from '../../actions/cbo/ClientEngagementManagementAction';
import { ToastActions } from '../../actions/ToastActions';

export function* getClientsDataWorker() {
  try {
    const response: IClientResponse[] = (yield ClientEngagementManagementService.getClientList());
    yield put(ClientEngagementManagementActions.getClientsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(ClientEngagementManagementActions.getClientsDataError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getClientsNameDataWorker() {
  try {
    const response: IClientResponse[] = (yield ClientEngagementManagementService.getClientNames());
    yield put(ClientEngagementManagementActions.getClientNamesDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(ClientEngagementManagementActions.getClientNamesDataError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getEngagementsDataWorker(action: ClientEngagementManagementAction) {
  try {
    const data = action.data as string;
    const response: IEngagementResponse[] =
      (yield ClientEngagementManagementService.getEngagementList(data))!;
    yield put(ClientEngagementManagementActions.getEngagementsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(ClientEngagementManagementActions.getEngagementsDataError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* addClientDataWorker(action: ClientEngagementManagementAction) {
  try {
    const { data } = action;
    yield ClientEngagementManagementService.addClient(data as IClientAddPayload);
    yield put(
      ToastActions.showToast({
        description: 'Client added successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const clientList: IClientResponse[] = yield ClientEngagementManagementService.getClientList();
      yield put(ClientEngagementManagementActions.getClientsDataSuccess(clientList));
    } catch (error) {
      if (error instanceof Error) {
        yield put(ClientEngagementManagementActions.getClientsDataError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(ClientEngagementManagementActions.addClientError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* updateClientDataWorker(action: ClientEngagementManagementAction) {
  try {
    const { data } = action;
    yield ClientEngagementManagementService.updateClient(data as IClientUpdatePayload);
    yield put(
      ToastActions.showToast({
        description: 'Client updated successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const clientList: IClientResponse[] = yield ClientEngagementManagementService.getClientList();
      yield put(ClientEngagementManagementActions.getClientsDataSuccess(clientList));
    } catch (error) {
      if (error instanceof Error) {
        yield put(ClientEngagementManagementActions.getClientsDataError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(ClientEngagementManagementActions.updateClientError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* addEngagementDataWorker(action: ClientEngagementManagementAction) {
  try {
    const { data } = action;
    yield ClientEngagementManagementService.addEngagement(data as IEngagementAddPayload);
    yield put(
      ToastActions.showToast({
        description: 'Engagement added successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const response: IEngagementResponse[] =
        (yield ClientEngagementManagementService.getEngagementList(
          (data as IEngagementAddPayload).selectedClientId,
        ))!;
      yield put(ClientEngagementManagementActions.getEngagementsDataSuccess(response));
    } catch (error) {
      if (error instanceof Error) {
        yield put(ClientEngagementManagementActions.getEngagementsDataError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(ClientEngagementManagementActions.addEngagementError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* updateEngagementDataWorker(action: ClientEngagementManagementAction) {
  try {
    const { data } = action;
    yield ClientEngagementManagementService.updateEngagement(data as IEngagementUpdatePayload);
    yield put(
      ToastActions.showToast({
        description: 'Engagement updated successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const response: IEngagementResponse[] =
        (yield ClientEngagementManagementService.getEngagementList(
          (data as IEngagementUpdatePayload).clientId,
        ))!;
      yield put(ClientEngagementManagementActions.getEngagementsDataSuccess(response));
    } catch (error) {
      if (error instanceof Error) {
        yield put(ClientEngagementManagementActions.getEngagementsDataError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(ClientEngagementManagementActions.updateEngagementError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* deleteClientDataWorker(action: ClientEngagementManagementAction) {
  try {
    yield ClientEngagementManagementService.deleteClient(action.data as string);
    yield put(
      ToastActions.showToast({
        description: 'Client deleted successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const clientList: IClientResponse[] = yield ClientEngagementManagementService.getClientList();
      yield put(ClientEngagementManagementActions.getClientsDataSuccess(clientList));
    } catch (error) {
      if (error instanceof Error) {
        yield put(ClientEngagementManagementActions.getClientsDataError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(ClientEngagementManagementActions.deleteClientDataError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}
export function* deleteEngagementDataWorker(action: ClientEngagementManagementAction) {
  try {
    const engagementDeletePayload = action.data as IEngagementDeletePayload;
    yield engagementService.deleteEngagement(engagementDeletePayload.engagementId as string);
    yield put(
      ToastActions.showToast({
        description: 'Engagement deleted successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const EngagementList: IEngagementResponse[] =
        yield ClientEngagementManagementService.getEngagementList(
          engagementDeletePayload.clientId as string,
        );
      yield put(ClientEngagementManagementActions.getEngagementsDataSuccess(EngagementList));
    } catch (error) {
      if (error instanceof Error) {
        yield put(ClientEngagementManagementActions.getEngagementsDataError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(ClientEngagementManagementActions.deleteEngagementDataError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* clientEngagementManagementWatcher() {
  yield takeEvery(ClientEngagementActionTypes.GET_CLIENTS_DATA_STARTED, getClientsDataWorker);
  yield takeEvery(ClientEngagementActionTypes.GET_CLIENTS_NAME_DATA_STARTED, getClientsNameDataWorker);
  yield takeEvery(
    ClientEngagementActionTypes.GET_ENGAGEMENTS_DATA_STARTED,
    getEngagementsDataWorker,
  );
  yield takeEvery(ClientEngagementActionTypes.ADD_CLIENT_DATA_STARTED, addClientDataWorker);
  yield takeEvery(ClientEngagementActionTypes.UPDATE_CLIENT_DATA_STARTED, updateClientDataWorker);
  yield takeEvery(ClientEngagementActionTypes.ADD_ENGAGEMENT_DATA_STARTED, addEngagementDataWorker);
  yield takeEvery(
    ClientEngagementActionTypes.UPDATE_ENGAGEMENT_DATA_STARTED,
    updateEngagementDataWorker,
  );
  yield takeEvery(ClientEngagementActionTypes.DELETE_CLIENT_DATA_STARTED, deleteClientDataWorker);
  yield takeEvery(
    ClientEngagementActionTypes.DELETE_ENGAGEMENT_DATA_STARTED,
    deleteEngagementDataWorker,
  );
}
