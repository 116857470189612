/**
 * Constants and utility functions related to CBOLogin.
 */
import { congnitoConfiguration } from '../../configs/AWS';
import { getEnv } from '../../libs/helper/HelperFunctions';
import { Configuration } from '@azure/msal-browser';

/**
 * Login labels used in CBOLogin.
 */
export const LoginLabels = {
  cboSubtitle: 'The One Platform',
  cboSubTitleBoth: 'Choose your login type to proceed ahead',
  cboSubTitleSso: 'Click below button to proceed with Single Sign On',
  cboSubTitleCustom: 'Enter your credentials to proceed with login',
  ssoLoginText: 'Login with SSO',
  customLoginText: 'Login',
  enterNewPasswordText: 'Enter New Password',
  newPasswordRequiredMessage: 'Please enter new password',
  newPasswordConfirmMessage: 'Please enter confirm new password',
  newPasswordMatchMessage: 'New Password and Confirm Password must be same',
  changePasswordText: 'Change Password',
  forgotPasswordText: 'Forgot Password?',
  confirmPasswordText: 'Confirm Password',
  succesfulLoginText: 'Logged in Successfully',
  userNameLabel: 'Enter Username',
  userNameRequiredMessage: 'Please enter the user name',
  userNamePlaceholder: 'username',
  validEmailAddress: 'Please enter a valid email address',
  passwordLabel: 'Enter Password',
  passwordRequiredMessage: 'Please enter the password',
  passwordPlaceholder: 'password',
  errorMsgPasswordPattern:
    'Password must be at least 8 characters long and contain at least 1 number, 1 lowercase letter, 1 uppercase letter, and 1 special character.',
};

export const accessRequestLink =
  'https://resources.deloitte.com/sites/consulting/sandd/delivery_excellence/Pages/Ascend_for_Engineering_and_Analytics.aspx';
/**
 * Challenge names used in CBOLogin.
 */
export enum ChallengeName {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

/**
 * Login types used in CBOLogin.
 */
export const LoginType = {
  CUSTOM: 'custom',
  SSO: 'sso',
  BOTH: 'both',
};

/**
 * Get the authentication URL for CBOLogin.
 * @param urlState The URL state.
 * @returns The authentication URL.
 */
export const getAuthURL = (urlState?: string) => {
  const environment = getEnv();
  const COGNITO = congnitoConfiguration(environment);
  if (urlState) {
    const url = `${COGNITO.DOMAIN}/oauth2/authorize?redirect_uri=${
      COGNITO.REDIRECT_SIGN_IN
    }&state=${btoa(urlState)}&response_type=${COGNITO.RESPONSE_TYPE}&client_id=${
      COGNITO.APP_CLIENT_ID
    }`;
    return url;
  } else {
    const url = `${COGNITO.DOMAIN}/oauth2/authorize?redirect_uri=${COGNITO.REDIRECT_SIGN_IN}&response_type=${COGNITO.RESPONSE_TYPE}&client_id=${COGNITO.APP_CLIENT_ID}`;
    return url;
  }
};

/**
 * Get the assessment authentication URL for CBOLogin.
 * @param authDomainUrl The authentication domain URL.
 * @param appRealm The application realm.
 * @returns The assessment authentication URL.
 */
export const getAssessAuthUrl = (authDomainUrl?: string, appRealm?: string) => {
  const environment = getEnv();
  const COGNITO = congnitoConfiguration(environment).ASSESS;
  const url = `${authDomainUrl}/auth/realms/${appRealm}/protocol/openid-connect/auth?client_id=${COGNITO.APP_CLIENT_ID}&redirect_uri=${COGNITO.REDIRECT_SIGN_IN}%2Flogin-callback&response_type=${COGNITO.RESPONSE_TYPE}&scope=openid%20profile%20openid%20offline_access&response_mode=query&kc_idp_hint=oidc`;
  return url;
};

/**
 * Get the DCA authentication URL for CBOLogin.
 * @param authDomainUrl The authentication domain URL.
 * @returns The DCA authentication URL.
 */
export const getDCAAuthUrl = (authDomainUrl?: string) => {
  const environment = getEnv();
  const COGNITO = congnitoConfiguration(environment).DCA;
  const domainURL = authDomainUrl ? authDomainUrl : COGNITO.DOMAIN;
  const url = `${domainURL}/oauth2/authorize?redirect_uri=${COGNITO.REDIRECT_SIGN_IN}&response_type=${COGNITO.RESPONSE_TYPE}&client_id=${COGNITO.APP_CLIENT_ID}`;
  return url;
};

/**
 * Get the CWB authentication URL for CBOLogin.
 * @param loadCwb Indicates whether to load CWB.
 * @returns The CWB authentication URL.
 */
export const getCWBAuthUrl = (loadCwb?: boolean) => {
  const environment = getEnv();
  const COGNITO = congnitoConfiguration(environment).CWB;
  const url = `${COGNITO.DOMAIN}/oauth2/authorize?response_type=${
    COGNITO.RESPONSE_TYPE
  }&client_id=${COGNITO.APP_CLIENT_ID}&redirect_uri=${
    loadCwb ? 'https://cwb.deloitte.com/login' : COGNITO.REDIRECT_SIGN_IN
  }`;
  return url;
};

/**
 * Get the TurboCode MSAL configuration to authenticate for Turbocode.
 * @returns The TurboCode MSAL configuration.
 */
export const getTurboCodeMSALConfig = () => {
  const environment = getEnv();
  const COGNITO = congnitoConfiguration(environment).TURBOCODE;
  const msalConfig: Configuration = {
    auth: {
      clientId: COGNITO.APP_CLIENT_ID,
      authority: `${COGNITO.DOMAIN}/${COGNITO.AUTHORITY}`,
      redirectUri: COGNITO.REDIRECT_SIGN_IN,
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  };
  return msalConfig;
};

/**
 * CBO-3573_AI_AUTO_ENHANCE_CODE
 * Get the DEP MSAL configuration for authenticate for DEP.
 * @returns The DEP MSAL configuration.
 */
export const getDEPMSALConfig = () => {
  const environment = getEnv();
  const COGNITO = congnitoConfiguration(environment).DEP;
  const msalConfig: Configuration = {
    auth: {
      clientId: COGNITO.APP_CLIENT_ID,
      authority: `${COGNITO.DOMAIN}/${COGNITO.AUTHORITY}`,
      redirectUri: COGNITO.REDIRECT_SIGN_IN,
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
  };
  return msalConfig;
};

export const getDEPAuthUrl = () => {
  const environment = getEnv();
  const COGNITO = congnitoConfiguration(environment).DEP;
  const url = `${COGNITO.DOMAIN}/${COGNITO.AUTHORITY}/oauth2/v2.0/authorize?client_id=${COGNITO.APP_CLIENT_ID}&redirect_uri=${COGNITO.REDIRECT_SIGN_IN}&code_challenge=${COGNITO.CODE_CHALLENGE}&response_type=${COGNITO.RESPONSE_TYPE}&scope=${COGNITO.SCOPE}`;
  return url;
};
