import React, { Component, ErrorInfo, ReactNode } from 'react';
import './ErrorBoundaryStyles.less';
import { Button, Typography } from 'antd';
import { storage } from '../../libs/services/LocalStorage';
import { LABELS } from './ErrorBoundaryConstants';

interface IProps {
  children: ReactNode;
}

interface IState {
  hasError: boolean;
  errorName: string;
}

class ErrorBoundary extends Component<IProps, IState> {
  public static getDerivedStateFromError(_: Error): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorName: _?.name};
  }

  public state: IState = {
    hasError: false,
    errorName: ""
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Can console log the errors here
  }

  public render() {
    const {
      state: { hasError, errorName },
      props: { children },
    } = this;
    if (hasError) {
      return (
        <div className="error-boundary-container" style={{background: errorName=='ChunkLoadError'?'#f6f6f6': '#d9ef79'}}>
          <img className="error-boundary-image" src={`${process.env.PUBLIC_URL}/icons/error-boundary.svg`} alt="Error Boundary" />
          <Typography className="error-boundary-title" style={{color: errorName=='ChunkLoadError'?'black': '#007680'}}>{errorName=='ChunkLoadError'? LABELS.loadingFailedTitle :LABELS.title}</Typography>
          {errorName=='ChunkLoadError'? 
          (<Button
            className="error-boundary-button error-boundary-button-reload"
            onClick={() => {
              window.location.reload();
            }}
          >
            {LABELS.reloadButtonText}
          </Button>)
          : (<Button
            className="error-boundary-button"
            onClick={() => {
              storage.clearAll();
              window.location.reload();
            }}
          >
            {LABELS.buttonText}
          </Button>)
          }
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
