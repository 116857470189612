/* eslint-disable max-lines */
import { put, takeEvery } from 'redux-saga/effects';
import {
  ILandingZoneResponse,
  LandingZoneStatus,
  LzMicroserviceStatus,
} from '../../models/BuildDeployModels';
import BuildDeployService from '../../services/BuildDeployService/BuildDeployService';
import {
  BuildDeployAction,
  BuildDeployActions,
  BuildDeployActionTypes,
} from '../actions/BuildDeployActions';
import {
  IArchitectureCatalogueResponse,
  IDeploymentsResponse,
  ILandingZoneState,
  IFormInterface,
} from '../state/BuildDeployState';

export function* getLandingZonesWorker(action: BuildDeployAction) {
  try {
    //API call to get all the landing zones
    const projectId = action.data as string;
    const landingZoneList: ILandingZoneResponse[] = yield BuildDeployService.getLandingZone(
      projectId,
    );
    const landingZones: ILandingZoneState[] = landingZoneList.map(
      ({
        LandingZoneName,
        CurrentStatus,
        CloudServiceProvider,
        LandingZoneId,
        Sector,
        GCPProjectId,
        MicroserviceInfo,
        Errors,
      }) => {
        const isEnabled = CurrentStatus === LandingZoneStatus.CREATED;
        const microServicesConfigured = MicroserviceInfo?.status === LzMicroserviceStatus.CREATED;
        const microServicesError = MicroserviceInfo?.status === LzMicroserviceStatus.ERROR;
        return {
          landingZoneId: LandingZoneId,
          landingZoneEnabled: isEnabled,
          landingZoneConfigured: isEnabled,
          landingZoneName: LandingZoneName,
          projectName: GCPProjectId,
          loadingLandingZone: !isEnabled,
          loadingEtlWorkload: false,
          sector: Sector,
          loadingMicroServicesWorkload: Boolean(
            isEnabled && MicroserviceInfo?.ids && !microServicesConfigured && !microServicesError,
          ),
          workLoadPatternMicroServicesEnabled: microServicesConfigured,
          workLoadPatternEtlEnabled: false,
          workLoadPatternMicroServicesConfigured: microServicesConfigured,
          workLoadPatternMicroServicesError: microServicesError,
          workLoadPatternEtlConfigured: false,
          cloudServiceProvider: CloudServiceProvider,
          workLoadPatternMicroServicesId: MicroserviceInfo?.ids,
          landingZoneStatus: CurrentStatus,
          errorMessage: Errors,
        };
      },
    );

    yield put(BuildDeployActions.getBuildDeployDetailsSuccess(landingZones));
    yield put(BuildDeployActions.getLandingZoneListSuccess(landingZoneList));
   

    for (const { landingZoneName, workLoadPatternMicroServicesId } of landingZones.filter(
      (landingZone) => landingZone.loadingMicroServicesWorkload,
    )) {
      if (workLoadPatternMicroServicesId)
        yield put(
          BuildDeployActions.checkMicroServicesPatternStatus({
            microServiceId: workLoadPatternMicroServicesId,
            landingZoneName: landingZoneName,
          }),
        );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getBuildDeployDetailsError(error));
    }
  }
}
export function* getArchitectureListWorker(action: BuildDeployAction) {
  try {
    const architectureList: IArchitectureCatalogueResponse[] =
      yield BuildDeployService.getArchitectureListServive(action?.data as string);
    yield put(
      BuildDeployActions.getArchitectureListSuccess(
        architectureList as IArchitectureCatalogueResponse[],
      ),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getArchitectureListError(error));
    }
  }
}
export function* getDeploymentsWorker(action: BuildDeployAction) {
  try {
    //API call to get all deployment work flows
    const projectId = action.data as string;
    const deployments: IDeploymentsResponse[] = yield BuildDeployService.getDeploymentsList(
      projectId,
    );

    yield put(BuildDeployActions.getDeploymentDetailsSuccess(deployments));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getDeploymentDetailsError(error));
    }
  }
}
export function* getMultiCloudYamlContentWorker(action: BuildDeployAction) {
  try {
    const response: Record<string, unknown> = yield BuildDeployService.getMultiCloudYamlContent(
      action.data as IFormInterface,
    );
    yield put(BuildDeployActions.getMultiCloudYamlContentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getMultiCloudYamlContentError(error));
    }
  }
}

export function* buildDeployWatcher() {
  yield takeEvery(BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_STARTED, getLandingZonesWorker);
  yield takeEvery(BuildDeployActionTypes.GET_ARCHITECTURE_LIST_STARTED, getArchitectureListWorker);
  yield takeEvery(BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_STARTED, getDeploymentsWorker);
  yield takeEvery(BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_STARTED, getMultiCloudYamlContentWorker);
}
