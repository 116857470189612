import { Reducer } from 'redux';
import { EngagementPhases } from '../../../../components/CBO/CBOConstants';
import {
  IJourneyCapabilitiesData,
  IJourneyCapabilitiesResponse,
} from '../../../models/cbo/EngagementModels';
import {
  IJourneyList,
  IJourneyMapping,
  IJourneySaveResponse,
  IMarketplaceAssets,
  IPredefinedJourneyListMapping,
  IRecommendedAssetsResponse,
} from '../../../models/cbo/JourneyBuilderModel';
import {
  JourneyBuilderAction,
  JourneyBuilderActionTypes,
} from '../../actions/cbo/JourneyBuilderActions';
import JourneyBuilderState from '../../state/cbo/JourneyBuilderState';

const initialJourneyRecord: IJourneyList[] = [
  {
    journeyId: '',
    journeyName: '',
    industry: '',
    sector: '',
    predefinedJourney: '',
    capabilityList: [''],
    createdOn: '',
    createdBy: '',
    status: '',
    journeyComment: '',
    journeyType: '',
  },
];

const defaultJourneyCapabilitiesData: IJourneyCapabilitiesData[] = [
  {
    capabilityId: '',
    phase: EngagementPhases.ASSESS,
    capabilityName: '',
    capabilityInfo: '',
    enabled: false,
    products: [],
    relevance: '',
    sequenceNumber: 0,
    features: [],
  },
];

const initialRecommendedData: IRecommendedAssetsResponse = {
  assets: [],
  count: 0,
};

const initialJourneySaveResponseData: IJourneySaveResponse = {
  journeyId: '',
  journeyName: '',
  msg: '',
};

export const initialState: JourneyBuilderState = {
  journeyRecords: [],
  journeyListLoading: false,
  loading: false,
  error: '',
  predefinedJourneyList: [],
  journeyCapabilitiesResponse: {} as IJourneyCapabilitiesResponse,
  recommendedAssets: initialRecommendedData,
  mandatoryAssets: [],
  RecommendedAssetsLoading: false,
  journeyConfigSaveResponse: initialJourneySaveResponseData,
  businessObjectives: [],
  businessObjectivesLoading: false,
  journeyMapping: {} as IJourneyMapping,
  journeyMappingLoading: false,
  deletedJourneyLoading: false,
};

export const JourneyBuilderReducer: Reducer<JourneyBuilderState> = (
  state = initialState,
  action: JourneyBuilderAction,
) => {
  switch (action.type) {
    case JourneyBuilderActionTypes.GET_JOURNEY_LIST_DATA_STARTED:
      return {
        ...state,
        journeyListLoading: true,
        error: undefined,
        journeyRecords: [],
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_LIST_DATA_SUCCESS:
      return {
        ...state,
        error: undefined,
        journeyRecords: action.data as IJourneyList[],
        journeyListLoading: false,
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_LIST_DATA_ERROR:
      return {
        ...state,
        journeyListLoading: false,
        error: action.data as Error,
      };
    case JourneyBuilderActionTypes.GET_PREDEFINED_JOURNEY_LIST_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        predefinedJourneyList: [],
      };
    case JourneyBuilderActionTypes.GET_PREDEFINED_JOURNEY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        predefinedJourneyList: action.data as IPredefinedJourneyListMapping[],
      };
    case JourneyBuilderActionTypes.GET_PREDEFINED_JOURNEY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };

    case JourneyBuilderActionTypes.GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        journeyCapabilitiesResponse: {} as IJourneyCapabilitiesResponse,
      };
    case JourneyBuilderActionTypes.GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        journeyCapabilitiesResponse: action.data as IJourneyCapabilitiesResponse,
      };
    case JourneyBuilderActionTypes.GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case JourneyBuilderActionTypes.GET_JOURNEY_MANDATORY_ASSETS_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        mandatoryAssets: [],
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_MANDATORY_ASSETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        mandatoryAssets: action.data as IMarketplaceAssets[],
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_MANDATORY_ASSETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_RECOMMENDED_ASSETS_STARTED:
      return {
        ...state,
        RecommendedAssetsLoading: true,
        error: undefined,
        recommendedAssets: initialRecommendedData,
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_RECOMMENDED_ASSETS_SUCCESS:
      return {
        ...state,
        RecommendedAssetsLoading: false,
        error: undefined,
        recommendedAssets: action.data as IRecommendedAssetsResponse,
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_RECOMMENDED_ASSETS_ERROR:
      return {
        ...state,
        RecommendedAssetsLoading: false,
        error: action.data as Error,
      };

    case JourneyBuilderActionTypes.POST_JOURNEY_BUILDER_SAVE_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        journeyConfigSaveResponse: initialJourneySaveResponseData,
      };
    case JourneyBuilderActionTypes.POST_JOURNEY_BUILDER_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        journeyConfigSaveResponse: action.data as IJourneySaveResponse,
      };
    case JourneyBuilderActionTypes.POST_JOURNEY_BUILDER_SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case JourneyBuilderActionTypes.DELETE_DOCUMENT_DATA_STARTED:
      return {
        ...state,
        loading: true,
      };
    case JourneyBuilderActionTypes.DELETE_DOCUMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case JourneyBuilderActionTypes.DELETE_DOCUMENT_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case JourneyBuilderActionTypes.POST_SUBMIT_STATUS_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case JourneyBuilderActionTypes.POST_SUBMIT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case JourneyBuilderActionTypes.POST_SUBMIT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };

    case JourneyBuilderActionTypes.PATCH_JOURNEY_BUILDER_UPDATE_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        journeyConfigSaveResponse: initialJourneySaveResponseData,
      };
    case JourneyBuilderActionTypes.PATCH_JOURNEY_BUILDER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        journeyConfigSaveResponse: action.data as IJourneySaveResponse,
      };
    case JourneyBuilderActionTypes.PATCH_JOURNEY_BUILDER_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case JourneyBuilderActionTypes.RESET_SAVE_JOURNEY_DATA:
      return {
        ...state,
        journeyConfigSaveResponse: initialJourneySaveResponseData,
      };
    case JourneyBuilderActionTypes.GET_BUSINESS_OBJECTIVES__STARTED:
      return {
        ...state,
        businessObjectivesLoading: true,
        error: undefined,
        businessObjectives: [],
      };
    case JourneyBuilderActionTypes.GET_BUSINESS_OBJECTIVES_SUCCESS:
      return {
        ...state,
        error: undefined,
        businessObjectives: action.data as string[],
        businessObjectivesLoading: false,
      };
    case JourneyBuilderActionTypes.GET_BUSINESS_OBJECTIVES_ERROR:
      return {
        ...state,
        businessObjectivesLoading: false,
        error: action.data as Error,
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_MAPPING_STARTED:
      return {
        ...state,
        journeyMappingLoading: true,
        error: undefined,
        journeyMapping: {} as IJourneyMapping,
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_MAPPING_SUCCESS:
      return {
        ...state,
        journeyMappingLoading: false,
        error: undefined,
        journeyMapping: action.data as IJourneyMapping,
      };
    case JourneyBuilderActionTypes.GET_JOURNEY_MAPPING_ERROR:
      return {
        ...state,
        journeyMappingLoading: false,
        error: action.data as Error,
      };
    case JourneyBuilderActionTypes.DELETE_JOURNEY_MAPPING_STARTED:
      return {
        ...state,
        deletedJourneyLoading: true,
        error: undefined,
        deleteResp: {} as any,
      };
    case JourneyBuilderActionTypes.DELETE_JOURNEY_MAPPING_SUCCESS:
      return {
        ...state,
        deletedJourneyLoading: false,
        error: undefined,
        deleteResp: action.data as any,
      };
    case JourneyBuilderActionTypes.DELETE_JOURNEY_MAPPING_ERROR:
      return {
        ...state,
        deletedJourneyLoading: false,
        error: action.data as Error,
      };
    case JourneyBuilderActionTypes.RESET_JOURNEY_BUILDER_DATA:
      return {
        ...state,
        journeyCapabilitiesResponse: {} as IJourneyCapabilitiesResponse,
        recommendedAssets: initialRecommendedData,
        mandatoryAssets: [],
        RecommendedAssetsLoading: false,
        journeyConfigSaveResponse: initialJourneySaveResponseData,
        businessObjectives: [],
        businessObjectivesLoading: false,
        journeyMapping: {} as IJourneyMapping,
        journeyMappingLoading: false,
        deletedJourneyLoading: false,
      };

    default:
      return state;
  }
};
