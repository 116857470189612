import { put, takeEvery } from 'redux-saga/effects';
import RefreshTokenService from '../../../services/cbo/RefreshTokenService/RefreshTokenService';
import {
  RefreshTokenAction, RefreshTokenActions, RefreshTokenActionTypes,
} from '../../actions/cbo/RefreshTokenActions';
import { IRefreshTokenResponse } from '../../state/cbo/RefreshTokenState';
import { ToastActions } from "../../actions/ToastActions";
import { NotificationTypes } from "../../../../components/Notifications/NotificationsConstants";

export function* refreshTokenWorker(action: RefreshTokenAction) {
  try {
    const response: IRefreshTokenResponse = yield RefreshTokenService.refreshToken(action.data as string);
    yield put(RefreshTokenActions.refreshTokenSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(RefreshTokenActions.refreshTokenError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* refreshTokenWatcher() {
  yield takeEvery(RefreshTokenActionTypes.REFRESH_TOKEN_STARTED, refreshTokenWorker);
}
