import { Reducer } from 'redux';
import {
  HitsAnalyticsAction,
  HitsAnalyticsActionTypes,
  IAnalyticsAreaChartResponse,
  IAnalyticsPieChartResponse,
} from '../../actions/WebAnalytics/HitsAnalyticsActions';
import IHitsAnalyticsState from '../../state/HitAnalyticsState';

export const initialState: IHitsAnalyticsState = {
  error: '',
  loading: false,
  newVsReturningUsersData: [],
  newVsReturningUsersLoading: false,
  activeUserLoading: false,
  visitsLoading: false,
  pageViewsLoading: false,
  bounceRateLoading: false,
  activeUsersData: {
    chartValues: [],
    totalCount: 0,
  },
  pageViewsData: {
    chartValues: [],
    totalCount: 0,
  },
  visitsData: {
    chartValues: [],
    totalCount: 0,
  },
  bounceRateData: {
    chartValues: [],
    totalCount: 0,
  },
};

export const HitsAnalyticsReducer: Reducer<IHitsAnalyticsState> = (
  state = initialState,
  action: HitsAnalyticsAction,
) => {
  switch (action.type) {
    case HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_ERROR:
      return {
        ...state,
        newVsReturningUsersLoading: true,
      };
    case HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_SUCCESS:
      return {
        ...state,
        newVsReturningUsersLoading: false,
        newVsReturningUsersData: action.data as IAnalyticsPieChartResponse[],
      };
    case HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_ERROR:
      return {
        ...state,
        newVsReturningUsersLoading: false,
        error: action.data as Error,
      };
    case HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_START:
      return {
        ...state,
        activeUserLoading: true,
      };
    case HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_SUCCESS:
      return {
        ...state,
        activeUserLoading: false,
        activeUsersData: action.data as IAnalyticsAreaChartResponse,
      };
    case HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_ERROR:
      return {
        ...state,
        activeUserLoading: false,
        error: action.data as Error,
      };
    case HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_START:
      return {
        ...state,
        visitsLoading: true,
      };
    case HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_SUCCESS:
      return {
        ...state,
        visitsData: action.data as IAnalyticsAreaChartResponse,
        visitsLoading: false,
      };
    case HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
        visitsLoading: false,
      };
    case HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_START:
      return {
        ...state,
        pageViewsLoading: true,
      };

    case HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_SUCCESS:
      return {
        ...state,
        pageViewsData: action.data as IAnalyticsAreaChartResponse,
        pageViewsLoading: false,
      };

    case HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
        pageViewsLoading: false,
      };

    case HitsAnalyticsActionTypes.GET_BOUNCE_RATE_DATA_START:
      return {
        ...state,
        bounceRateLoading: true,
      };
    case HitsAnalyticsActionTypes.GET_BOUNCE_RATE_DATA_SUCCESS:
      return {
        ...state,
        bounceRateData: action.data as IAnalyticsAreaChartResponse,
        bounceRateLoading: false,
      };
    case HitsAnalyticsActionTypes.GET_BOUNCE_RATE_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
        bounceRateLoading: false,
      };

    default:
      return state;
  }
};
