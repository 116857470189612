import {
  IJourneyCapabilitiesResponse,
} from '../../../models/cbo/EngagementModels';
import {
  IDocumentDeletePayload,
  IJourneyBuilderPayload,
  IJourneyList,
  IJourneyMarketplacePayload,
  IMarketplaceAssets,
  IPrdefinedJouneyList,
  ISubmitStatuspayload,
  IJourneyCapabilityListPayload,
  IRecommendedAssetsResponse,
  IJourneyMapping
} from '../../../models/cbo/JourneyBuilderModel';
import { API_ROUTE_CBO } from '../../ApiRoute';
import ApiService from '../../ApiService';
import ApiServiceBase from '../../ApiServiceBase';
import { ServerType, ServiceType } from '../../ServiceType.data';

export class JourneyBuilderService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);
  // private readonly localService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.localStack);

  public getJourneyList(): Promise<IJourneyList[]> | IJourneyList[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_JOURNEY_BUILDER_LIST}`];
    return this.cboService.get({ route: route });
  }

  public getPredefinedJourneyList(): Promise<IPrdefinedJouneyList[]> | IPrdefinedJouneyList[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_PREDEFINED_JOURNEY_LIST}`];
    return this.cboService.get({ route: route });
  }

  public getCapabilitiesForPredefinedJourney(
    data: IJourneyCapabilityListPayload,
  ): Promise<IJourneyCapabilitiesResponse> | IJourneyCapabilitiesResponse {
    const route: string[] = [`${API_ROUTE_CBO.JOURNEY_BUILDER}/${data.journeyId}`];
    return this.cboService.get({ route: route, query: { action: data.action } });
  }

  public getJourneyMandatoryAssets(
    data: IJourneyMarketplacePayload,
  ): Promise<IMarketplaceAssets[]> | IMarketplaceAssets[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_JOURNEY_MANDATORY_ASSETS}`];
    return this.cboService.post({ route: route }, { capabilities: data.capabilities });
  }

  public getJourneyRecommendedAssets(
    data: IJourneyMarketplacePayload,
  ): Promise<IRecommendedAssetsResponse> | IRecommendedAssetsResponse {
    const route: string[] = [
      `${API_ROUTE_CBO.GET_JOURNEY_RECOMMENDED_ASSETS.replace(`:journeyId`, data.journeyId)}`,
    ];
    return this.cboService.post(
      { route: route, query: { assetCategory: 'recommended', action: data.action } },
      { capabilities: data.capabilities },
    );
  }

  public postJourneyBuilderSave(
    body: IJourneyBuilderPayload,
  ): Promise<IJourneyBuilderPayload> | IJourneyBuilderPayload {
    const route: string[] = [`${API_ROUTE_CBO.POST_JOURNEY_BUILDER_SAVE}`];
    return this.cboService.post({ route: route }, body);
  }

  public deleteDocument(data: string): Promise<any> | any {
    return this.cboService.delete({ route: [`${API_ROUTE_CBO.DELETE_DOCUMENT}/${data}`] });
  }

  public postSubmitStatus(
    body: ISubmitStatuspayload,
  ): Promise<IJourneyBuilderPayload> | IJourneyBuilderPayload {
    const route: string[] = [`${API_ROUTE_CBO.POST_SUBMIT_STATUS}`];
    return this.cboService.post({ route: route }, body);
  }

  public patchJourneyBuilderUpdate(
    body: IJourneyBuilderPayload,
  ): Promise<IJourneyBuilderPayload> | IJourneyBuilderPayload {
    const route: string[] = [`${API_ROUTE_CBO.JOURNEY_BUILDER}`];
    return this.cboService.patch({ route: route }, body);
  }

  public getBusinessObjectives(): Promise<string[]> | string[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_BUSINESS_OBJECTIVES}`];
    return this.cboService.get({ route: route });
  }
  public getJourneyMapping(data:string): Promise<IJourneyMapping> | IJourneyMapping {
    const route: string[] = [`${API_ROUTE_CBO.GET_JOURNEY_MAPPING.replace(':journeyId', data!)}`];
    return this.cboService.get({ route: route });
  }
  public deleteJourney(data:string): Promise<any> | any {
    const route: string[] = [`${API_ROUTE_CBO.DELETE_JOURNEY.replace(':journeyId', data!)}`];
    return this.cboService.delete({ route: route });
  }
}

const journeyBuilderService = new JourneyBuilderService();
export default journeyBuilderService;
