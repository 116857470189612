import {
  IMarketplaceSystemTags,
  IFilterPayload,
  IPostFilterResponse,
  ISearchAndFilterAssetsPayload,
  ISearchAndFilterAssetsResponse,
  IJourneyFilter,
  IImportantLinks,
} from '../../../models/cbo/CboMarketplaceSearchModels';
import { API_ROUTE_CBO } from '../../ApiRoute';
import ApiService from '../../ApiService';
import ApiServiceBase from '../../ApiServiceBase';
import { ServerType, ServiceType } from '../../ServiceType.data';

export class MarketplaceSearchService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);
  // private readonly localCboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.localStack);

  public getAssetMetaData(): Promise<string[]> | string[] {
    const route: string[] = [`${API_ROUTE_CBO.ASSET_CATEGORY}`];
    return this.cboService.get({ route: route });
  }

  public getSystemTagsData(): Promise<IMarketplaceSystemTags[]> | IMarketplaceSystemTags[] {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.SYSTEM_TAGS}`],
    });
  }

  public postSearchFilterData(
    data: ISearchAndFilterAssetsPayload,
  ): Promise<ISearchAndFilterAssetsResponse> | ISearchAndFilterAssetsResponse {
    return this.cboService.post(
      {
        route: [`${API_ROUTE_CBO.GET_MARKETPLACE_ASSETS}`],
      },
      data,
    );
  }

  public postFilterUrlData(
    data: IFilterPayload,
  ): Promise<IPostFilterResponse> | IPostFilterResponse {
    return this.cboService.post(
      {
        route: [`${API_ROUTE_CBO.POST_FILTER_DATA}`],
      },
      data,
    );
  }

  public getFilterData(filterId: string): Promise<IFilterPayload> | IFilterPayload {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_FILTER_DATA}/${filterId}`],
    }, );
  }

  public getFilterJourneyList(): Promise<IJourneyFilter[]> | IJourneyFilter[] {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_JOURNEY_FILTER_LIST}`],
    }, );
  }

  public getImportantLinks(searchText:string): Promise<IImportantLinks[]> | IImportantLinks[] {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_IMPORTANT_LINKS}`],
      query: {
        searchText: searchText,
      },
    }, );
  }
}

const marketplaceSearchService = new MarketplaceSearchService();
export default marketplaceSearchService;
