import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import { LoginLabels } from '../../../containers/CBOLogin/CBOLoginConstants';
import {
  ILoginPayload,
  ILoginResponse,
  ISessionTokenResponse,
  ITangraLoginPayload,
  ITangraLoginResponse,
  ICboLoginResponse,
  ICustomLoginPayload,
  IChangePasswordPayload,
} from '../../models/LoginModels';
import { storage, STORAGE_CONSTANTS } from '../../services/LocalStorage';
import LoginService from '../../services/login/LoginService';
import { LoginAction, LoginActions, LoginActionTypes } from '../actions/LoginActions';
import { ToastActions } from '../actions/ToastActions';
import { NotificationPlacement } from 'antd/lib/notification';
import {
  SectionType,
  TrafficAnalyticsActions,
} from '../actions/WebAnalytics/TrafficAnalyticsActions';

export function* postTangraLoginWorker(action: LoginAction) {
  try {
    const data = action.data as ITangraLoginPayload;
    const response: ICboLoginResponse = (yield LoginService.postTangraLogin(data))!;
    yield put(LoginActions.postTangraLoginSuccess(response));
    yield put(
      ToastActions.showToast({
        description: LoginLabels.succesfulLoginText,
        type: NotificationTypes.SUCCESS,
      }),
    );
    if (navigator.platform.indexOf('Win') !== -1) {
      yield put(
        ToastActions.showToast({
          description: `Best viewed at 67% zoom for windows`,
          type: NotificationTypes.INFO,
          duration: 0,
          placement: 'top' as NotificationPlacement,
        }),
      );
    }
    yield put(LoginActions.sessionTokenStart());
  } catch (error) {
    yield put(LoginActions.postTangraLoginError(error));
    if (error instanceof Error) {
      yield put(LoginActions.postTangraLoginError(error));
    }
  }
}

//API Worker to fetch session token.
export function* getSessionToken(action: LoginAction) {
  try {
    const response: ISessionTokenResponse = (yield LoginService.getSessionToken())!;
    if (response?.activityId) {
      storage.setItem(STORAGE_CONSTANTS.sessionToken, response.activityId);
      yield put(LoginActions.sessionTokenSuccess());
      yield put(
        TrafficAnalyticsActions.postVisitedPageStart({
          sectionType: SectionType.LOGIN,
        }),
      );
      yield put(
        TrafficAnalyticsActions.postVisitedPageStart({
          sectionType: SectionType.PURSUIT_DASHBOARD,
        }),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(LoginActions.sessionTokenError(error));
    }
  }
}

export function* postSessionToken(action: LoginAction) {
  try {
    const response: ISessionTokenResponse = (yield LoginService.postSessionToken())!;
    yield put(LoginActions.sessionTokenPostSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(LoginActions.sessionTokenPostError(error));
    }
  }
}

export function* postCustomLogin(action: LoginAction) {
  try {
    const data = action.data as ICustomLoginPayload;
    const response: ICboLoginResponse = (yield LoginService.postCustomLogin(data))!;
    yield put(LoginActions.postCustomLoginSuccess(response));
    if (response?.id_token) {
      yield put(
        ToastActions.showToast({
          description: LoginLabels.succesfulLoginText,
          type: NotificationTypes.SUCCESS,
        }),
      );
      if (navigator.platform.indexOf('Win') !== -1) {
        yield put(
          ToastActions.showToast({
            description: `Best viewed at 67% zoom for windows`,
            type: NotificationTypes.INFO,
            duration: 0,
            placement: 'top' as NotificationPlacement,
          }),
        );
      }
      yield put(LoginActions.sessionTokenStart());
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(LoginActions.postCustomLoginError(error));
      yield put(
        ToastActions.showToast({
          message: 'Login Failed!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* postChangePassword(action: LoginAction) {
  try {
    const data = action.data as IChangePasswordPayload;
    const response: ICboLoginResponse = (yield LoginService.postChangePassword(data))!;
    yield put(LoginActions.postChangePasswordSuccess(response));
    if (response?.id_token) {
      yield put(
        ToastActions.showToast({
          description: LoginLabels.succesfulLoginText,
          type: NotificationTypes.SUCCESS,
        }),
      );
      if (navigator.platform.indexOf('Win') !== -1) {
        yield put(
          ToastActions.showToast({
            description: `Best viewed at 67% zoom for windows`,
            type: NotificationTypes.INFO,
            duration: 0,
            placement: 'top' as NotificationPlacement,
          }),
        );
      }
      yield put(LoginActions.sessionTokenStart());
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(LoginActions.postChangePasswordError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* loginWatcher() {
  yield takeEvery(LoginActionTypes.TANGRA_LOGIN_REQUEST, postTangraLoginWorker);
  yield takeEvery(LoginActionTypes.SESSION_LOGIN_TOKEN_START, getSessionToken);
  yield takeEvery(LoginActionTypes.POST_SESSION_LOGIN_TOKEN_START, postSessionToken);
  yield takeEvery(LoginActionTypes.CUSTOM_LOGIN_START, postCustomLogin);
  yield takeEvery(LoginActionTypes.CHANGE_PASSWORD_START, postChangePassword);
}
