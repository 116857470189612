/* eslint-disable max-lines */
import { Reducer } from 'redux';
import { BuildDeployAction, BuildDeployActionTypes } from '../actions/BuildDeployActions';
import BuildDeployState, {
  IArchitectureCatalogueResponse,
  IDeploymentsResponse,
  ILandingZoneState,
} from '../state/BuildDeployState';
import { ILandingZoneResponse } from '../../models/BuildDeployModels';

export const initialState: BuildDeployState = {
  architectureList: [] as IArchitectureCatalogueResponse[],
  architectureListLoading: false,
  loading: false,
  error: '',
  landingZones: [],
  multiCloudYamlContent:
    'Environments: capabilities_multicloud: anthos_gcp: - bastion_name: bastionname cluster_tags: sd enable_feature: truegke_cluster_name: newvaluesmaster_ipv4_cidr_block: 10.0.3.0/28max_node_count: 4max_pods_per_node: 8min_node_count: 3',
  landingZoneList: [],
  deployments: [],
};

export const buildDeployReducer: Reducer<BuildDeployState> = (
  state = initialState,
  action: BuildDeployAction,
) => {
  switch (action.type) {
    case BuildDeployActionTypes.GET_ARCHITECTURE_LIST_STARTED:
      return {
        ...state,
        architectureListLoading: true,
      };
    case BuildDeployActionTypes.GET_ARCHITECTURE_LIST_SUCCESS:
      return {
        ...state,
        architectureList: action.data as IArchitectureCatalogueResponse[],
        architectureListLoading: false,
      };
    case BuildDeployActionTypes.GET_ARCHITECTURE_LIST_ERROR:
      return {
        ...state,
        architectureListLoading: false,
      };

    case BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_SUCCESS:
      return {
        ...state,
        landingZones: action.data as ILandingZoneState[],
        loading: false,
      };
    case BuildDeployActionTypes.GET_LANDING_ZONE_LIST_SUCCESS:
      return {
        ...state,
        landingZoneList: action.data as ILandingZoneResponse[],
        loading: false,
      };
    case BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case BuildDeployActionTypes.RESET_BUILD_DEPLOY_STATE:
      return {
        ...state,
        landingZones: [],
        architectureList: [] as IArchitectureCatalogueResponse[],
      };
    case BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_SUCCESS:
      return {
        ...state,
        multiCloudYamlContent: action.data as any,
        loading: false,
      };
    case BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        deployments: action.data as IDeploymentsResponse[],
        loading: false,
      };
    case BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    default:
      return state;
  }
};
