import { CloudServiceProvider } from '../../AppConstants';

export interface ILandingZoneResponse {
  LandingZoneName: string;
  CurrentStatus: LandingZoneStatus;
  LandingZoneId: string;
  Errors: string;
  Sector: ILandingZoneSector;
  GCPProjectId: string;
  MicroserviceInfo: {
    status: LzMicroserviceStatus;
    ids: string;
  };
  CloudServiceProvider: CloudServiceProvider;
  CreatedOn: string;
}
export enum ILandingZoneSector {
  GENERAL = 'GENERAL',
  CONSUMER = 'CONSUMER',
  FINANCIAL = 'FINANCIAL SERVICES',
  ENERGY = 'ENERGY, RESOURCES & INDUSTRIALS',
  GOVERNMENT = 'GOVERNMENT & PUBLIC SERVICES',
  HEALTH = 'HEALTHCARE',
  TECHNOLOGY = 'TECHNOLOGY, MEDIA & TELECOMMUNICATION',
}

export enum LandingZoneStatus {
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  ERROR = 'Error',
  DELETE_IN_PROFRESS = 'delete in progress',
}

export enum LzMicroserviceStatus {
  NOT_ENABLED = 'notEnabled',
  CREATED = 'Created',
  IN_PROGRESS = 'inProgress',
  ERROR = 'Error',
}

export interface IUploadFileBody {
  key: string;
  AWSAccessKeyId: string;
  'x-amz-security-token': string;
  policy: string;
  signature: string;
  file?: File;
}

export interface IGetPresignedURLToUploadResponse {
  message: string;
  url: {
    url: string;
    fields: IUploadFileBody;
  };
}

export interface IGetPresignedURLResponse {
  message: string;
  url: string;
}

export interface IBuildDeployErrorState {
  errorKey: string;
  errorValue: string[];
}

export enum IDeployStatus {
  DEPLOYED = 'Deployed',
  YET_TO_START = 'Yet To Start',
}
