    import { API_ROUTE_CBO } from '../../ApiRoute';
    import ApiService from '../../ApiService';
    import ApiServiceBase from '../../ApiServiceBase';
    import { ServerType, ServiceType } from '../../ServiceType.data';

    export class RBACService {
      private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);

      public getRBACData(payload:string[]): Promise<string[]> | string[] {
        const route: string[] = [`${API_ROUTE_CBO.GET_RBAC_DATA}`];
        return this.cboService.post({ route: route }, payload);
      }

    }

    const rbacService = new RBACService();
    export default rbacService;
