import { Reducer } from 'redux';
import {RefreshTokenAction, RefreshTokenActionTypes} from '../../actions/cbo/RefreshTokenActions';
import IRefreshTokenState, { IRefreshTokenResponse } from '../../state/cbo/RefreshTokenState';

export const initialState: IRefreshTokenState = {
    error: '',
    loading: false,
    refreshData: {
      id_token: '',
      access_token: '',
      expires_in: 0,
      token_type: ''
    }
}
export const RefreshTokenReducer: Reducer<IRefreshTokenState> = (
    state = initialState,
    action: RefreshTokenAction,
  ) => {
    switch (action.type) {
      case RefreshTokenActionTypes.REFRESH_TOKEN_STARTED:
        return {
          ...state,
          loading: true,
          error: undefined,
        };
      case RefreshTokenActionTypes.REFRESH_TOKEN_SUCCESS:
        return {
          ...state,
          loading: false,
          error: undefined,
          refreshData: action.data as IRefreshTokenResponse,
        };
      case RefreshTokenActionTypes.REFRESH_TOKEN_ERROR:
        return {
          ...state,
          loading: false,
          error: action.data
        };
    
      default:
        return state;
    }
  }
        
