import { IStoreAction, IStoreFlagAction } from '../../StoreHelper';
import { IConfigurePayload, IEngagementConfigurationResponse, IEngagementListForAdminRequestPayload, IEngagementListForAdminResponse, IEngagementResponse } from '../../../models/cbo/EngagementModels';
export enum EngagementSummaryActionTypes {
  GET_ENGAGEMENT_SUMMARY_STARTED = 'GET_ENGAGEMENT_SUMMARY_STARTED',
  GET_ENGAGEMENT_SUMMARY_SUCCESS = 'GET_ENGAGEMENT_SUMMARY_SUCCESS',
  GET_ENGAGEMENT_SUMMARY_ERROR = 'GET_ENGAGEMENT_SUMMARY_ERROR',
  GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_STARTED = 'GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_STARTED',
  GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_SUCCESS = 'GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_SUCCESS',
  GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_ERROR = 'GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_ERROR',
  DELETE_ENGAGEMENTCONFIG_DATA_STARTED = 'DELETE_ENGAGEMENTCONFIG_DATA_STARTED',
  DELETE_ENGAGEMENTCONFIG_DATA_SUCCESS = 'DELETE_ENGAGEMENTCONFIG_DATA_SUCCESS',
  DELETE_ENGAGEMENTCONFIG_DATA_ERROR = 'DELETE_ENGAGEMENTCONFIG_DATA_ERROR',
  RESET_DELETE_ENGAGEMENT_SUCCESSFULL= 'RESET_DELETE_ENGAGEMENT_SUCCESSFULL',
  // fetch data

  deleteEngagementConfigDataError = "deleteEngagementConfigDataError"
}
export type EngagementSummaryActionPayload =   
    boolean
  | Error
  | IEngagementResponse
  | number
  | string
  | IEngagementConfigurationResponse
  | IEngagementListForAdminRequestPayload
  | IEngagementListForAdminResponse
  | IConfigurePayload

export type EngagementSummaryAction = IStoreFlagAction<EngagementSummaryActionTypes, EngagementSummaryActionPayload, boolean>;

export class EngagementSummaryActions {

  public static getEngagementStarted(data: boolean): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_STARTED,
      data
    };
  }
  public static getEngagemenSuccess(data: IEngagementResponse, isEngagement:boolean): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_SUCCESS,
      data,
      flag:isEngagement
    };
  }
  public static getEngagementError(data: Error): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_ERROR,
      data,
    };
  }
  public static getEngagementListForAdminStarted(data: IEngagementListForAdminRequestPayload): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_STARTED,
      data
    };
  }
  public static getEngagementListForAdminSuccess(data: IEngagementListForAdminResponse, isEngagement:boolean): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_SUCCESS,
      data,
      flag:isEngagement 
    };
  }
  public static getEngagementListForAdminError(data: Error): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_ERROR,
      data,
    };
  }
  public static deleteEngagementConfigDataStart(data: IConfigurePayload): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.DELETE_ENGAGEMENTCONFIG_DATA_STARTED,
      data,
    };
  }
  public static deleteEngagementConfigDataSuccess(): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.DELETE_ENGAGEMENTCONFIG_DATA_SUCCESS,
    };
  }
  public static deleteEngagementConfigDataError(data: Error): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.DELETE_ENGAGEMENTCONFIG_DATA_ERROR,
      data
    };
  }
  public static resetDeleteEngagementSuccessfull(): EngagementSummaryAction {
    return {
      type: EngagementSummaryActionTypes.RESET_DELETE_ENGAGEMENT_SUCCESSFULL,
    };
  }
}
