import { useEffect, useState } from 'react';

class Storage {
  private readonly localstorage = window.localStorage;
  private readonly sessionstorage = window.sessionStorage;

  public setItemSession(key: string, item: string) {
    this.sessionstorage.setItem(key, item);
  }
  public getItemSession(key: string) {
    return this.sessionstorage.getItem(key);
  }
  public setItem(key: string, item: string) {
    this.localstorage.setItem(key, item);
  }

  public getItem(key: string) {
    return this.localstorage.getItem(key);
  }

  public removeItem(key: string) {
    this.localstorage.removeItem(key);
  }
  public removeSessionItem(key: string) {
    this.sessionstorage.removeItem(key);
  }

  public clearAll() {
    this.localstorage.clear();
    this.sessionstorage.clear();
  }
}
export const STORAGE_CONSTANTS = {
  userId: 'userId',
  userType: 'userType',
  accessToken: 'accessToken',
  authTokenBasic: 'authTokenBasic',
  refreshToken: 'refreshToken',
  cwbIdToken: 'cwbIdToken',
  cwbAccessToken: 'cwbAccessToken',
  cwbRefreshToken: 'cwbRefreshToken',
  cwbTokenExpiry: 'cwbTokenExpiry',
  cmsAccessToken: 'cmsAccessToken',
  cmsRefreshToken: 'cmsRefreshToken',
  assessAccessToken: 'assessAccessToken',
  assessConfigAccessToken: 'assessConfigAccessToken',
  assessTokenExpiry: 'assessTokenExpiry',
  assessConfigTokenExpiry: 'assessConfigTokenExpiry',
  vpnConnected: 'vpnConnected',
  hideRegister: 'hideRegister',
  cwbUserDetails: 'cwbUserDetails',
  userName: 'userName',
  userDetails: 'userDetails',
  email: 'email',
  authorizeNowModalOpened: 'authorizeNowModalOpened',
  sessionToken: 'sessionToken',
  cbAccessToken: 'cbAccessToken',
  userGroup: 'userGroup',
  first_login_response: 'first_login_response',
  idToken: 'idToken',
  fetchingRefreshToken: 'fetchingRefreshToken',
  adRefreshToken: 'adRefreshToken',
  adAccessToken: 'adAccessToken',
  authOriginatorUrl: 'authOriginatorUrl',
  sessionState: 'sessionState',
  assessSSOTimeout: 'assessSSOTimeout',
  useProfilePic: 'useProfilePic',
  dcaAuthenticated: 'dcaAuthenticated',
  dcaEngAuthenticated: 'dcaEngAuthenticated',
  cwbAuthenticated: 'cwbAuthenticated',
  turboCodeDemoAuthenticated: 'turboCodeDemoAuthenticated',
  turboCodeConfigAuthenticated: 'turboCodeConfigAuthenticated',
  turboCodeDemoAPIAuthenticated: 'turboCodeDemoAPIAuthenticated',
  turboCodeConfigAPIAuthenticated: 'turboCodeConfigAPIAuthenticated',
  zoomValue: 'zoomValue',
  keyAssetList: 'KeyAssetList',
  isEngagement: 'isEngagement',
  turboCodeAuthorizing: 'turboCodeAuthorizing',
  depAuthenticated: 'depAuthenticated',
  dtDemoAPIAccessToken: 'dtDemoAPIAccessToken',
  dtConfigAPIAccessToken: 'dtConfigAPIAccessToken',
  fetchingDTCredential: 'fetchingDTCredential',
  fetchingDTAccessToken: 'fetchingDTAccessToken',
  msalInteractionStatus: 'msal.interaction.status',
};

export const storage = new Storage();

export const useStorage = (key: string): string | null => {
  const [item, setItem] = useState<string | null>(storage.getItem(key));

  useEffect(() => {
    const handleStorageChange = () => {
      setItem(storage.getItem(key));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, [key]);

  return item;
};
