import { ICapability, IEditCapabilityPayload, IFeaturesList, IKeyAssetsList } from '../../../models/cbo/CapabilityManagementModel';
import { IStoreAction } from '../../StoreHelper';

export enum CapabilityActionTypes {
  GET_CAPABILITY_DATA_STARTED = 'GET_CAPABILITY_DATA_STARTED',
  GET_CAPABILITY_DATA_SUCCESS = 'GET_CAPABILITY_DATA_SUCCESS',
  GET_CAPABILITY_DATA_ERROR = 'GET_CAPABILITY_DATA_ERROR',

  UPDATE_CAPABILITY_DATA_STARTED = 'UPDATE_CAPABILITY_DATA_STARTED',
  UPDATE_CAPABILITY_DATA_SUCCESS = 'UPDATE_CAPABILITY_DATA_SUCCESS',
  UPDATE_CAPABILITY_DATA_ERROR = 'UPDATE_CAPABILITY_DATA_ERROR',

  GET_KEY_ASSETS_DATA_STARTED = 'GET_KEY_ASSETS_DATA_STARTED',
  GET_KEY_ASSETS_DATA_SUCCESS = 'GET_KEY_ASSETS_DATA_SUCCESS',
  GET_KEY_ASSETS_DATA_ERROR = 'GET_KEY_ASSETS_DATA_ERROR',

  UPDATE_KEY_ASSETS_DATA_STARTED = 'UPDATE_KEY_ASSETS_DATA_STARTED',
  UPDATE_KEY_ASSETS_DATA_SUCCESS = 'UPDATE_KEY_ASSETS_DATA_SUCCESS',
  UPDATE_KEY_ASSETS_DATA_ERROR = 'UPDATE_KEY_ASSETS_DATA_ERROR',

  GET_FEATURES_DATA_STARTED = 'GET_FEATURES_DATA_STARTED',
  GET_FEATURES_DATA_SUCCESS = 'GET_FEATURES_DATA_SUCCESS',
  GET_FEATURES_DATA_ERROR = 'GET_FEATURES_DATA_ERROR',

  ADD_CAPABILITY_DATA_STARTED = 'ADD_CAPABILITY_DATA_STARTED',
  ADD_CAPABILITY_DATA_SUCCESS = 'ADD_CAPABILITY_DATA_SUCCESS',
  ADD_CAPABILITY_DATA_ERROR = 'ADD_CAPABILITY_DATA_ERROR',

  GET_PHASE_DATA_STARTED = 'GET_PHASE_DATA_STARTED',
  GET_PHASE_DATA_SUCCESS = 'GET_PHASE_DATA_SUCCESS',
  GET_PHASE_DATA_ERROR = 'GET_PHASE_DATA_ERROR',

}

export type CapabilityActionPayload =
  | Error
  | null
  | string
  | ICapability[]
  | ICapability
  | IKeyAssetsList
  | IKeyAssetsList[]
  | IFeaturesList[]
  | IEditCapabilityPayload
  | string[];

export type CapabilityManagementAction = IStoreAction<
  CapabilityActionTypes,
  CapabilityActionPayload
>;
export class CapabilityManagementActions {
  public static getCapabilityDataStart(): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_CAPABILITY_DATA_STARTED,
    };
  }
  public static getCapabilityDataSuccess(data: ICapability[]): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_CAPABILITY_DATA_SUCCESS,
      data,
    };
  }
  public static getCapabilityDataError(error: Error): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_CAPABILITY_DATA_ERROR,
      data: error,
    };
  }

  public static updateCapabilityDataStart(data: IEditCapabilityPayload): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.UPDATE_CAPABILITY_DATA_STARTED,
      data,
    };
  }



  public static updateCapabilityDataSuccess(): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.UPDATE_CAPABILITY_DATA_SUCCESS,
    };
  }
  public static updateCapabilityDataError(error: Error): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.UPDATE_CAPABILITY_DATA_ERROR,
      data: error,
    };
  }

  public static getKeyAssetsDataStart(): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_KEY_ASSETS_DATA_STARTED,
    };
  }
  public static getKeyAssetsDataSuccess(data: IKeyAssetsList[]): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_KEY_ASSETS_DATA_SUCCESS,
      data,
    };
  }
  public static getKeyAssetsDataError(error: Error): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_KEY_ASSETS_DATA_ERROR,
      data: error,
    };
  }

  public static updateKeyAssetsDataStart(data: IKeyAssetsList): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.UPDATE_KEY_ASSETS_DATA_STARTED,
      data,
    };
  }
  public static updateKeyAssetsDataSuccess(): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.UPDATE_KEY_ASSETS_DATA_SUCCESS,
    };
  }
  public static updateKeyAssetsDataError(error: Error): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.UPDATE_KEY_ASSETS_DATA_ERROR,
      data: error,
    };
  }

  public static getFeaturesDataStart(): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_FEATURES_DATA_STARTED,
    };
  }
  public static getFeaturesDataSuccess(data: IFeaturesList[]): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_FEATURES_DATA_SUCCESS,
      data,
    };
  }
  public static getFeaturesDataError(error: Error): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_FEATURES_DATA_ERROR,
      data: error,
    };
  }
  public static addCapability(data: IEditCapabilityPayload): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.ADD_CAPABILITY_DATA_STARTED,
      data,
    };
  }
  public static addCapabilityDataSuccess(): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.ADD_CAPABILITY_DATA_SUCCESS,
    };
  }
  public static addCapabilityDataError(error: Error): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.ADD_CAPABILITY_DATA_ERROR,
      data: error,
    };
  }
  public static getPhaseMetadataStart(): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_PHASE_DATA_STARTED,
    };
  }
  public static getPhaseMetadataSuccess(data: string[]): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_PHASE_DATA_SUCCESS,
      data,
    };
  }
  public static getPhaseMetadataError(error: Error): CapabilityManagementAction {
    return {
      type: CapabilityActionTypes.GET_PHASE_DATA_ERROR,
      data: error,
    };
  }
}
