import { IClientResponse, IEngagementResponse, IClientAddPayload , IClientUpdatePayload, IEngagementAddPayload, IEngagementUpdatePayload, IEngagementDeletePayload} from '../../../models/cbo/ClientEngagementManagementModel';
import { IStoreAction } from '../../StoreHelper';
  
export enum ClientEngagementActionTypes {
  GET_CLIENTS_DATA_STARTED = 'GET_CLIENTS_DATA_STARTED',
  GET_CLIENTS_DATA_SUCCESS = 'GET_CLIENTS_DATA_SUCCESS',
  GET_CLIENTS_DATA_ERROR = 'GET_CLIENTS_DATA_ERROR',
  GET_ENGAGEMENTS_DATA_STARTED = 'GET_ENGAGEMENTS_DATA_STARTED',
  GET_ENGAGEMENTS_DATA_SUCCESS = 'GET_ENGAGEMENTS_DATA_SUCCESS',
  GET_ENGAGEMENTS_DATA_ERROR = 'GET_ENGAGEMENTS_DATA_ERROR',
  ADD_CLIENT_DATA_STARTED = 'ADD_CLIENT_DATA_STARTED',
  ADD_CLIENT_DATA_SUCCESS = 'ADD_CLIENT_DATA_SUCCESS',
  ADD_CLIENT_DATA_ERROR = 'ADD_CLIENT_DATA_ERROR',
  UPDATE_CLIENT_DATA_STARTED = 'UPDATE_CLIENT_DATA_STARTED',
  UPDATE_CLIENT_DATA_SUCCESS = 'UPDATE_CLIENT_DATA_SUCCESS',
  UPDATE_CLIENT_DATA_ERROR = 'UPDATE_CLIENT_DATA_ERROR',
  ADD_ENGAGEMENT_DATA_STARTED = 'ADD_ENGAGEMENT_DATA_STARTED',
  ADD_ENGAGEMENT_DATA_SUCCESS = 'ADD_ENGAGEMENT_DATA_SUCCESS',
  ADD_ENGAGEMENT_DATA_ERROR = 'ADD_ENGAGEMENT_DATA_ERROR',
  UPDATE_ENGAGEMENT_DATA_STARTED = 'UPDATE_ENGAGEMENT_DATA_STARTED',
  UPDATE_ENGAGEMENT_DATA_SUCCESS = 'UPDATE_ENGAGEMENT_DATA_SUCCESS',
  UPDATE_ENGAGEMENT_DATA_ERROR = 'UPDATE_ENGAGEMENT_DATA_ERROR',
  DELETE_CLIENT_DATA_STARTED = 'DELETE_CLIENT_DATA_STARTED',
  DELETE_CLIENT_DATA_SUCCESS = 'DELETE_CLIENT_DATA_SUCCESS',
  DELETE_CLIENT_DATA_ERROR = 'DELETE_CLIENT_DATA_ERROR',
  DELETE_ENGAGEMENT_DATA_STARTED = 'DELETE_ENGAGEMENT_DATA_STARTED',
  DELETE_ENGAGEMENT_DATA_SUCCESS = 'DELETE_ENGAGEMENT_DATA_SUCCESS',
  DELETE_ENGAGEMENT_DATA_ERROR = 'DELETE_ENGAGEMENT_DATA_ERROR',
  GET_CLIENTS_NAME_DATA_STARTED='GET_CLIENTS_NAME_DATA_STARTED',
  GET_CLIENTS_NAME_DATA_SUCCESS='GET_CLIENTS_NAME_DATA_SUCCESS',
  GET_CLIENTS_NAME_DATA_ERROR='GET_CLIENTS_NAME_DATA_ERROR'
}

export type ClientEngagementActionPayload =
  | Error
  | null
  | string
  | IClientResponse[]
  | IEngagementResponse[]
  | IClientAddPayload
  | IClientUpdatePayload
  | IEngagementAddPayload
  | IEngagementUpdatePayload
  | IEngagementDeletePayload

export type ClientEngagementManagementAction = IStoreAction<
ClientEngagementActionTypes,
ClientEngagementActionPayload>;
 export class ClientEngagementManagementActions {
    public static getClientsDataStart(): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.GET_CLIENTS_DATA_STARTED
        };
      }
      public static getClientsDataSuccess(data: IClientResponse[]): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.GET_CLIENTS_DATA_SUCCESS,
          data,
        };
      }
      public static getClientsDataError(error: Error): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.GET_CLIENTS_DATA_ERROR,
          data: error,
        };
      }

      //client without logo
      public static getClientNamesDataStart(): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.GET_CLIENTS_NAME_DATA_STARTED,
        };
      }
      public static getClientNamesDataSuccess(data: IClientResponse[]): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.GET_CLIENTS_NAME_DATA_SUCCESS,
          data,
        };
      }
      public static getClientNamesDataError(error: Error): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.GET_CLIENTS_NAME_DATA_ERROR,
          data: error,
        };
      }

      public static getEngagementsDataStart(data: string | undefined): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.GET_ENGAGEMENTS_DATA_STARTED,
          data,
        };
      }
      public static getEngagementsDataSuccess(data: IEngagementResponse[]): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.GET_ENGAGEMENTS_DATA_SUCCESS,
          data,
        };
      }
      public static getEngagementsDataError(error: Error): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.GET_ENGAGEMENTS_DATA_ERROR,
          data: error,
        };
      }

      public static addClientStart(data: IClientAddPayload): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.ADD_CLIENT_DATA_STARTED,
          data,
        };
      }
      public static addClientSuccess(): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.ADD_CLIENT_DATA_SUCCESS
        };
      }
      public static addClientError(error: Error): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.ADD_CLIENT_DATA_ERROR,
          data: error,
        };
      }

      public static updateClientStart(data: IClientUpdatePayload): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.UPDATE_CLIENT_DATA_STARTED,
          data,
        };
      }
      public static updateClientSuccess(): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.UPDATE_CLIENT_DATA_SUCCESS
        };
      }
      public static updateClientError(error: Error): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.UPDATE_CLIENT_DATA_ERROR,
          data: error,
        };
      }

      public static addEngagementStart(data: IEngagementAddPayload): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.ADD_ENGAGEMENT_DATA_STARTED,
          data,
        };
      }
      public static addEngagementSuccess(): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.ADD_ENGAGEMENT_DATA_SUCCESS
        };
      }
      public static addEngagementError(error: Error): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.ADD_ENGAGEMENT_DATA_ERROR,
          data: error,
        };
      }

      public static updateEngagementStart(data: IEngagementUpdatePayload): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.UPDATE_ENGAGEMENT_DATA_STARTED,
          data,
        };
      }
      public static updateEngagementSuccess(): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.UPDATE_ENGAGEMENT_DATA_SUCCESS
        };
      }
      public static updateEngagementError(error: Error): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.UPDATE_ENGAGEMENT_DATA_ERROR,
          data: error,
        };
      }

      public static deleteClientDataStart(data: string): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.DELETE_CLIENT_DATA_STARTED,
          data,
        };
      }
      public static deleteClientDataSuccess(): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.DELETE_CLIENT_DATA_SUCCESS,
        };
      }
      public static deleteClientDataError(data: Error): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.DELETE_CLIENT_DATA_ERROR,
          data
        };
      }
      public static deleteEngagementDataStart(data: IEngagementDeletePayload): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.DELETE_ENGAGEMENT_DATA_STARTED,
          data,
        };
      }
      public static deleteEngagementDataSuccess(): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.DELETE_ENGAGEMENT_DATA_SUCCESS,
        };
      }
      public static deleteEngagementDataError(data: Error): ClientEngagementManagementAction {
        return {
          type: ClientEngagementActionTypes.DELETE_ENGAGEMENT_DATA_ERROR,
          data
        };
      }
 }
