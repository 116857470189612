import { Reducer } from 'redux';
import {
  IEngagementAssetsObject,
  IMarketplaceAssetModal,
} from '../../../models/cbo/CboMarketplaceModels';
import {
  CboMarketplaceAction,
  CboMarketplaceActionTypes,
} from '../../actions/cbo/CboMarketplaceAction';

import CboMarketplaceState from '../../state/cbo/CboMarketplaceState';
import { marketplaceTabKeysConstant } from '../../../../components/CBO/CBOConstants';

const defaultAssetDetails: IMarketplaceAssetModal = {
  asset_id: '',
  assetName: '',
  assetCategory: '',
  assetType: '',
  externalId: '',
  assetLink: '',
  assetIconLink: '',
  certifiedBy: '',
  submittedBy: '',
  journey: '',
  description: '',
  userDefinedTags: [],
  contacts: [''],
  price: 0,
  assetClassif: null,
  assetSubClassif: '',
  currentVersion: {},
  createdBy: 1,
  updatedBy: 1,
  likes: 1,
  views: 1,
  downloads: 1,
  popularityScore: 1,
  state: '',
  isActive: true,
  publishDate: '',
  projectDetails: {
    activities: [''],
    industries: [''],
  },
  deliveryDetails: {
    methodology: [''],
    framework: [''],
    hosting: [''],
    platform: [''],
  },
  marketOfferingTags: [
    {
      category: '',
      offeringPortfolio: '',
      offering: '',
      marketOffering: '',
    },
  ],
  likeFlag: true,
  certificationStatus: '',
  assetImages: [],
  assetFile: '',
  selected: true,
  assetImage: '',
  assetPhase: '',
  optOutComment: '',
  capabilities: [],
};

const defaultEngMandatoryAssetData: IEngagementAssetsObject = {
  engagementId: '',
  engagementName: '',
  journeyList: [],
  assets: [],
};

const defaultEngRecommendedAssetData: IEngagementAssetsObject = {
  engagementId: '',
  engagementName: '',
  journeyList: [],
  assets: [],
};

export const initialState: CboMarketplaceState = {
  selectedJourneyName: [],
  selectedBaseJourneyName: '',
  selectedEngagement: '',
  loading: false,
  mandatoryAssetList: defaultEngMandatoryAssetData,
  recommendedAssetList: defaultEngRecommendedAssetData,
  assetDetails: defaultAssetDetails,
  error: '',
  assetsUpdatedSuccessfullyOnConfigure: false,
  selectedMarketplaceTab: marketplaceTabKeysConstant.keyAssets,
  isSmartStartsClicked: false,
};

export const cboMarketplaceReducer: Reducer<CboMarketplaceState> = (
  state = initialState,
  action: CboMarketplaceAction,
) => {
  switch (action.type) {
    case CboMarketplaceActionTypes.GET_MARKETPLACE_MANDATORY_ASSET_STARTED:
      return {
        ...state,
        loading: true,
        mandatoryAssetList: defaultEngMandatoryAssetData,
      };
    case CboMarketplaceActionTypes.GET_MARKETPLACE_MANDATORY_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        mandatoryAssetList: action.data as IEngagementAssetsObject,
      };
    case CboMarketplaceActionTypes.GET_MARKETPLACE_MANDATORY_ASSET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case CboMarketplaceActionTypes.GET_MARKETPLACE_RECOMMENDED_ASSET_STARTED:
      return {
        ...state,
        loading: true,
        recommendedAssetList: defaultEngRecommendedAssetData,
      };
    case CboMarketplaceActionTypes.GET_MARKETPLACE_RECOMMENDED_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendedAssetList: action.data as IEngagementAssetsObject,
      };
    case CboMarketplaceActionTypes.GET_MARKETPLACE_RECOMMENDED_ASSET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case CboMarketplaceActionTypes.ADD_MARKETPLACE_ASSET_STARTED:
      return {
        ...state,
        loading: true,
        assetsUpdatedSuccessfullyOnConfigure: false,
        error: undefined,
      };
    case CboMarketplaceActionTypes.ADD_MARKETPLACE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        assetsUpdatedSuccessfullyOnConfigure: true,
        error: undefined,
      };
    case CboMarketplaceActionTypes.ADD_MARKETPLACE_ASSET_ERROR:
      return {
        ...state,
        loading: false,
        assetsUpdatedSuccessfullyOnConfigure: false,
        error: action.data,
      };
    case CboMarketplaceActionTypes.SET_SELECTED_JOURNEY_NAME:
      return {
        ...state,
        selectedJourneyName: action.data as string[],
      };
    case CboMarketplaceActionTypes.SET_SELECTED_BASE_JOURNEY_NAME:
      return {
        ...state,
        selectedBaseJourneyName: action.data as string,
      };
    case CboMarketplaceActionTypes.GET_MARKETPLACE_ASSET_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
        assetDetails: defaultAssetDetails,
      };
    case CboMarketplaceActionTypes.GET_MARKETPLACE_ASSET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        assetDetails: action.data as IMarketplaceAssetModal,
      };
    case CboMarketplaceActionTypes.GET_MARKETPLACE_ASSET_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case CboMarketplaceActionTypes.SET_SELECTED_MARKETPLACE_TAB:
      return {
        ...state,
        selectedMarketplaceTab: action.data as string,
      };
    case CboMarketplaceActionTypes.SET_IS_SMARTSTARTS_CLICKED:
      return {
        ...state,
        isSmartStartsClicked: action.data as boolean,
      };
    default:
      return state;
  }
};
