import { Reducer } from 'redux';
import {
  IMarketplaceSystemTags,
  IPostFilterResponse,
  ISearchAndFilterAssetsResponse,
  IFilterPayload,
  IJourneyFilter,
  IImportantLinks,
} from '../../../models/cbo/CboMarketplaceSearchModels';

import {
  CboMarketplaceSearchAction,
  CboMarketplaceSearchActionTypes,
} from '../../actions/cbo/CboMarketplaceSearchActions';
import CboMarketplaceSearchState from '../../state/cbo/CboMarketplaceSearchState';
import { IJourneyList } from '../../../models/cbo/JourneyBuilderModel';

const defaultSearchAndFilter: ISearchAndFilterAssetsResponse = {
  assetData: [
    {
      asset_id: 0,
      assetName: '',
      assetCategory: '',
      description: '',
    },
  ],
  count: 0,
};
const defaultPostFilterResponse: IPostFilterResponse = {
  status: 0,
  successMessage: '',
  filterId: '',
};
const defaultFilterData: IFilterPayload = {
  filterId: undefined,
  category: undefined,
  offering: undefined,
  op: undefined,
  industries: [],
  userId: '',
  createdById: '',
  assetCategory: undefined,
};
const defaultFilterJourneyList: IJourneyFilter[] = [
  {
    journeyId: '',
    journeyName: '',
    archetype: [],
    capabilities: [],
  },
];

export const initialState: CboMarketplaceSearchState = {
  loading: false,
  systemTagsCallCompleted: true,
  assetCategoryCallCompleted: true,
  assetListCallCompleted: true,
  filterSaveCallCompleted: true,
  filterDataCallCompleted: true,
  searchFilterAssetListCallCompleted: true,
  error: '',
  systemTags: {} as IMarketplaceSystemTags,
  searchFilterAssetList: defaultSearchAndFilter,
  postFilterResponse: defaultPostFilterResponse,
  filterData: defaultFilterData,
  assetCategoryList: [],
  journeyListCallCompleted: true,
  journeyList: [] as IJourneyFilter[],
  importantLinks: [] as IImportantLinks[],
};

export const cboMarketplaceSearchReducer: Reducer<CboMarketplaceSearchState> = (
  state = initialState,
  action: CboMarketplaceSearchAction,
) => {
  switch (action.type) {
    // meta data
    case CboMarketplaceSearchActionTypes.GET_ASSET_METADATA_STARTED:
      return {
        ...state,
        assetCategoryCallCompleted: false,
        assetCategoryList: [],
      };
    case CboMarketplaceSearchActionTypes.GET_ASSET_METADATA_SUCCESS:
      return {
        ...state,
        assetCategoryCallCompleted: true,
        assetCategoryList: action.data as string[],
      };
    case CboMarketplaceSearchActionTypes.GET_ASSET_METADATA_ERROR:
      return {
        ...state,
        assetCategoryCallCompleted: true,
        error: action.data,
      };

    // system tags
    case CboMarketplaceSearchActionTypes.GET_SYSTEM_TAGS_STARTED:
      return {
        ...state,
        error: undefined,
        systemTagsCallCompleted: false,
        systemTags: {} as IMarketplaceSystemTags,
      };
    case CboMarketplaceSearchActionTypes.GET_SYSTEM_TAGS_SUCCESS:
      return {
        ...state,
        error: undefined,
        systemTagsCallCompleted: true,
        systemTags: action.data as IMarketplaceSystemTags,
      };
    case CboMarketplaceSearchActionTypes.GET_SYSTEM_TAGS_ERROR:
      return {
        ...state,
        systemTagsCallCompleted: true,
        error: action.data as Error,
      };

    // search and filter
    case CboMarketplaceSearchActionTypes.GET_SEARCH_FILTER_DATA_STARTED:
      return {
        ...state,
        error: undefined,
        searchFilterAssetListCallCompleted: false,
        searchFilterAssetList: defaultSearchAndFilter,
      };
    case CboMarketplaceSearchActionTypes.GET_SEARCH_FILTER_DATA_SUCCESS:
      return {
        ...state,
        error: undefined,
        searchFilterAssetListCallCompleted: true,
        searchFilterAssetList: action.data as ISearchAndFilterAssetsResponse,
      };
    case CboMarketplaceSearchActionTypes.GET_SEARCH_FILTER_DATA_ERROR:
      return {
        ...state,
        searchFilterAssetListCallCompleted: true,
        error: action.data as Error,
      };

    // filter url POST
    case CboMarketplaceSearchActionTypes.POST_FILTER_URL_DATA_STARTED:
      return {
        ...state,
        error: undefined,
        filterSaveCallCompleted: false,
        postFilterUrlData: defaultPostFilterResponse,
      };
    case CboMarketplaceSearchActionTypes.POST_FILTER_URL_DATA_SUCCESS:
      return {
        ...state,
        error: undefined,
        filterSaveCallCompleted: true,
        postFilterResponse: action.data as IPostFilterResponse,
      };
    case CboMarketplaceSearchActionTypes.POST_FILTER_URL_DATA_ERROR:
      return {
        ...state,
        filterSaveCallCompleted: true,
        error: action.data as Error,
      };

    // filter url GET
    case CboMarketplaceSearchActionTypes.GET_URL_DATA_STARTED:
      return {
        ...state,
        error: undefined,
        filterDataCallCompleted: false,
        filterData: defaultFilterData,
      };
    case CboMarketplaceSearchActionTypes.GET_URL_DATA_SUCCESS:
      return {
        ...state,
        error: undefined,
        filterDataCallCompleted: true,
        filterData: action.data as IFilterPayload,
      };
    case CboMarketplaceSearchActionTypes.GET_URL_DATA_ERROR:
      return {
        ...state,
        filterDataCallCompleted: true,
        error: action.data as Error,
      };

    //  filter journeyList
    case CboMarketplaceSearchActionTypes.GET_FILTER_JOURNEY_LIST_STARTED:
      return {
        ...state,
        error: undefined,
        journeyListCallCompleted: false,
        journeyList: [] as IJourneyFilter[],
      };
    case CboMarketplaceSearchActionTypes.GET_FILTER_JOURNEY_LIST_SUCCESS:
      return {
        ...state,
        error: undefined,
        journeyListCallCompleted: true,
        journeyList: action.data as IJourneyFilter[],
      };
    case CboMarketplaceSearchActionTypes.GET_FILTER_JOURNEY_LIST_ERROR:
      return {
        ...state,
        journeyListCallCompleted: true,
        error: action.data as Error,
      };

    //IMPORTANT LINKS
    case CboMarketplaceSearchActionTypes.GET_IMPORTANT_LINKS_DATA_STARTED:
      return {
        ...state,
        error: undefined,
        importantLinks: [] as IImportantLinks[],
      };
    case CboMarketplaceSearchActionTypes.GET_IMPORTANT_LINKS_DATA_SUCCESS:
      return {
        ...state,
        error: undefined,
        importantLinks: action.data as IImportantLinks[],
      };
    case CboMarketplaceSearchActionTypes.GET_IMPORTANT_LINKS_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };

    // reset filter
    case CboMarketplaceSearchActionTypes.RESET_FILTER_DATA:
      return {
        ...state,
        error: undefined,
        filterData: defaultFilterData,
      };
    default:
      return state;
  }
};
