import { Reducer } from 'redux';
import {
  UserManagementAction,
  UserManagementActionTypes,
} from '../../actions/cbo/UserManagementAction';
import UserManagementState from '../../state/cbo/UserManagementState';
import {
  ICboUserManagementObject,
  IClientListMapping,
} from '../../../models/cbo/UserManagementModel';
import { IEngagementResponse } from '../../../models/cbo/ClientEngagementManagementModel';

export const initialState: UserManagementState = {
  userList: {} as ICboUserManagementObject,
  clientEngagaementMapping: [],
  RoleMetaData: [],
  loading: false,
  error: '',
  clientUserData: [],
  updateClientVisibilityLoading: false,
};

export const CboUserManagementReducer: Reducer<UserManagementState> = (
  state = initialState,
  action: UserManagementAction,
) => {
  switch (action.type) {
    case UserManagementActionTypes.GET_USER_LIST_STARTED:
      return {
        ...state,
        loading: true,
        clientList: {},
      };
    case UserManagementActionTypes.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.data as ICboUserManagementObject,
      };
    case UserManagementActionTypes.GET_USER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case UserManagementActionTypes.GET_CLIENT_ENG_LIST_STARTED:
      return {
        ...state,
        loading: true,
        clientList: [],
      };
    case UserManagementActionTypes.GET_CLIENT_ENG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clientEngagaementMapping: action.data as IClientListMapping[],
      };
    case UserManagementActionTypes.GET_CLIENT_ENG_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case UserManagementActionTypes.ADD_USER_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UserManagementActionTypes.ADD_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case UserManagementActionTypes.ADD_USER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case UserManagementActionTypes.EDIT_USER_STATUS_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UserManagementActionTypes.EDIT_USER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case UserManagementActionTypes.EDIT_USER_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case UserManagementActionTypes.UPDATE_USER_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UserManagementActionTypes.UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case UserManagementActionTypes.UPDATE_USER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case UserManagementActionTypes.GET_ROLE_METADATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UserManagementActionTypes.GET_ROLE_METADATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        RoleMetaData: action.data as string[],
      };
    case UserManagementActionTypes.GET_ROLE_METADATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case UserManagementActionTypes.GET_CLIENT_USER_STARTED:
      return {
        ...state,
        loading: true,
      };
    case UserManagementActionTypes.GET_CLIENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        clientUserData: action.data as IEngagementResponse[],
      };
    case UserManagementActionTypes.GET_CLIENT_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    // @CBO-2834_AI_AUTO_NEW_CODE:generated case for show user's client start, success, error action Types
    case UserManagementActionTypes.SHOW_USER_CLIENT_STARTED:
      return {
        ...state,
        updateClientVisibilityLoading: true,
      };
    case UserManagementActionTypes.SHOW_USER_CLIENT_SUCCESS:
      return {
        ...state,
        updateClientVisibilityLoading: false,
      };
    case UserManagementActionTypes.SHOW_USER_CLIENT_ERROR:
      return {
        ...state,
        updateClientVisibilityLoading: false,
        error: action.data,
      };
    default:
      return state;
  }
};
