import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import {
  IMarketplaceSystemTags, IFilterPayload, IPostFilterResponse, ISearchAndFilterAssetsPayload, ISearchAndFilterAssetsResponse, IJourneyFilter, IImportantLinks,
} from '../../../models/cbo/CboMarketplaceSearchModels';
import marketplaceSearchService from '../../../services/cbo/CboMarketplaceSearchService/CboMarketplaceSearchService';
import {
  CboMarketplaceSearchAction,
  CboMarketplaceSearchActions,
  CboMarketplaceSearchActionTypes,
} from '../../actions/cbo/CboMarketplaceSearchActions';
import { ToastActions } from '../../actions/ToastActions';
import { IJourneyList } from '../../../models/cbo/JourneyBuilderModel';

// meta data
export function* getAssetMetaDataWorker(action: CboMarketplaceSearchAction) {
  try {
    const response: string[] = yield marketplaceSearchService.getAssetMetaData();
    yield put(CboMarketplaceSearchActions.getAssetsMetaDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CboMarketplaceSearchActions.getAssetsMetaDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

// system tags
export function* getSystemTagsDataWorker() {
  try {
    const response: IMarketplaceSystemTags = yield marketplaceSearchService.getSystemTagsData();
    yield put(CboMarketplaceSearchActions.getSystemTagsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CboMarketplaceSearchActions.getSystemTagsDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

// search and filter url
export function* getSearchFilterUrlDataWorker(action: CboMarketplaceSearchAction) {
    try {
      const { data } = action;
      const response:ISearchAndFilterAssetsResponse = yield marketplaceSearchService.postSearchFilterData(data as ISearchAndFilterAssetsPayload);
      yield put(CboMarketplaceSearchActions.getSearchFilterDataSuccess(response));
    } catch (error) {
      if (error instanceof Error) {
        yield put(CboMarketplaceSearchActions.getSearchFilterDataError(error));
        yield put(
          ToastActions.showToast({
            message: 'Error!!!',
            description: error.message,
            type: NotificationTypes.ERROR,
          }),
        );
      }
    }
  }

//   filter url - POST
export function* postFilterUrlDataWorker(action: CboMarketplaceSearchAction) {
    try {
      const { data } = action;
      const response: IPostFilterResponse = yield marketplaceSearchService.postFilterUrlData(data as IFilterPayload);
      yield put(CboMarketplaceSearchActions.postFilterUrlDataSuccess(response));
      if(response.filterId)
      yield put(
        ToastActions.showToast({
          description: 'Asset search link copied successfully',
          type: NotificationTypes.SUCCESS,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        yield put(CboMarketplaceSearchActions.postFilterUrlDataError(error));
        yield put(
          ToastActions.showToast({
            message: 'Error!!!',
            description: error.message,
            type: NotificationTypes.ERROR,
          }),
        );
      }
    }
  }

//   filter url - GET
export function* getFilterDataWorker(action: CboMarketplaceSearchAction){
    try{
      const data = action.data as string;
        const response: IFilterPayload = yield marketplaceSearchService.getFilterData(data);
        yield put(CboMarketplaceSearchActions.getFilterDataSuccess(response));
    }
    catch (error) {
        if (error instanceof Error) {
          yield put(CboMarketplaceSearchActions.getFilterDataError(error));
          yield put(
            ToastActions.showToast({
              message: 'Error!!!',
              description: error.message,
              type: NotificationTypes.ERROR,
            }),
          );
        }
      }
}

//  journeyList filter
export function* getFilterJourneyListDataWorker() {
  try {
    const response:IJourneyFilter[] = yield marketplaceSearchService.getFilterJourneyList();
    yield put(CboMarketplaceSearchActions.getFilterJourneyListSuccess(response));
  }
   catch (error) {
    if (error instanceof Error) {
      yield put(CboMarketplaceSearchActions.getFilterJourneyListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

//important links
export function* getImportantLinksDataWorker(action: CboMarketplaceSearchAction) {
  try {
    const data = action.data as string;
    const response:IImportantLinks[] = yield marketplaceSearchService.getImportantLinks(data);
    yield put(CboMarketplaceSearchActions.getImportantLinksSuccess(response));
  }
   catch (error) {
    if (error instanceof Error) {
      yield put(CboMarketplaceSearchActions.getImportantLinksError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}


export function* marketplaceSearchWatcher() {
  yield takeEvery(
    CboMarketplaceSearchActionTypes.GET_ASSET_METADATA_STARTED,
    getAssetMetaDataWorker,
  );
  yield takeEvery(
    CboMarketplaceSearchActionTypes.GET_SYSTEM_TAGS_STARTED,
    getSystemTagsDataWorker,
  );
  yield takeEvery(
    CboMarketplaceSearchActionTypes.GET_SEARCH_FILTER_DATA_STARTED,
    getSearchFilterUrlDataWorker,
  );
  yield takeEvery(
    CboMarketplaceSearchActionTypes.POST_FILTER_URL_DATA_STARTED,
    postFilterUrlDataWorker,
  );
  yield takeEvery(
    CboMarketplaceSearchActionTypes.GET_URL_DATA_STARTED,
    getFilterDataWorker,
  );
  yield takeEvery(
    CboMarketplaceSearchActionTypes.GET_FILTER_JOURNEY_LIST_STARTED,
    getFilterJourneyListDataWorker,
  );
  yield takeEvery(
    CboMarketplaceSearchActionTypes.GET_IMPORTANT_LINKS_DATA_STARTED,
    getImportantLinksDataWorker,
  );
}
