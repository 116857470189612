import React, { useState, useEffect } from 'react';
import Loader from '../../../components/Loader/Loader';
import { getTurboCodeMSALConfig } from '../../CBOLogin/CBOLoginConstants';
import { useDispatch } from 'react-redux';
import {
  IVisitedPagePayload,
  SectionType,
  TrafficAnalyticsActions,
} from '../../../libs/store/actions/WebAnalytics/TrafficAnalyticsActions';
import { IProduct } from '../../../libs/models/cbo/EngagementModels';
import { turboCodeDemoInstanceConfig } from '../KeyAssetsConstant';
import { STORAGE_CONSTANTS, storage } from '../../../libs/services/LocalStorage';
import { routes } from '../../../navigation/Routes';
import { IApplicationState, useAppSelector } from '../../../libs/store/reducers';
import * as msal from '@azure/msal-browser';
import { CBOKeyAssets } from '../../CBOSidebar/CBOSidebarConstants';
import { removeCookie } from '../../../libs/helper/HelperFunctions';

interface IAssetProps {
  selectedAssetConfig?: IProduct;
  isEngagement?: boolean;
  selectedEnagagementName?: string;
  selectedEnagagementId?: string;
}

const TurboCodeDashboard = ({
  isEngagement,
  selectedAssetConfig,
  selectedEnagagementName,
  selectedEnagagementId,
}: IAssetProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState<boolean>();

  const dispatcher = {
    postPageVisited: (data: IVisitedPagePayload) =>
      dispatch(TrafficAnalyticsActions.postVisitedPageStart(data)),
  };
  const { selectedPhase, KeyAssetList } = useAppSelector((state: IApplicationState) => ({
    selectedPhase: state.cboViewSummary.selectedPhase,
    KeyAssetList: state.engagement.KeyAssetList,
  }));

  const authenticateTurbocode = async () => {
    const msalInstance = new msal.PublicClientApplication(getTurboCodeMSALConfig());
    storage?.removeSessionItem(STORAGE_CONSTANTS.msalInteractionStatus);
    removeCookie(STORAGE_CONSTANTS.msalInteractionStatus);
    await msalInstance.initialize();
    msalInstance.loginRedirect();
  };

  useEffect(() => {
    dispatcher.postPageVisited({
      sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
      assetName: SectionType.TURBOCODE,
      name: selectedEnagagementName,
      id: selectedEnagagementId,
    });
    const turbocodeAsset = KeyAssetList?.find(
      (keyAsset) => keyAsset.product == CBOKeyAssets.turboCode,
    );
    const turboCodeDemoAuthenticated: any = turbocodeAsset?.configDone
      ? storage.getItemSession(STORAGE_CONSTANTS.turboCodeConfigAuthenticated)
      : storage.getItemSession(STORAGE_CONSTANTS.turboCodeDemoAuthenticated);
    if (turboCodeDemoAuthenticated == 'true') {
      console.log('trurboCode Dashboard- trurboCode authenticated');
      setAuthenticated(true);
      turbocodeAsset?.configDone &&
        storage.setItemSession(STORAGE_CONSTANTS.turboCodeConfigAuthenticated, 'false');
    } else {
      console.log('trurboCode not authenticated');
      setAuthenticated(false);
      const path =
        window.location?.pathname == routes.cboEngagementSummary
          ? `${window.location?.pathname}#${selectedPhase}`
          : window.location?.pathname;
      storage.setItem(STORAGE_CONSTANTS.authOriginatorUrl, path);
      authenticateTurbocode();
    }

    return () => {
      dispatcher.postPageVisited({
        sectionType: isEngagement ? SectionType.ENG_ASSET : SectionType.PURSUIT_ASSET,
        assetName: SectionType.TURBOCODE,
        name: selectedEnagagementName,
        id: selectedEnagagementId,
      });
    };
  }, []);

  // useEffect(() => {
  //   url && handleZoom('assess-iframe');
  // }, [url]);

  return (
    <div className="trunorth-dashboard-wrapper">
      {authenticated && (
        <Loader loaderName="Authorizing..." loading={isLoading}>
          <iframe
            id="assess"
            className="assess-iframe"
            title="Assess"
            src={
              isEngagement
                ? selectedAssetConfig?.instanceUrl
                : selectedAssetConfig?.configDone
                  ? selectedAssetConfig?.instanceUrl
                  : turboCodeDemoInstanceConfig.demoInstanceUrl
            }
            onLoad={() => setIsLoading(false)}
          />
        </Loader>
      )}
    </div>
  );
};

export default TurboCodeDashboard;
