import { Reducer } from 'redux';
import {RBACAction, RBACActionTypes} from '../../actions/cbo/RBACActions';
import IRBACState from '../../state/cbo/RBACState';
import RBACState from "../../state/cbo/RBACState";


export const initialState: IRBACState = {
    error: '',
    loading: false,
    RBACData:[]
}
export const RBACReducer: Reducer<RBACState> = (
    state = initialState,
    action: RBACAction,
  ) => {
    switch (action.type) {
        case RBACActionTypes.GET_RBAC_DATA_STARTED:
            return {
              ...state,
              loading: true,
              error: undefined,
            };
          case RBACActionTypes.GET_RBAC_DATA_SUCCESS:
            return {
              ...state,
              loading: false,
              error: undefined,
              RBACData: action.data as string[],
            };
          case RBACActionTypes.GET_RBAC_DATA_ERROR:
            return {
              ...state,
              loading: false,
              error: action.data
            };
        
          default:
            return state;
        }
        }
        
