import { ILandingZoneResponse } from '../../models/BuildDeployModels';
import { ICheckMicroServicesPatternStatus } from '../../models/DashboardModels';
import {
  IArchitectureCatalogueResponse,
  IDeploymentsResponse,
  ILandingZoneState,
  IFormInterface,
} from '../state/BuildDeployState';
import { IStoreAction } from '../StoreHelper';

export enum BuildDeployActionTypes {
  GET_ARCHITECTURE_LIST_STARTED = 'GET_ARCHITECTURE_LIST_STARTED',
  GET_ARCHITECTURE_LIST_SUCCESS = 'GET_ARCHITECTURE_LIST_SUCCESS',
  GET_ARCHITECTURE_LIST_ERROR = 'GET_ARCHITECTURE_LIST_ERROR',
  GET_DEPLOYMENT_DETAILS_STARTED = 'GET_DEPLOYMENT_DETAILS_STARTED',
  GET_DEPLOYMENT_DETAILS_SUCCESS = 'GET_DEPLOYMENT_DETAILS_SUCCESS',
  GET_DEPLOYMENT_DETAILS_ERROR = 'GET_DEPLOYMENT_DETAILS_ERROR',
  GET_BUILD_DEPLOY_DETAILS_STARTED = 'GET_BUILD_DEPLOY_DETAILS_STARTED',
  GET_BUILD_DEPLOY_DETAILS_SUCCESS = 'GET_BUILD_DEPLOY_DETAILS_SUCCESS',
  GET_BUILD_DEPLOY_DETAILS_ERROR = 'GET_BUILD_DEPLOY_DETAILS_ERROR',
  CHECK_MICROSERVIVES_PATTERN_STATUS = 'CHECK_MICROSERVIVES_PATTERN_STATUS',

  GET_LANDING_ZONE_LIST_SUCCESS = 'GET_LANDING_ZONE_LIST_SUCCESS',
  RESET_BUILD_DEPLOY_STATE = 'RESET_BUILD_DEPLOY_STATE',

  GET_MULTICLOUD_YAML_CONTENT_STARTED = 'GET_MULTICLOUD_YAML_CONTENT_STARTED',
  GET_MULTICLOUD_YAML_CONTENT_SUCCESS = 'GET_MULTICLOUD_YAML_CONTENT_SUCCESS',
  GET_MULTICLOUD_YAML_CONTENT_ERROR = 'GET_MULTICLOUD_YAML_CONTENT_ERROR',
}

export interface IConfigureLandingZoneError {
  error: Error;
  index: number;
}
export interface ILandingZoneErrorResponse {
  [error: string]: string[];
}

export type BuildDeployActionPayload =
  | boolean
  | ILandingZoneState[]
  | IArchitectureCatalogueResponse[]
  | IDeploymentsResponse[]
  | ILandingZoneResponse
  | ILandingZoneResponse[]
  | ICheckMicroServicesPatternStatus
  | Error
  | number
  | string
  | IFormInterface;

export type BuildDeployAction = IStoreAction<BuildDeployActionTypes, BuildDeployActionPayload>;
export class BuildDeployActions {
  public static getArchitectureListStarted(data?: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_ARCHITECTURE_LIST_STARTED,
      data,
    };
  }
  public static getArchitectureListSuccess(
    data: IArchitectureCatalogueResponse[],
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_ARCHITECTURE_LIST_SUCCESS,
      data,
    };
  }
  public static getArchitectureListError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_ARCHITECTURE_LIST_ERROR,
      data,
    };
  }

  public static getBuildDeployDetailStarted(data?: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_STARTED,
      data,
    };
  }
  public static getBuildDeployDetailsSuccess(data: ILandingZoneState[]): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_SUCCESS,
      data,
    };
  }
  public static getBuildDeployDetailsError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_ERROR,
      data,
    };
  }

  public static getDeploymentDetailStarted(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_STARTED,
      data,
    };
  }
  public static getDeploymentDetailsSuccess(data: IDeploymentsResponse[]): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_SUCCESS,
      data,
    };
  }
  public static getDeploymentDetailsError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_ERROR,
      data,
    };
  }

  public static resetBuildDeployStateData(): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.RESET_BUILD_DEPLOY_STATE,
    };
  }
  public static getLandingZoneListSuccess(data: ILandingZoneResponse[]): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_LANDING_ZONE_LIST_SUCCESS,
      data,
    };
  }

  public static checkMicroServicesPatternStatus(
    data: ICheckMicroServicesPatternStatus,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CHECK_MICROSERVIVES_PATTERN_STATUS,
      data,
    };
  }
  //Dont Dlete

  public static getMultiCloudYamlContentStarted(data: IFormInterface): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_STARTED,
      data,
    };
  }
  public static getMultiCloudYamlContentSuccess(data: any): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_SUCCESS,
      data,
    };
  }

  public static getMultiCloudYamlContentError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_ERROR,
      data,
    };
  }
}
