import { IStoreAction } from '../StoreHelper';

export enum SidebarActionTypes {
  SET_SIDEBAR_KEY = 'SET_SIDEBAR_KEY',
  TOGGLE_LOGOUT = 'TOGGLE_LOGOUT',
}

export type SidebarActionPayload = boolean | string;

export type SidebarAction = IStoreAction<SidebarActionTypes, SidebarActionPayload>;
export class SidebarActions {
 public static setSidebarKey(data: string): SidebarAction {
    return {
      type: SidebarActionTypes.SET_SIDEBAR_KEY,
      data,
    };
  }

  public static toggleLogout(data: boolean): SidebarAction {
    return {
      type: SidebarActionTypes.TOGGLE_LOGOUT,
      data,
    };
  }
}
