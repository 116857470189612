import { Modal } from 'antd';
import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { isAuthorized } from '../../libs/helper/HelperFunctions';
import { routes } from '../../navigation/Routes';
import UnauthorizedModal from '../Auth/UnauthorizedModal/UnauthorizedModal';
import { useCustomAppSelector } from '../Auth/useCustomeAppSelector';

interface IProtectedRouteProps {
  children: React.ReactNode;
  action: string;
}

const ProtectedRoute = ({ children, action }: IProtectedRouteProps) => {
  const rbacData = useCustomAppSelector();
  return (
    <>
      {rbacData?.length > 0 && (
        <Route
          render={(props) =>
            isAuthorized(action, rbacData) ? children : <Redirect to={routes.accessDenied} />
          }
        />
      )}
    </>
  );
};

export default ProtectedRoute;
