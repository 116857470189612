import {
  ICboLoginResponse,
  IChangePasswordPayload,
  ICustomLoginPayload,
  ICustomLoginResponse,
  ILoginPayload,
  ILoginResponse,
  IRegisterUserPayload,
  ITangraLoginPayload,
  ITangraLoginResponse,
} from '../../models/LoginModels';
import { IStoreAction } from '../StoreHelper';

export enum LoginActionTypes {
  TANGRA_LOGIN_REQUEST = 'TANGRA_LOGIN_REQUEST',
  TANGRA_LOGIN_SUCCESS = 'TANGRA_LOGIN_SUCCESS',
  TANGRA_LOGIN_ERROR = 'TANGRA_LOGIN_ERROR',
  SESSION_LOGIN_TOKEN_START = 'SESSION_LOGIN_TOKEN_START',
  SESSION_LOGIN_TOKEN_SUCCESS = 'SESSION_LOGIN_TOKEN_SUCCESS',
  SESSION_LOGIN_TOKEN_ERROR = 'SESSION_LOGIN_TOKEN_ERROR',
  POST_SESSION_LOGIN_TOKEN_START = 'POST_SESSION_LOGIN_TOKEN_START',
  POST_SESSION_LOGIN_TOKEN_SUCCESS = 'POST_SESSION_LOGIN_TOKEN_SUCCESS',
  POST_SESSION_LOGIN_TOKEN_ERROR = 'POST_SESSION_LOGIN_TOKEN_ERROR',
  CUSTOM_LOGIN_START = 'CUSTOM_LOGIN_START',
  CUSTOM_LOGIN_SUCCESS = 'CUSTOM_LOGIN_SUCCESS',
  CUSTOM_LOGIN_ERROR = 'CUSTOM_LOGIN_ERROR',
  CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR',
}

export type LoginActionPayload =
  | ICustomLoginPayload
  | IChangePasswordPayload
  | ICustomLoginResponse
  | ILoginPayload
  | IRegisterUserPayload
  | ILoginResponse
  | ITangraLoginPayload
  | ITangraLoginResponse
  | ICboLoginResponse
  | Error
  | null;

export type LoginAction = IStoreAction<LoginActionTypes, LoginActionPayload>;
export class LoginActions {

  public static postTangraLoginStart(data: ITangraLoginPayload): LoginAction {
    return {
      type: LoginActionTypes.TANGRA_LOGIN_REQUEST,
      data,
    };
  }
  public static postTangraLoginSuccess(data: ICboLoginResponse): LoginAction {
    return {
      type: LoginActionTypes.TANGRA_LOGIN_SUCCESS,
      data,
    };
  }
  public static postTangraLoginError(error: any): LoginAction {
    return {
      type: LoginActionTypes.TANGRA_LOGIN_ERROR,
      data: error,
    };
  }

  public static sessionTokenStart(): LoginAction {
    return {
      type: LoginActionTypes.SESSION_LOGIN_TOKEN_START,
    };
  }
  public static sessionTokenSuccess(): LoginAction {
    return {
      type: LoginActionTypes.SESSION_LOGIN_TOKEN_SUCCESS,
    };
  }
  public static sessionTokenError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.SESSION_LOGIN_TOKEN_ERROR,
      data: error,
    };
  }

  public static sessionTokenPostStart(): LoginAction {
    return {
      type: LoginActionTypes.POST_SESSION_LOGIN_TOKEN_START,
    };
  }
  public static sessionTokenPostSuccess(): LoginAction {
    return {
      type: LoginActionTypes.POST_SESSION_LOGIN_TOKEN_SUCCESS,
    };
  }
  public static sessionTokenPostError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.POST_SESSION_LOGIN_TOKEN_ERROR,
      data: error,
    };
  }

   //Start: Custom Login
   public static postCustomLoginStart(data: ICustomLoginPayload): LoginAction {
    return {
      type: LoginActionTypes.CUSTOM_LOGIN_START,
      data,
    };
  }
  public static postCustomLoginSuccess(data: ICustomLoginResponse): LoginAction {
    return {
      type: LoginActionTypes.CUSTOM_LOGIN_SUCCESS,
      data,
    };
  }
  public static postCustomLoginError(error: any): LoginAction {
    return {
      type: LoginActionTypes.CUSTOM_LOGIN_ERROR,
      data: error,
    };
  }

  public static postChangePasswordStart(data: IChangePasswordPayload): LoginAction {
    return {
      type: LoginActionTypes.CHANGE_PASSWORD_START,
      data,
    };
  }
  public static postChangePasswordSuccess(data: ICustomLoginResponse): LoginAction {
    return {
      type: LoginActionTypes.CHANGE_PASSWORD_SUCCESS,
      data,
    };
  }
  public static postChangePasswordError(error: any): LoginAction {
    return {
      type: LoginActionTypes.CHANGE_PASSWORD_ERROR,
      data: error,
    };
  }
  //End: Custom Login
}
