/* eslint-disable max-lines */
import { put, select, takeEvery } from 'redux-saga/effects';
import BuildDeployService from '../../../services/BuildDeployService/BuildDeployService';
import engagementService from '../../../services/cbo/EnagementService/EngagementService';
import {
  BuildDeployAction,
  BuildDeployActions,
} from '../../actions/BuildDeployActions';
import {
  EngagementSummaryAction,
  EngagementSummaryActions,
  EngagementSummaryActionTypes,
} from '../../actions/cbo/EngagementSummaryAction';
import {
  IConfigurePayload,
  IEngagementConfigurationResponse,
  IEngagementListForAdminRequestPayload,
  IEngagementListForAdminResponse,
  IEngagementResponse,
} from '../../../models/cbo/EngagementModels';
import {
  IFormInterface,
} from '../..//state/BuildDeployState';
import { ToastActions } from "../../actions/ToastActions";
import { NotificationTypes } from "../../../../components/Notifications/NotificationsConstants";
import { storage, STORAGE_CONSTANTS } from '../../../services/LocalStorage';

export function* getEngagementListWorker(action: EngagementSummaryAction) {
  try {
    const engagementList: IEngagementResponse = yield engagementService.getEngagementList(action.data as boolean);
    yield put(EngagementSummaryActions.getEngagemenSuccess(engagementList, action.data as boolean ?? false));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryActions.getEngagementError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getEngagementListForAdminWorker(action: EngagementSummaryAction) {
  try {
    const engagementListForAdmin: IEngagementListForAdminResponse = yield engagementService.getEngagementListForAdmin(action.data as IEngagementListForAdminRequestPayload);
    yield put(EngagementSummaryActions.getEngagementListForAdminSuccess(engagementListForAdmin, (action.data as IEngagementListForAdminRequestPayload)?.isEngagement ?? false));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryActions.getEngagementListForAdminError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* deleteEngagementDataWorker(action: EngagementSummaryAction) {
  try {
    const userGroup = storage.getItem(STORAGE_CONSTANTS.userGroup)
  ? storage.getItem(STORAGE_CONSTANTS.userGroup)?.split(',') ?? ['']
  : [''];
    const data = action.data as IConfigurePayload;
    yield engagementService.deleteEngagement(data.engagementId);
    yield put(EngagementSummaryActions.deleteEngagementConfigDataSuccess());
    yield put(
      ToastActions.showToast({
        description: 'Pursuit deleted successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    if(!(userGroup.indexOf('ADMIN') > -1)){
      try {
      const engagementList: IEngagementResponse = yield engagementService.getEngagementList(false);
      yield put(EngagementSummaryActions.getEngagemenSuccess(engagementList, data.isEngagement ?? false ));
    } catch (error) {
      if (error instanceof Error) {
        yield put(EngagementSummaryActions.getEngagementError(error));
        yield put(
          ToastActions.showToast({
            message: 'Error!!!',
            description: error.message,
            type: NotificationTypes.ERROR,
          }),
        );
      }
    }
  }
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryActions.deleteEngagementConfigDataError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getMultiCloudYamlContentWorker(action: BuildDeployAction) {
  try {
    const response: Record<string, unknown> = yield BuildDeployService.getMultiCloudYamlContent(
      action.data as IFormInterface,
    );
    yield put(BuildDeployActions.getMultiCloudYamlContentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getMultiCloudYamlContentError(error));
      yield put(
        ToastActions.showToast({
          message: "Error!!!",
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* engagementSummaryWatcher() {
  yield takeEvery(
    EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_STARTED,
    getEngagementListWorker,
  );
  yield takeEvery(
    EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_STARTED,
    getEngagementListForAdminWorker,
  );
  yield takeEvery(
    EngagementSummaryActionTypes.DELETE_ENGAGEMENTCONFIG_DATA_STARTED,
    deleteEngagementDataWorker,
  );
}
