import {
  IActiveUsersPayload,
  IAnalyticsPieChartResponse,
} from '../../store/actions/WebAnalytics/HitsAnalyticsActions';
import { API_ROUTE_CBO } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.ANALYTICS;

export class HitsAnalyticsService {
  private readonly CboService: ApiServiceBase = new ApiService(serviceType, ServerType.cbo);
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);

  public getUserVsReturningUsers():
    | Promise<IAnalyticsPieChartResponse[]>
    | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE_CBO.NEW_VS_RETURNING_USERS}`];
    return this.CboService.get({
      route: route,
    });
  }

  /**ActiveUserson CBO */
  public getActiveUsersData(
    payload: IActiveUsersPayload,
  ): Promise<IAnalyticsPieChartResponse[]> | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_ACTIVE_USERS_DATA}`];
    return this.cboService.get({
      route: route,
      query: { sortBy: payload.sortBy },
    });
  }
  /**End of ActiveUserson CBO */

  public getUserVisitsData(): Promise<IAnalyticsPieChartResponse[]> | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_USERS_VISITS_DATA}`];
    return this.CboService.get({
      route: route,
    });
  }

  public getPageViewsData(): Promise<IAnalyticsPieChartResponse[]> | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_PAGE_VIEWS_DATA}`];
    return this.CboService.get({
      route: route,
    });
  }

  public getBounceRateData(): Promise<IAnalyticsPieChartResponse[]> | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE_CBO.BOUNCE_RATE_DATA}`];
    return this.CboService.get({
      route: route,
    });
  }
}

const hitsAnalyticsService = new HitsAnalyticsService();
export default hitsAnalyticsService;
