import { API_ROUTE_CBO } from '../../ApiRoute';
import ApiService from '../../ApiService';
import ApiServiceBase from '../../ApiServiceBase';
import { ServerType, ServiceType } from '../../ServiceType.data';
import {
  ICboUserManagementObject,
  ICreateUserRequestBody,
  IClientListMapping,
  IEditUserRequestBody,
  IShowUserClientPayload,
} from '../../../models/cbo/UserManagementModel';
import { IEngagementResponse } from '../../../models/cbo/ClientEngagementManagementModel';
import { IEngagementListForAdminRequestPayload } from '../../../models/cbo/EngagementModels';
import { STORAGE_CONSTANTS, storage } from '../../LocalStorage';

export class UserManagementService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);
  //private readonly localStak: ApiServiceBase = new ApiService(ServiceType.None, ServerType.localStack);

  public getUserList(
    requestPayload: IEngagementListForAdminRequestPayload,
  ): Promise<ICboUserManagementObject> | ICboUserManagementObject {
    if (requestPayload?.text?.trim().length !== 0)
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_USER_LIST}`],
        query: {
          limit: requestPayload.limit,
          page: requestPayload.page,
          text: requestPayload?.text,
        },
      });
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_USER_LIST}`],
      query: {
        limit: requestPayload.limit,
        page: requestPayload.page,
      },
    });
  }

  public getClientEngList(): Promise<IClientListMapping[]> | IClientListMapping[] {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_CLIENT_ENG_MAPPINGS}`],
    });
  }

  public addUser(
    data: ICreateUserRequestBody,
  ): Promise<ICreateUserRequestBody> | ICreateUserRequestBody {
    return this.cboService.post(
      {
        route: [`${API_ROUTE_CBO.GET_USER_LIST}`],
      },
      data,
    );
  }

  public editUserStatus(
    data: ICboUserManagementObject,
  ): Promise<ICboUserManagementObject> | ICboUserManagementObject {
    return this.cboService.patch(
      {
        route: [`${API_ROUTE_CBO.GET_USER_LIST}`],
      },
      data,
    );
  }

  public editUser(
    data: IEditUserRequestBody,
  ): Promise<IEditUserRequestBody> | IEditUserRequestBody {
    return this.cboService.patch(
      {
        route: [`${API_ROUTE_CBO.GET_USER_LIST}`],
      },
      data,
    );
  }

  public getRolesMetadata(): Promise<string[]> | string[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_ROLE_METADATA}`];
    return this.cboService.get({ route: route });
  }

  public getClientUserMapping(
    data: string,
  ): Promise<IEngagementResponse[]> | IEngagementResponse[] {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_CLIENT_USER_MAPPING.replace(`:userId`, data)}`],
    });
  }
  // @CBO-2834_AI_AUTO_NEW_CODE:generated service function for show user's client
  public showUserClientService(payload: IShowUserClientPayload): Promise<string> | string {
    const userId = storage.getItem(STORAGE_CONSTANTS.userId);
    return this.cboService.patch(
      {
        route: [`${API_ROUTE_CBO.SHOW_USER_CLIENT.replace(`:userId`, userId!)}`],
      },
      payload,
    );
  }
}

const userManagementService = new UserManagementService();
export default userManagementService;
