import { Reducer } from 'redux';
import { ICboLoginResponse, ITangraLoginResponse, ICustomLoginResponse } from '../../models/LoginModels';
import { LoginAction, LoginActionTypes } from '../actions/LoginActions';
import LoginState from '../state/LoginState';

export const initialState: LoginState = {
  error: '',
  loading: false,
  tangraLoginError: undefined,
  tangraLoginDetails: undefined,
  cboLoginDetails: undefined,
  cboLoginError: undefined,
  customLoginDetails: {} as ICustomLoginResponse
};

export const loginReducer: Reducer<LoginState> = (state = initialState, action: LoginAction) => {
  switch (action.type) {

    case LoginActionTypes.TANGRA_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        tangraLoginError: undefined,
      };
    case LoginActionTypes.TANGRA_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        tangraLoginError: undefined,
        cboLoginDetails: action.data as ICboLoginResponse,
      };
    case LoginActionTypes.TANGRA_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        cboLoginError: action.data,
      };

      case LoginActionTypes.CUSTOM_LOGIN_START:
        return {
          ...state,
          loading: true,
          error: undefined,
        };
      case LoginActionTypes.CUSTOM_LOGIN_SUCCESS:
        return {
          ...state,
          loading: false,
          error: undefined,
          customLoginDetails: action.data as ICustomLoginResponse,
        };
      case LoginActionTypes.CUSTOM_LOGIN_ERROR:
        return {
          ...state,
          loading: false,
          error: action.data,
        };

        case LoginActionTypes.CHANGE_PASSWORD_START:
        return {
          ...state,
          loading: true,
          error: undefined,
        };
      case LoginActionTypes.CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          error: undefined,
          customLoginDetails: action.data as ICustomLoginResponse,
        };
      case LoginActionTypes.CHANGE_PASSWORD_ERROR:
        return {
          ...state,
          loading: false,
          error: action.data,
        };

    default:
      return state;
  }
};
