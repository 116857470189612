import { put, select, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import cboViewSummaryService from '../../../services/cbo/CboViewSummaryService/CboViewSummaryService';
import CboViewSummaryService from '../../../services/cbo/CboViewSummaryService/CboViewSummaryService';
import {
  CboViewSummaryAction,
  CboViewSummaryActions,
  CboViewSummaryActionTypes,
} from '../../actions/cbo/CboViewSummaryAction';
import { ToastActions } from '../../actions/ToastActions';
import {
  ICapabilitiesSequence,
  ICapabilityReorderPayload,
  IViewSummaryResponsePayload,
} from '../../state/cbo/CboViewSummaryState';
import {
  IConfigurePayload,
  IEngagementConfigurationResponse,
} from '../../../models/cbo/EngagementModels';
import EngagementService from '../../../services/cbo/EnagementService/EngagementService';
import { EngagementActions } from '../../actions/cbo/EngagementAction';
import { IFeatureUpdatePayload } from '../../../models/cbo/EngagementSummaryGraphModel';

export function* getCapabilitiesWorker(action: CboViewSummaryAction) {
  try {
    //const selectedEnagagementId = action.data as string;
    const capabilitiesList: IViewSummaryResponsePayload =
      yield CboViewSummaryService.getCapabilitiesDetails(action.data as IConfigurePayload)!;
    yield put(
      CboViewSummaryActions.getCapabilitiesDetailSuccess(
        capabilitiesList,
        (action.data as IConfigurePayload)?.isEngagement ?? false,
      ),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(CboViewSummaryActions.getCapabilitiesDetailError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* postCapabilityOrderWorker(action: CboViewSummaryAction) {
  try {
    const body = action.data as ICapabilityReorderPayload;
    const response: ICapabilitiesSequence[] = yield CboViewSummaryService.postCapabilityOrder(body);
    yield put(CboViewSummaryActions.PostCapabilityOrderSuccess(response));
    yield put(
      ToastActions.showToast({
        description: 'Capabilities order updated!',
        type: NotificationTypes.SUCCESS,
      }),
    );

    //   try {
    //     const capabilitiesList : IViewSummaryResponsePayload = yield CboViewSummaryService.getCapabilitiesDetails(body.engagementId);
    //     yield put(CboViewSummaryActions.getCapabilitiesDetailSuccess(capabilitiesList,(action.data as IConfigurePayload)?.isEngagement ?? false));
    // }
    // catch (error) {
    //     if(error instanceof Error){
    //         yield put(CboViewSummaryActions.getCapabilitiesDetailError(error));
    //     }
    // }
  } catch (error) {
    if (error instanceof Error) {
      yield put(CboViewSummaryActions.PostCapabilityOrderError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* patchFeaturesUpdateWorker(action: CboViewSummaryAction) {
  try {
    const body = action.data as IFeatureUpdatePayload;
    yield CboViewSummaryService.patchFeaturesUpdate(body);
    yield put(
      ToastActions.showToast({
        description: 'Feature(s) updated Successfully!',
        type: NotificationTypes.SUCCESS,
      }),
    );
    yield put(CboViewSummaryActions.patchFeatureUpdateSuccess());

    try {
      const response: IEngagementConfigurationResponse =
        yield EngagementService.getEngagementConfiguration({
          engagementId: body.engagementId,
          isEngagement: body.isEngagement,
        })!;
      yield put(EngagementActions.getEngagementConfigDataSuccess(response, body.isEngagement));
    } catch (error) {
      if (error instanceof Error) {
        yield put(EngagementActions.getEngagementConfigDataError(error));
        yield put(
          ToastActions.showToast({
            message: 'Error!!!',
            description: error.message,
            type: NotificationTypes.ERROR,
          }),
        );
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(CboViewSummaryActions.patchFeatureUpdateError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* cboViewSummaryWatcher() {
  yield takeEvery(
    CboViewSummaryActionTypes.GET_CAPABILITES_FOR_ENGAGEMENT_ID_STARTED,
    getCapabilitiesWorker,
  );
  yield takeEvery(
    CboViewSummaryActionTypes.POST_CAPABILITY_ORDER_STARTED,
    postCapabilityOrderWorker,
  );
  yield takeEvery(
    CboViewSummaryActionTypes.PATCH_FEATURE_UPDATE_STARTED,
    patchFeaturesUpdateWorker,
  );
}
