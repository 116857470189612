/* eslint-disable max-lines */
import * as axios from 'axios';
import { DUMMY_ACCESS_TOKEN } from '../../AppConstants';
import { AppSettings } from '../../AppSettings';
import { API_ROUTE_CBO } from './ApiRoute';
import AxiosInstance from './AxiosInstance';
import { HttpContentType } from './HttpContentType';
import { storage, STORAGE_CONSTANTS } from './LocalStorage';
import { IMultipartFormData } from './MultipartFormData.data';
import { QueryPath } from './QueryPath.data';
import { IApiAdditionalData, ServerType, ServiceType } from './ServiceType.data';
import { IProduct } from '../models/cbo/EngagementModels';
import { formatUrl, getEnv } from '../helper/HelperFunctions';
import { CBOKeyAssets } from '../../containers/CBOSidebar/CBOSidebarConstants';
import { congnitoConfiguration } from '../../configs/AWS';
import {
  dbCutterDemoInstanceConfig,
  digitalTesterDemoInstanceConfig,
  monolithCutterDemoInstanceConfig,
  turboCodeDemoInstanceConfig,
} from '../../containers/KeyAssets/KeyAssetsConstant';

/// <summary>
/// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
/// </summary>
export default abstract class ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly serverType: ServerType;
  protected isFetchingToken = false;
  protected tokenSubscribers: any = [];

  constructor(serviceType: ServiceType, serverType: ServerType) {
    this.serviceType = serviceType;
    this.serverType = serverType;
  }

  public abstract get<T = void>(
    path: QueryPath,
    additionalData?: IApiAdditionalData,
  ): Promise<T> | T;

  public abstract post<T = void>(
    path: QueryPath,
    body: any,
    addtionalData?: IApiAdditionalData,
  ): Promise<T> | T;

  public abstract put<T = void>(
    path: QueryPath,
    body: any,
    additionalData?: IApiAdditionalData,
  ): Promise<T> | T;

  public abstract patch<T = void>(
    path: QueryPath,
    body: any,
    additionalData?: IApiAdditionalData,
  ): Promise<T> | T;

  public abstract delete<T = void>(
    path: QueryPath,
    additionalData?: IApiAdditionalData,
  ): Promise<T>;

  public abstract postMultipart<T = void>(
    path: QueryPath,
    data: IMultipartFormData[],
    additionalData?: IApiAdditionalData,
  ): Promise<T> | T;

  public abstract putMultipart<T = void>(
    path: QueryPath,
    data: IMultipartFormData[],
    additionalData?: IApiAdditionalData,
  ): Promise<T> | T;

  public abstract patchMultipart<T = void>(
    path: QueryPath,
    data: IMultipartFormData[],
    additionalData?: IApiAdditionalData,
  ): Promise<T> | T;

  /* tslint:disable:cyclomatic-complexity */
  public processError(error: any) {
    const errorCode = error.response ? error.response.status : 0;
    switch (errorCode) {
      case 404:
        return new Error('The request is not found');
      case 500:
        return new Error('Internal server error');
      case 401:
        return new Error(error?.response?.data);
      case 0:
        return new Error(`${error}. Please reload the page.`);
      case 402:

      case 403:
      case 400:
      case 422: {
        if (error.response.data.errors) {
          const err = error.response.data.errors;

          if (err instanceof Array) {
            const errArr = err;

            if (errArr.length > 0 && errArr[0]) {
              if (errArr[0].message) {
                return new Error(errArr[0].message.toString());
              } else if (errArr[0].Message) {
                return new Error(errArr[0].Message.toString());
              } else {
                return new Error(errArr[0].toString());
              }
            }
          } else if (err.message) {
            return new Error(err.message.toString());
          } else {
            return new Error(err.toString());
          }
        } else if (error.response.data) {
          return new Error(
            error.response.data.message ?? error.response.data ?? 'Something went wrong',
          );
        }

        return new Error('Internal server error');
      }
      default:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return error;
    }
  }

  /* tslint:enable */
  protected getConfig(contentType: HttpContentType): axios.AxiosRequestConfig {
    let headers;
    if (
      this.serverType == ServerType.tangra ||
      this.serverType == ServerType.cbo ||
      this.serverType == ServerType.localStack
    ) {
      const cwbIdToken = storage.getItem(STORAGE_CONSTANTS.cwbIdToken);
      const userId = storage.getItem(STORAGE_CONSTANTS.userId);
      const userType = storage.getItem(STORAGE_CONSTANTS.userType);
      headers = this.serviceType.startsWith('user')
        ? {
            'Content-Type': contentType.toString(),
            id_token: `Bearer ${cwbIdToken}`,
            access_token: storage.getItem(STORAGE_CONSTANTS.accessToken) || DUMMY_ACCESS_TOKEN,
            cwb_flag: `${Boolean(cwbIdToken)}`,
            userid: `${userId}`,
            user_type: `${userType}`,
          }
        : {
            'Content-Type': contentType.toString(),
            access_token: storage.getItem(STORAGE_CONSTANTS.accessToken) || DUMMY_ACCESS_TOKEN,
            id_token: storage.getItem(STORAGE_CONSTANTS.idToken) || DUMMY_ACCESS_TOKEN,
            userid: `${userId}`,
            user_type: `${userType}`,
          };
    } else {
      headers = this.serviceType.startsWith('user')
        ? {
            'Content-Type': contentType.toString(),
            id_token: `Bearer ${storage.getItem(STORAGE_CONSTANTS.cwbIdToken)}`,
          }
        : this.serviceType.startsWith('tco') ||
            this.serviceType.startsWith('csp') ||
            this.serviceType.startsWith('oma')
          ? {
              'Content-Type': contentType.toString(),
              idtoken: `Bearer ${storage.getItem(STORAGE_CONSTANTS.cwbIdToken)}`,
            }
          : {
              'Content-Type': contentType.toString(),
              idtoken: `Basic ${storage.getItem(STORAGE_CONSTANTS.authTokenBasic)}`,
            };
    }

    return { headers };
  }

  protected isAuthTokenRequired(path: string): boolean {
    return path.includes('/api');
  }

  protected getAxiosInstance(): axios.AxiosInstance {
    const instance = AxiosInstance.create();
    // const { baseUrl } = AppSettings;

    const subscribeTokenRefresh = (callBack: any) => {
      this.tokenSubscribers.push(callBack);
    };

    const onTokenRefreshed = (error: Error | null) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.tokenSubscribers.map((cb: any) => cb(error));
      this.tokenSubscribers = [];
    };

    const forceLogout = () => {
      this.isFetchingToken = false;
      storage.clearAll();
      window.location.href = `/#/login`;
    };

    instance.interceptors.response.use(
      (response: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response;
      },
      (error: any) => {
        if (!error.response) {
          return Promise.reject(error);
        }

        if (
          error.response.status !== 403 &&
          error.response.status !== 401 &&
          error.response.status !== 400
        ) {
          return Promise.reject(error);
        }

        if (error.config.url.includes('login')) {
          return Promise.reject(error);
        }

        // If refreshToken API, force logout
        if (error.config.url.includes('refresh_token') && error.response.status === 400) {
          onTokenRefreshed(new Error('Unable to refresh access token'));
          forceLogout();
          return Promise.reject(error);
        }

        //CBO API gateway returns 403 for unauthorized access of a resource
        if (error.response.status === 403 && this.serverType === ServerType.cbo) {
          //Might need for future reference
          //window.location.href = ("/cboAccessDenied")
        }
        // CBO Access Token could be expired when CBO API fails with 401
        if (
          error.response.status === 401 &&
          (this.serverType === ServerType.tangra || this.serverType === ServerType.cbo)
        ) {
          if (!this.isFetchingToken) {
            this.isFetchingToken = true;

            const currentRefreshToken = storage.getItem(STORAGE_CONSTANTS.refreshToken);
            return instance
              .get(
                `${AppSettings.CBOServer}/${API_ROUTE_CBO.REFRESH_TOKEN}?refresh_token=${currentRefreshToken}`,
                {
                  headers: {
                    access_token:
                      storage.getItem(STORAGE_CONSTANTS.accessToken) || DUMMY_ACCESS_TOKEN,
                    userid: `${storage.getItem(STORAGE_CONSTANTS.userId)}`,
                    user_type: `${storage.getItem(STORAGE_CONSTANTS.userType)}`,
                  },
                },
              )
              .then((response) => {
                const { access_token } = response.data;
                this.isFetchingToken = false;
                storage.setItem(STORAGE_CONSTANTS.accessToken, access_token);
                error.config.headers.access_token = access_token;
                onTokenRefreshed(null);
                return instance(error.config);
              });
          } else {
            const initTokenSubscriber = new Promise((resolve, reject) => {
              subscribeTokenRefresh((errRefreshing: any) => {
                if (errRefreshing) {
                  return reject(errRefreshing);
                }
                error.config.headers.access_token = storage.getItem(STORAGE_CONSTANTS.accessToken);
                return resolve(instance(error.config));
              });
            });
            return initTokenSubscriber;
          }
        }
        return Promise.reject(error);
      },
    );
    return instance;
  }

  protected getinstanceUrl(assetName?: string): string {
    let KeyAssetList = JSON.parse(
      storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]',
    ) as IProduct[];
    return KeyAssetList?.find((asset) => asset.product == assetName)?.instanceUrl ?? '';
  }

  // Generates url: {AppSettings.service.baseUrl}/{this.serviceType}/{routeParam1}/{routeParam2}/.../{routeParamN}?{queryParam1key}={queryParam1val}&{queryParam2key}={queryParam2val}...
  // Query params with null, undefined or empty string won't be appended to the url.

  protected getUrl(path: QueryPath, additionalData?: IApiAdditionalData): string {
    let baseURL;
    if (this.serverType == ServerType.dca) baseURL = AppSettings.DCAserver;
    else if (this.serverType == ServerType.cbo) baseURL = AppSettings.CBOServer;
    else if (this.serverType == ServerType.localStack) baseURL = AppSettings.cboLocalServer;
    else if (this.serverType == ServerType.tangra) baseURL = AppSettings.tangraURL;
    else if (this.serverType == ServerType.tangraDCA) baseURL = AppSettings.TangraDCAserver;
    else if (this.serverType == ServerType.cwb) baseURL = AppSettings.cwbServer;

    let url: string = '';

    if (additionalData) {
      url = formatUrl(this.getinstanceUrl(additionalData?.assetName));
    } else {
      url = this.serviceType ? `${baseURL}/${this.serviceType}` : `${baseURL}`;
    }
    if (path) {
      if (path.route && path.route.length > 0) {
        for (const route of path.route) {
          if (route && route !== 'undefined') {
            url += `/${route}`;
          }
        }
      }

      if (path.query) {
        let separator = '?';
        for (const name in path.query) {
          if (path.query[name] != undefined) {
            url += `${separator}${encodeURI(name)}=${encodeURI(path.query[name]!.toString())}`;
            separator = '&';
          }
        }
      }
    }
    return url;
  }

  protected prepareMultiPartForm(data: IMultipartFormData[]): FormData {
    //  It is expected that if a file is to be given a name that is different from the file system name,
    //  the interface created for such objects will have a key named "formDataName" and another named "file" (names are self-explanatory)

    const formData = new FormData();
    for (const item of data) {
      const { content, name } = item;
      if (Array.isArray(content) && content.length > 0) {
        for (const element of content) {
          if (
            typeof element === 'object' &&
            Object.prototype.hasOwnProperty.call(element, 'formDataName')
          ) {
            formData.append(name, element.file, element.formDataName);
          } else {
            formData.append(name, element);
          }
        }
      } else if (
        typeof content === 'object' &&
        content !== null &&
        Object.prototype.hasOwnProperty.call(content, 'formDataName')
      ) {
        formData.append(name, content.file, content.formDataName);
      } else {
        formData.append(name, content);
      }
    }
    return formData;
  }
}
