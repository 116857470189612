    import { IRefreshTokenResponse } from '../../../store/state/cbo/RefreshTokenState';
import { API_ROUTE_CBO } from '../../ApiRoute';
    import ApiService from '../../ApiService';
    import ApiServiceBase from '../../ApiServiceBase';
    import { ServerType, ServiceType } from '../../ServiceType.data';

    export class RefreshTokenService {
      private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);

      public refreshToken(payload:string): Promise<IRefreshTokenResponse> | IRefreshTokenResponse {
        const route: string[] = [`${API_ROUTE_CBO.REFRESH_TOKEN}?refresh_token=${payload}`];
        return this.cboService.get({ route: route });
      }
    }

    const refreshTokenService = new RefreshTokenService();
    export default refreshTokenService;
