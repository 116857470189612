import {
  IMarketplaceSystemTags,
  IFilterPayload,
  IPostFilterResponse,
  ISearchAndFilterAssetsPayload,
  ISearchAndFilterAssetsResponse,
  IJourneyFilter,
  IImportantLinks,
} from '../../../models/cbo/CboMarketplaceSearchModels';
import { IJourneyList } from '../../../models/cbo/JourneyBuilderModel';
import { IStoreAction } from '../../StoreHelper';

export enum CboMarketplaceSearchActionTypes {
  GET_ASSET_METADATA_STARTED = 'GET_ASSET_METADATA_STARTED',
  GET_ASSET_METADATA_SUCCESS = 'GET_ASSET_METADATA_SUCCESS',
  GET_ASSET_METADATA_ERROR = 'GET_ASSET_METADATA_ERROR',
  GET_SYSTEM_TAGS_STARTED = 'GET_SYSTEM_TAGS_STARTED',
  GET_SYSTEM_TAGS_SUCCESS = 'GET_SYSTEM_TAGS_SUCCESS',
  GET_SYSTEM_TAGS_ERROR = 'GET_SYSTEM_TAGS_ERROR',
  GET_SEARCH_FILTER_DATA_STARTED = 'GET_SEARCH_FILTER_DATA_STARTED',
  GET_SEARCH_FILTER_DATA_SUCCESS = 'GET_SEARCH_FILTER_DATA_SUCCESS',
  GET_SEARCH_FILTER_DATA_ERROR = 'GET_SEARCH_FILTER_DATA_ERROR',
  POST_FILTER_URL_DATA_STARTED = 'POST_FILTER_URL_DATA_STARTED',
  POST_FILTER_URL_DATA_SUCCESS = 'POST_FILTER_URL_DATA_SUCCESS',
  POST_FILTER_URL_DATA_ERROR = 'POST_FILTER_URL_DATA_ERROR',
  GET_URL_DATA_STARTED = 'GET_URL_DATA_STARTED',
  GET_URL_DATA_SUCCESS = 'GET_URL_DATA_SUCCESS',
  GET_URL_DATA_ERROR = 'GET_URL_DATA_ERROR',
  RESET_FILTER_DATA= 'RESET_FILTER_DATA',
  GET_FILTER_JOURNEY_LIST_STARTED='GET_FILTER_JOURNEY_LIST_STARTED',
  GET_FILTER_JOURNEY_LIST_SUCCESS='GET_FILTER_JOURNEY_LIST_SUCCESS',
  GET_FILTER_JOURNEY_LIST_ERROR='GET_FILTER_JOURNEY_LIST_ERROR',
  GET_IMPORTANT_LINKS_DATA_STARTED='GET_IMPORTANT_LINKS_DATA_STARTED',
  GET_IMPORTANT_LINKS_DATA_SUCCESS='GET_IMPORTANT_LINKS_DATA_SUCCESS',
  GET_IMPORTANT_LINKS_DATA_ERROR='GET_IMPORTANT_LINKS_DATA_ERROR'
}

export type CboMarketplaceSearchPayload =
  | string
  | Error
  | string[]
  | IMarketplaceSystemTags
  | ISearchAndFilterAssetsPayload
  | ISearchAndFilterAssetsResponse
  | IFilterPayload
  | IPostFilterResponse
  | IJourneyFilter[]
  | IImportantLinks[]

export type CboMarketplaceSearchAction = IStoreAction<
  CboMarketplaceSearchActionTypes,
  CboMarketplaceSearchPayload
>;
export class CboMarketplaceSearchActions {
  // meta data
  public static getAssetsMetaDataStart(): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_ASSET_METADATA_STARTED,
    };
  }
  public static getAssetsMetaDataSuccess(data: string[]): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_ASSET_METADATA_SUCCESS,
      data,
    };
  }
  public static getAssetsMetaDataError(error: Error): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_ASSET_METADATA_ERROR,
      data: error,
    };
  }

  //   system tags
  public static getSystemTagsDataStart(): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_SYSTEM_TAGS_STARTED,
    };
  }
  public static getSystemTagsDataSuccess(
    data: IMarketplaceSystemTags,
  ): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_SYSTEM_TAGS_SUCCESS,
      data,
    };
  }
  public static getSystemTagsDataError(error: Error): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_SYSTEM_TAGS_ERROR,
      data: error,
    };
  }

  //   search and filter
  public static getSearchFilterDataStart(
    data: ISearchAndFilterAssetsPayload,
  ): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_SEARCH_FILTER_DATA_STARTED,
      data,
    };
  }
  public static getSearchFilterDataSuccess(
    data: ISearchAndFilterAssetsResponse,
  ): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_SEARCH_FILTER_DATA_SUCCESS,
      data,
    };
  }
  public static getSearchFilterDataError(error: Error): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_SEARCH_FILTER_DATA_ERROR,
      data: error,
    };
  }

  //    filter url POST
  public static postFilterUrlDataStart(data: IFilterPayload): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.POST_FILTER_URL_DATA_STARTED,
      data,
    };
  }
  public static postFilterUrlDataSuccess(data: IPostFilterResponse): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.POST_FILTER_URL_DATA_SUCCESS,
      data,
    };
  }
  public static postFilterUrlDataError(error: Error): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.POST_FILTER_URL_DATA_ERROR,
      data: error,
    };
  }

  //   filter url GET
  public static getFilterDataStart(data:string): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_URL_DATA_STARTED,
      data,
    };
  }
  public static getFilterDataSuccess(data: IFilterPayload): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_URL_DATA_SUCCESS,
      data,
    };
  }
  public static getFilterDataError(error: Error): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_URL_DATA_ERROR,
      data: error,
    };
  }

  //  filter journey List
  public static getFilterJourneyListStart(): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_FILTER_JOURNEY_LIST_STARTED,
    };
  }
  public static getFilterJourneyListSuccess(data:IJourneyFilter[]): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_FILTER_JOURNEY_LIST_SUCCESS,
      data
    };
  }
  public static getFilterJourneyListError(error: Error): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_FILTER_JOURNEY_LIST_ERROR,
      data: error,
    };
  }

  //important links
  public static getImportantLinksStart(data:string): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_IMPORTANT_LINKS_DATA_STARTED,
      data
    };
  }
  public static getImportantLinksSuccess(data:IImportantLinks[]): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_IMPORTANT_LINKS_DATA_SUCCESS,
      data
    };
  }
  public static getImportantLinksError(error: Error): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.GET_IMPORTANT_LINKS_DATA_ERROR,
      data: error,
    };
  }

  //reset filter data
  public static resetFilterData(): CboMarketplaceSearchAction {
    return {
      type: CboMarketplaceSearchActionTypes.RESET_FILTER_DATA
    }
  }
}
