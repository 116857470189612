import { API_ROUTE_CBO } from '../../ApiRoute';
import ApiService from '../../ApiService';
import ApiServiceBase from '../../ApiServiceBase';
import { ServerType, ServiceType } from '../../ServiceType.data';
import {
  IEngagementAssetsObject,
  IEngagementAssetsPayload,
  IMarketplaceAssetModal,
  IMarketplaceAssetPayload,
} from '../../../models/cbo/CboMarketplaceModels';

export class MarketplaceService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);

  public getEngagementMandatoryAssets(
    data: IMarketplaceAssetPayload,
  ): Promise<IEngagementAssetsObject> | IEngagementAssetsObject {
    return this.cboService.get({
      route: [
        `${
          data.isEngagement
            ? API_ROUTE_CBO.GET_ENGAGEMENT_ASSETS.replace(
                `:engagementId`,
                data.selectedEnagagementId,
              )
            : API_ROUTE_CBO.GET_PURSUIT_ASSETS.replace(`:engagementId`, data.selectedEnagagementId)
        }`,
      ],
      query: { assetType: 'Mandatory' },
    });
  }

  public getEngagementRecommendedAssets(
    data: IMarketplaceAssetPayload,
  ): Promise<IEngagementAssetsObject> | IEngagementAssetsObject {
    return this.cboService.get({
      route: [
        `${
          data.isEngagement
            ? API_ROUTE_CBO.GET_ENGAGEMENT_ASSETS.replace(
                `:engagementId`,
                data.selectedEnagagementId,
              )
            : API_ROUTE_CBO.GET_PURSUIT_ASSETS.replace(`:engagementId`, data.selectedEnagagementId)
        }`,
      ],
      query: { assetType: 'Recommended' },
    });
  }

  public addEngagmentAsset(
    data: IEngagementAssetsPayload,
  ): Promise<IEngagementAssetsPayload> | IEngagementAssetsPayload {
    return this.cboService.patch(
      {
        route: [
          `${
            data.isEngagement
              ? API_ROUTE_CBO.POST_ENGAGEMENT_ASSETS
              : API_ROUTE_CBO.POST_PURSUIT_ASSETS
          }`,
        ],
      },
      data,
    );
  }

  public getEngagementAssetDetails(
    data: string,
  ): Promise<IMarketplaceAssetModal> | IMarketplaceAssetModal {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_ENGAGEMENT_ASSET_DETAILS.replace(`:assetId`, data)}`],
    });
  }
}

const marketplaceService = new MarketplaceService();
export default marketplaceService;
