import { storage, STORAGE_CONSTANTS } from '../../services/LocalStorage';
import {
  IArchitectureCatalogueResponse,
  IDeploymentsResponse,
  ILandingZoneState,
  IYamlContent,
  IFormInterface,
} from '../../store/state/BuildDeployState';
import {  API_ROUTE_CBO } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';
import { IProduct } from '../../models/cbo/EngagementModels';
import { CBOKeyAssets } from '../../../containers/CBOSidebar/CBOSidebarConstants';

export class BuildDeployService {
  private readonly localService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.localStack);
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);

  private readonly tangraService: ApiServiceBase = new ApiService(ServiceType.None,ServerType.tangra);

  
  public getDeploymentsList(projectId: string): Promise<IDeploymentsResponse[]> | IDeploymentsResponse[] {
    let route: string[] = [];
    route = [`${API_ROUTE_CBO.WORKFLOW}/${projectId}`];
    return this.tangraService.get({ route: route });
  }

  public getMultiCloudYamlContent(payload: IFormInterface): Promise<IYamlContent> | IYamlContent {
    return this.tangraService.post(
      {
        route: [`${API_ROUTE_CBO.multiCloudYaml}`],
      },
      payload,
    );
  }
  public getArchitectureListServive(projectId?:string):| Promise<IArchitectureCatalogueResponse[]>| IArchitectureCatalogueResponse[] {
  const isEngagement = JSON.parse(storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false') as boolean;
  const keyAssetList = JSON.parse(storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]') as IProduct[];
  const dcaAsset =  keyAssetList?.find((keyAsset)=>keyAsset.product == CBOKeyAssets.cboDCAAsset);
  if (isEngagement) {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_ENG_S3_FILES.replace(':projectId', projectId!)}`]
    });
  } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.GET_PURSUIT_S3_FILES.replace(':pursuitId', projectId!)}`]
      });        
    
  }
}

public getLandingZone(projectId: string): Promise<ILandingZoneState[]> | ILandingZoneState[] {
  const isEngagement = JSON.parse(storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false') as boolean;
  const keyAssetList = JSON.parse(storage.getItem(STORAGE_CONSTANTS.keyAssetList) ?? '[]') as IProduct[];
  const dcaAsset =  keyAssetList?.find((keyAsset)=>keyAsset.product == CBOKeyAssets.cboDCAAsset);
  if (isEngagement) {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.LIST_ENG_LANDING_ZONES.replace(':projectId', projectId!)}`]
    });
  } else {
      return this.cboService.get({
        route: [`${API_ROUTE_CBO.LIST_PURSUIT_LANDING_ZONES.replace(':pursuitId', projectId!)}`]
      });
    }
}

}

const buildDeployService = new BuildDeployService();
export default buildDeployService;
