import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import { IJourneyCapabilitiesResponse } from '../../../models/cbo/EngagementModels';
import {
  IJourneyBuilderPayload,
  IJourneyList,
  IJourneyMarketplacePayload,
  IMarketplaceAssets,
  IPrdefinedJouneyList,
  IPredefinedJourneyListMapping,
  ISubmitStatuspayload,
  IJourneyCapabilityListPayload,
  IRecommendedAssetsResponse,
  IJourneySaveResponse,
  IJourneyMapping,
} from '../../../models/cbo/JourneyBuilderModel';
import journeyBuilderService from '../../../services/cbo/JourneyBuilderService/JourneyBuilderService';
import JourneyBuilderService from '../../../services/cbo/JourneyBuilderService/JourneyBuilderService';
import {
  JourneyBuilderAction,
  JourneyBuilderActions,
  JourneyBuilderActionTypes,
} from '../../actions/cbo/JourneyBuilderActions';
import { ToastActions } from '../../actions/ToastActions';

export function* getJourneyBuilderListWorker(action: JourneyBuilderAction) {
  try {
    const response: IJourneyList[] = yield JourneyBuilderService.getJourneyList();
    yield put(JourneyBuilderActions.getJourneyListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.getJourneyListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getPredefinedJourneyListWorker(action: JourneyBuilderAction) {
  try {
    const response: IPrdefinedJouneyList[] = yield JourneyBuilderService.getPredefinedJourneyList();
    const mappedData: IPredefinedJourneyListMapping[] = response.map((data) => ({
      label: data.journeyName,
      value: data.journeyId,
      offering: data.offering,
    }));
    yield put(JourneyBuilderActions.getPredefinedJourneyListSuccess(mappedData));
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.getPredefinedJourneyListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getJourneyCapabilityDataWorker(action: JourneyBuilderAction) {
  try {
    const data = action.data as IJourneyCapabilityListPayload;
    const response: IJourneyCapabilitiesResponse =
      (yield JourneyBuilderService.getCapabilitiesForPredefinedJourney(data))!;
    yield put(JourneyBuilderActions.getCapabilitiesForPredefinedJourneySuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.getCapabilitiesForPredefinedJourneyError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getJourneyMandatoryAssetstWorker(action: JourneyBuilderAction) {
  try {
    const data = action.data as IJourneyMarketplacePayload;
    const response: IMarketplaceAssets[] =
      yield JourneyBuilderService.getJourneyMandatoryAssets(data);
    yield put(JourneyBuilderActions.getJourneyMandatoryAssetsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.getJourneyListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}
export function* getJourneyRecommendedAssetstWorker(action: JourneyBuilderAction) {
  try {
    const data = action.data as IJourneyMarketplacePayload;
    const response: IRecommendedAssetsResponse =
      yield JourneyBuilderService.getJourneyRecommendedAssets(data);
    yield put(JourneyBuilderActions.getJourneyRecommendedAssetsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.getJourneyListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* postJourneyBuilderSaveWorker(action: JourneyBuilderAction) {
  try {
    const body = action.data as IJourneyBuilderPayload;
    const response: IJourneySaveResponse =
      yield JourneyBuilderService.postJourneyBuilderSave(body)!;
    yield put(JourneyBuilderActions.postJourneyBuilderSaveSuccess(response));
    yield put(
      ToastActions.showToast({
        description: 'Journey Saved!',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const response: IJourneyList[] = yield JourneyBuilderService.getJourneyList();
      yield put(JourneyBuilderActions.getJourneyListSuccess(response));
    } catch (error) {
      if (error instanceof Error) {
        yield put(JourneyBuilderActions.getJourneyListError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.postJourneyBuilderSaveError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* patchJourneyBuilderUpdateWorker(action: JourneyBuilderAction) {
  try {
    const body = action.data as IJourneyBuilderPayload;
    const response: IJourneySaveResponse =
      yield JourneyBuilderService.patchJourneyBuilderUpdate(body)!;
    yield put(JourneyBuilderActions.patchJourneyBuilderUpdateSuccess(response));
    yield put(
      ToastActions.showToast({
        description: 'Journey Updated!',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const response: IJourneyList[] = yield JourneyBuilderService.getJourneyList();
      yield put(JourneyBuilderActions.getJourneyListSuccess(response));
    } catch (error) {
      if (error instanceof Error) {
        yield put(JourneyBuilderActions.getJourneyListError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.patchJourneyBuilderUpdateError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* postSubmitStatusWorker(action: JourneyBuilderAction) {
  try {
    const body = action.data as ISubmitStatuspayload;
    yield JourneyBuilderService.postSubmitStatus(body)!;
    yield put(JourneyBuilderActions.PostSubmitStatusSuccess());
    yield put(
      ToastActions.showToast({
        description: `Journey - ${body.journeyName} has been successfully ${body.status}`,
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const response: IJourneyList[] = yield JourneyBuilderService.getJourneyList();
      yield put(JourneyBuilderActions.getJourneyListSuccess(response));
    } catch (error) {
      if (error instanceof Error) {
        yield put(JourneyBuilderActions.getJourneyListError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.PostSubmitStatusError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* deleteDocumentDataWorker(action: JourneyBuilderAction) {
  try {
    const data = action.data as string;
    yield journeyBuilderService.deleteDocument(data);
    yield put(
      ToastActions.showToast({
        description: 'Document deleted successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.getJourneyMandatoryAssetsError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getBusinessObjectivesWorker(action: JourneyBuilderAction) {
  try {
    const response: string[] = yield JourneyBuilderService.getBusinessObjectives();
    yield put(JourneyBuilderActions.getBusinessObjectivesSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.getBusinessObjectivesError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getJourneyMappingWorker(action: JourneyBuilderAction) {
  try {
    const data = action.data as string;
    const response: IJourneyMapping = yield JourneyBuilderService.getJourneyMapping(data);

    yield put(JourneyBuilderActions.getJourneyMappingSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.deleteJourneyError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getJourneydeleteWorker(action: JourneyBuilderAction) {
  try {
    const data = action.data as string;
    const response: IJourneySaveResponse = yield JourneyBuilderService.deleteJourney(data);
    yield put(JourneyBuilderActions.deleteJourneySuccess(response));
    yield put(
      ToastActions.showToast({
        description: 'Journey deleted successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      const response: IJourneyList[] = yield JourneyBuilderService.getJourneyList();
      yield put(JourneyBuilderActions.getJourneyListSuccess(response));
    } catch (error) {
      if (error instanceof Error) {
        yield put(JourneyBuilderActions.getJourneyListError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(JourneyBuilderActions.getJourneyMappingtError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}
export function* journeyBuilderWatcher() {
  yield takeEvery(
    JourneyBuilderActionTypes.GET_JOURNEY_LIST_DATA_STARTED,
    getJourneyBuilderListWorker,
  );
  yield takeEvery(
    JourneyBuilderActionTypes.GET_PREDEFINED_JOURNEY_LIST_STARTED,
    getPredefinedJourneyListWorker,
  );
  yield takeEvery(
    JourneyBuilderActionTypes.GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_STARTED,
    getJourneyCapabilityDataWorker,
  );
  yield takeEvery(
    JourneyBuilderActionTypes.GET_JOURNEY_MANDATORY_ASSETS_STARTED,
    getJourneyMandatoryAssetstWorker,
  );
  yield takeEvery(
    JourneyBuilderActionTypes.GET_JOURNEY_RECOMMENDED_ASSETS_STARTED,
    getJourneyRecommendedAssetstWorker,
  );
  yield takeEvery(
    JourneyBuilderActionTypes.POST_JOURNEY_BUILDER_SAVE_STARTED,
    postJourneyBuilderSaveWorker,
  );
  yield takeEvery(
    JourneyBuilderActionTypes.PATCH_JOURNEY_BUILDER_UPDATE_STARTED,
    patchJourneyBuilderUpdateWorker,
  );
  yield takeEvery(JourneyBuilderActionTypes.DELETE_DOCUMENT_DATA_STARTED, deleteDocumentDataWorker);
  yield takeEvery(JourneyBuilderActionTypes.POST_SUBMIT_STATUS_STARTED, postSubmitStatusWorker);
  yield takeEvery(
    JourneyBuilderActionTypes.GET_BUSINESS_OBJECTIVES__STARTED,
    getBusinessObjectivesWorker,
  );
  yield takeEvery(JourneyBuilderActionTypes.GET_JOURNEY_MAPPING_STARTED, getJourneyMappingWorker);
  yield takeEvery(JourneyBuilderActionTypes.DELETE_JOURNEY_MAPPING_STARTED, getJourneydeleteWorker);
}
