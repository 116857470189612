import { Reducer } from 'redux';
import {IHeatmapResponse } from '../../models/CloudSuitabilityModels';
import {
  CloudSuitabilityAction,
  CloudSuitabilityActionTypes,
} from '../actions/CloudSuitabilityActions';
import CloudSuitabilityState from '../state/CloudSuitabilityState';

const defaultHeatMapData: IHeatmapResponse = {
  listOfLOBs: [],
  listOfAttributes: [],
  totalNoOfApps: [],
  listOfAttributesInfo: [],
};
export const initialState: CloudSuitabilityState = {
  error: '',
  loading: false,
  heatmapData: defaultHeatMapData,
};

export const CloudSuitabilityReducer: Reducer<CloudSuitabilityState> = (
  state = initialState,
  action: CloudSuitabilityAction,
) => {
  switch (action.type) {
    case CloudSuitabilityActionTypes.GET_HEATMAP_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CloudSuitabilityActionTypes.GET_HEATMAP_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        heatmapData: action.data as IHeatmapResponse,
      };
    case CloudSuitabilityActionTypes.GET_HEATMAP_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        heatmapData: defaultHeatMapData,
      };  
      case CloudSuitabilityActionTypes.RESET_CLOUD_SUITABILITY_STATE:
      return {
        ...state,
        heatmapData: defaultHeatMapData,
      };
    default:
      return state;
  }
};
