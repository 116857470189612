import { put, takeEvery } from 'redux-saga/effects';
import {IHeatmapPayload, IHeatmapResponse} from '../../models/CloudSuitabilityModels';
import CloudSuitabilityService from '../../services/CloudSuitabilityService/CloudSuitabilityService';
import {
  CloudSuitabilityAction,
  CloudSuitabilityActions,
  CloudSuitabilityActionTypes,
} from '../actions/CloudSuitabilityActions';

export function* getHeatmapDataWorker(action: CloudSuitabilityAction) {
  try {
    const data = action.data as IHeatmapPayload;
    const response: IHeatmapResponse = (yield CloudSuitabilityService.getHeatmapData(data))!;
    yield put(CloudSuitabilityActions.getHeatmapDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CloudSuitabilityActions.getHeatmapDataError(error));
    }
  }
}
export function* cloudSuitabilityWatcher() {
  yield takeEvery(CloudSuitabilityActionTypes.GET_HEATMAP_DATA_STARTED, getHeatmapDataWorker);
  
}
