/**
 * Global styles for the Joyride product tour.
 */
export const joyRideGlobalStyles = {
  options: {
    arrowColor: '#0297a8',
    overlayColor: '#5D5D5D',
    textColor: '#11193C',
    zIndex: 1000,
    primaryColor: '#0fc39a',
  },
};

/**
 * Labels for the tooltip buttons in the product tour.
 */
export enum TooltipButtonLabel {
  BACK = 'Back',
  CLOSE = 'Close',
  NEXT = 'Next',
}
