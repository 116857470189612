import {
    IClientResponse,
    IClientAddPayload,
    IClientUpdatePayload,
    IEngagementResponse,
    IEngagementAddPayload,
    IEngagementUpdatePayload
    } from '../../../models/cbo/ClientEngagementManagementModel';
    import { API_ROUTE_CBO } from '../../ApiRoute';
    import ApiService from '../../ApiService';
    import ApiServiceBase from '../../ApiServiceBase';
    import { ServerType, ServiceType } from '../../ServiceType.data';
    
    export class ClientEngagementManagementService {
      private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);

      public getClientNames(): Promise<IClientResponse[]> | IClientResponse[] {
        const route: string[] = [`${API_ROUTE_CBO.GET_CLIENTS}`];
        return this.cboService.get({ 
          route: route , 
          query:{isLogoRequired:'False'}
        });
      }

      public getClientList(): Promise<IClientResponse[]> | IClientResponse[] {
        const route: string[] = [`${API_ROUTE_CBO.GET_CLIENTS}`];
        return this.cboService.get({ 
          route: route , 
          query:{isLogoRequired:'True'}
        });
      }
    
      public getEngagementList(data:string): Promise<IEngagementResponse[]> | IEngagementResponse[] {
        // const route: string[] = [`${API_ROUTE_CBO.GET_ENGAGEMENT_CONFIG.replace(`:engagementId`,data )}`];
        const route: string[] = [`${API_ROUTE_CBO.GET_CLIENT_ENGAGEMENTS.replace(`:clientId`,data )}`];
        return this.cboService.get({ route: route });
      }
  
      public addClient(data: IClientAddPayload): Promise<IClientAddPayload> | IClientAddPayload {
        const route: string[] = [`${API_ROUTE_CBO.SAVE_CLIENT}`];
        return this.cboService.post({ route }, data);
      }

      public updateClient(data: IClientUpdatePayload): Promise<IClientUpdatePayload> | IClientUpdatePayload {
        const route: string[] = [`${API_ROUTE_CBO.SAVE_CLIENT}`];
        return this.cboService.put({ route }, data);
      }

      public addEngagement(data: IEngagementAddPayload): Promise<IEngagementAddPayload> | IEngagementAddPayload {
        const route: string[] = [`${API_ROUTE_CBO.SAVE_ENGAGEMENT}`];
        return this.cboService.post({ route }, data.payload);
      }

      public updateEngagement(data: IEngagementUpdatePayload): Promise<IEngagementUpdatePayload> | IEngagementUpdatePayload {
        const route: string[] = [`${API_ROUTE_CBO.SAVE_ENGAGEMENT}`];
        return this.cboService.patch({ route }, data);
      }

      public deleteClient(data:string): Promise<string[]> | string[] {
        const route: string[] = [`${API_ROUTE_CBO.DELETE_CLIENT.replace(`:clientId`,data )}`];
        return this.cboService.delete({ route: route });
      }
    
    }
  
    const clientEngagementManagementService = new ClientEngagementManagementService();
    export default clientEngagementManagementService;
