
export const AssetAuthorisationtLabels = {
  cwbTitle: 'Cloud Work Book',
  cwbSubtitle:
    'Cloud Workbook is a framework developed by Deloitte’s cloud practitioners to help clients quickly acquire meaningful and actionable insights to determine their right path to cloud, assess the total cost of ownership, and optimize the organizational structure to get the highest value from cloud investments',
  cwbPopupWindowName: 'Authentication CWB',
  cmsPopupWindowName: 'Authentication CMS',
  assessPopupWindowName: 'Authentication Assess',
};

export const AssetAuthorisationModalLabels = {
  assessSuccess : 'Assess Authorized Successfully',
  assessFailed: 'Assess Authorization failed',
  cwbSuccess: 'Cloud Work Book Authorized Successfully',
  cwbFailed: 'Cloud Work Book Authorization failed',
  cmsSuccess: 'Open Cloud Authorized Successfully',
  cmsFailed: 'Open Cloud Authorization failed',
  ok: 'OK',
};
export enum AssestStatus {
  CONFIGURE = 'configure',
  SUCCESS = 'success',
  EXPIRED = 'expired',
}
export const cmsAuthorisationState = {
  title: 'Open Cloud',
  subtitle:
    'Cloud Managed Services can help Deloitte clients by simplifying the management of existing and new cloud workloads',
  icon: `${process.env.PUBLIC_URL}/icons/cloud-asset3.svg`,
  assetStatus: AssestStatus.SUCCESS,
};

export const cb360AuthorisationState = {
  title: 'CB 360',
  subtitle:
    'Cloud Managed Services can help Deloitte clients by simplifying the management of existing and new cloud workloads',
  icon: `${process.env.PUBLIC_URL}/icons/cloud-asset3.svg`,
  assetStatus: AssestStatus.SUCCESS,
};

export const AssetAuthorisationState = [
  {
    title: 'Deloitte Cloud Accelerator',
    subtitle:
      'DCA enables the creation of environments to support the application development lifecycle. It helps deploy cloud resources required for the application ensuring the resources follow best practices and security guidelines. It provides a one click deployment of the Cloud Environment using Infrastructure as Code (IaC) capabilities',
    icon: `${process.env.PUBLIC_URL}/icons/cloud-asset2.svg`,
    assetStatus: AssestStatus.CONFIGURE,
  },

  {
    title: 'DevOps Cloud Platform',
    subtitle:
      'DCP is an AllOps platform that automates continuous improvement throughout the development cycle, offers real-time insights into DevSecOps pipelines, and executes compliance rules on cloud resources',
    icon: `${process.env.PUBLIC_URL}/icons/cloud-asset4.svg`,
    assetStatus: AssestStatus.CONFIGURE,
  },
  {
    title: 'Cloud Billing 360',
    subtitle:
      'Cloud Billing 360 provides information on spending on the cloud resources provisioned. It Provides the cost summary, credit summary, cost optimization summary and carbon footprint. We can create budgets, alerts as well as view the cost allocations.',
    icon: `${process.env.PUBLIC_URL}/icons/cb360.png`,
    assetStatus: AssestStatus.SUCCESS,
  },
  {
    title: 'COFTA',
    subtitle:
      'Cognitive Functional Test Automation (COFTA) is an AI-enabled solution complements functional testing by converting test cases written in plain English to auto.',
    icon: `${process.env.PUBLIC_URL}/icons/testing-icon-1.svg`,
    assetStatus: AssestStatus.SUCCESS,
  },
];

export const POPUP_WINDOW_CONFIG =
  'left=350,top=150,width=600,height=350,resizable=no,toolbar=no,menubar=no,location=no,status=no,popup=yes';
