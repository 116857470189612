import { Reducer } from 'redux';
import ClientEngagementManagementState from "../../state/cbo/ClientEngagementManagementState";
import { IClientResponse, IEngagementResponse } from '../../../models/cbo/ClientEngagementManagementModel';
import { ClientEngagementManagementAction, ClientEngagementActionTypes } from '../../actions/cbo/ClientEngagementManagementAction';


const defaultClientsData: IClientResponse[] = [{
    clientId:'',
    clientName:'',
    clientLogo:''
  }];

  const defaultEngagementsData: IEngagementResponse[] = [{
    engagementId : '',
    engagementName : '',
    capabilities: [''],
    createdOn: '',
    status: '',
    clientId:'',
    userId:''
  }];

  export const initialState: ClientEngagementManagementState = {
    clientList: defaultClientsData,
    EngagementList : defaultEngagementsData,
    loading: false,
    error: ''
  };


export const ClientEngagementManagementReducer:  Reducer<ClientEngagementManagementState> = (
  state = initialState,
  action: ClientEngagementManagementAction
) =>  {
  switch (action.type) {
  case ClientEngagementActionTypes.GET_CLIENTS_DATA_STARTED:
    return {
      ...state,
      loading: true,
      error: undefined,
      clientList: defaultClientsData ,
    };
  case ClientEngagementActionTypes.GET_CLIENTS_DATA_SUCCESS:
    return {
      ...state,
      loading: false,
      error: undefined,
      clientList: action.data as IClientResponse[],
    };
  case ClientEngagementActionTypes.GET_CLIENTS_DATA_ERROR:
    return {
      ...state,
      loading: false,
      error: action.data
    };

    case ClientEngagementActionTypes.GET_CLIENTS_NAME_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        clientList: defaultClientsData ,
      };
    case ClientEngagementActionTypes.GET_CLIENTS_NAME_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        clientList: action.data as IClientResponse[],
      };
    case ClientEngagementActionTypes.GET_CLIENTS_NAME_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    case ClientEngagementActionTypes.GET_ENGAGEMENTS_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        EngagementList: defaultEngagementsData,
      };
    case ClientEngagementActionTypes.GET_ENGAGEMENTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        EngagementList: action.data as IEngagementResponse[],
      };
    case ClientEngagementActionTypes.GET_ENGAGEMENTS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data
      };

      case ClientEngagementActionTypes.ADD_ENGAGEMENT_DATA_STARTED:
        return {
          ...state,
          loading: true,
          error: undefined
        };
      case ClientEngagementActionTypes.ADD_ENGAGEMENT_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          error: undefined,
        };
      case ClientEngagementActionTypes.ADD_ENGAGEMENT_DATA_ERROR:
        return {
          ...state,
          loading: false,
          error: action.data,
        };

        case ClientEngagementActionTypes.UPDATE_ENGAGEMENT_DATA_STARTED:
        return {
          ...state,
          loading: true,
          error: undefined
        };
      case ClientEngagementActionTypes.UPDATE_ENGAGEMENT_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          error: undefined,
        };
      case ClientEngagementActionTypes.UPDATE_ENGAGEMENT_DATA_ERROR:
        return {
          ...state,
          loading: false,
          error: action.data,
        };

        case ClientEngagementActionTypes.ADD_CLIENT_DATA_STARTED:
          return {
            ...state,
            loading: true,
            error: undefined
          };
        case ClientEngagementActionTypes.ADD_CLIENT_DATA_SUCCESS:
          return {
            ...state,
            loading: false,
            error: undefined,
          };
        case ClientEngagementActionTypes.ADD_CLIENT_DATA_ERROR:
          return {
            ...state,
            loading: false,
            error: action.data,
          };
  
          case ClientEngagementActionTypes.UPDATE_CLIENT_DATA_STARTED:
          return {
            ...state,
            loading: true,
            error: undefined
          };
        case ClientEngagementActionTypes.UPDATE_CLIENT_DATA_SUCCESS:
          return {
            ...state,
            loading: false,
            error: undefined,
          };
        case ClientEngagementActionTypes.UPDATE_CLIENT_DATA_ERROR:
          return {
            ...state,
            loading: false,
            error: action.data,
          };

          case ClientEngagementActionTypes.DELETE_CLIENT_DATA_STARTED:
            return {
              ...state,
              loading : true
            };
          case ClientEngagementActionTypes.DELETE_CLIENT_DATA_SUCCESS:
            return {
                ...state,
                loading : false
            };
           case ClientEngagementActionTypes.DELETE_CLIENT_DATA_ERROR:
            return {
                ...state,
                loading : false,
                error: action.data as Error,
            };
            case ClientEngagementActionTypes.DELETE_ENGAGEMENT_DATA_STARTED:
            return {
              ...state,
              loading : true
            };
          case ClientEngagementActionTypes.DELETE_ENGAGEMENT_DATA_SUCCESS:
            return {
                ...state,
                loading : false
            };
           case ClientEngagementActionTypes.DELETE_ENGAGEMENT_DATA_ERROR:
            return {
                ...state,
                loading : false,
                error: action.data as Error,
            }
  
  default:
    return state;
}
}


