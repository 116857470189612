import { put, takeEvery } from 'redux-saga/effects';
import HitsAnalyticsService from '../../../services/TrafficAnalyticsService/HitAnalyticsService';
import {
  HitsAnalyticsAction,
  HitsAnalyticsActions,
  HitsAnalyticsActionTypes,
  IActiveUsersPayload,
  IAnalyticsAreaChartResponse,
  IAnalyticsPieChartResponse,
} from '../../actions/WebAnalytics/HitsAnalyticsActions';

export function* getNewVsReturningUserWorker(action: HitsAnalyticsAction) {
  try {
    const response: IAnalyticsPieChartResponse[] =
      (yield HitsAnalyticsService.getUserVsReturningUsers())!;
    yield put(HitsAnalyticsActions.getNewVsReturningUsersSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(HitsAnalyticsActions.getNewVsReturningUsersError(error));
    }
  }
}
export function* getActiveUsersDataWorker(action: HitsAnalyticsAction) {
  try {
    const data = action.data as IActiveUsersPayload;
    const response: IAnalyticsAreaChartResponse =
      (yield HitsAnalyticsService.getActiveUsersData(data))!;
    yield put(HitsAnalyticsActions.getActiveUserDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(HitsAnalyticsActions.getActiveUserDataError(error));
    }
  }
}

export function* getUserVisitsDataWorker(action: HitsAnalyticsAction) {
  try {
    const response: IAnalyticsAreaChartResponse = (yield HitsAnalyticsService.getUserVisitsData())!;
    yield put(HitsAnalyticsActions.getUserVisitsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(HitsAnalyticsActions.getUserVisitsDataError(error));
    }
  }
}

export function* getPageVisitsDataWorker(action: HitsAnalyticsAction) {
  try {
    const response: IAnalyticsAreaChartResponse = (yield HitsAnalyticsService.getPageViewsData())!;
    yield put(HitsAnalyticsActions.getPageViewsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(HitsAnalyticsActions.getPageViewsDataError(error));
    }
  }
}

export function* getBounceRateData(action: HitsAnalyticsAction) {
  try {
    const response: IAnalyticsAreaChartResponse = (yield HitsAnalyticsService.getBounceRateData())!;
    yield put(HitsAnalyticsActions.getBounceRateDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(HitsAnalyticsActions.getBounceRateDataError(error));
    }
  }
}

export function* hitsAnalyticsWatcher() {
  yield takeEvery(
    HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_START,
    getNewVsReturningUserWorker,
  );
  // yield takeEvery(HitsAnalyticsActionTypes.GET_CLOUD_STATUS_DATA_START, getCloudStatusData);
  yield takeEvery(HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_START, getActiveUsersDataWorker);
  yield takeEvery(HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_START, getUserVisitsDataWorker);
  yield takeEvery(HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_START, getPageVisitsDataWorker);
  yield takeEvery(HitsAnalyticsActionTypes.GET_BOUNCE_RATE_DATA_START, getBounceRateData);
}
