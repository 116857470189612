/**
 * This file contains the HomePage component, which serves as the main page of the application.
 * It renders the layout, header, sidebar, and content of the home page.
 * The HomePage component also handles scrolling and displays a product tour if it is the user's first login.
 *
 * Example usage:
 * ```
 * <HomePage>
 *   // Content goes here
 * </HomePage>
 * ```
 */

import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import CBOAppHeader from '../CBOAppHeader/CBOAppHeader';
import CBOSidebar from '../CBOSidebar/CBOSidebar';
import ProductTour from '../ProductTour/ProductTour';
import './HomePageStyles.less';

const { Content } = Layout;

interface IHomePageProps {
  children: ReactNode;
}

/**
 * The HomePage component renders the layout, header, sidebar, and content of the home page.
 * It also handles scrolling and displays a product tour if it is the user's first login.
 */
const HomePage = ({ children }: IHomePageProps) => {
  const startProductTour = JSON.parse(
    storage.getItem(STORAGE_CONSTANTS.first_login_response) ?? 'false',
  ) as boolean;
  return (
    <>
      <Layout>
        <CBOSidebar />
        {
          <Layout className="main-content-container">
            <CBOAppHeader />
            <Content>{children}</Content>
          </Layout>
        }
      </Layout>
      {startProductTour && <ProductTour startTour={startProductTour} />}
    </>
  );
};

export default HomePage;
