import {
  IHeatmapPayload,
  IHeatmapResponse,
} from '../../models/CloudSuitabilityModels';
import { IStoreAction } from '../StoreHelper';

export enum CloudSuitabilityActionTypes {
  GET_HEATMAP_DATA_STARTED = 'GET_HEATMAP_DATA_STARTED',
  GET_HEATMAP_DATA_SUCCESS = 'GET_HEATMAP_DATA_SUCCESS',
  GET_HEATMAP_DATA_ERROR = 'GET_HEATMAP_DATA_ERROR',
  GET_APP_SCOPE_ADJUSTMENT_STARTED = 'GET_APP_SCOPE_ADJUSTMENT_STARTED',
  GET_APP_SCOPE_ADJUSTMENT_SUCCESS = 'GET_APP_SCOPE_ADJUSTMENT_SUCCESS',
  GET_APP_SCOPE_ADJUSTMENT_ERROR = 'GET_APP_SCOPE_ADJUSTMENT_ERROR',
  GET_APP_INSCOPE_STARTED = 'GET_APP_INSCOPE_STARTED',
  GET_APP_INSCOPE_SUCCESS = 'GET_APP_INSCOPE_SUCCESS',
  GET_APP_INSCOPE_ERROR = 'GET_APP_INSCOPE_ERROR',
  GET_APP_ADJUSTMENT_COUNT_STARTED = 'GET_APP_ADJUSTMENT_COUNT_STARTED',
  GET_APP_ADJUSTMENT_COUNT_SUCCESS = 'GET_APP_ADJUSTMENT_COUNT_SUCCESS',
  GET_APP_ADJUSTMENT_COUNT_ERROR = 'GET_APP_ADJUSTMENT_COUNT_ERROR',
  
  RESET_CLOUD_SUITABILITY_STATE='RESET_CLOUD_SUITABILITY_STATE'
}

export type CloudSuitabilityActionPayload =
  | Error
  | null
  | IHeatmapPayload
  | IHeatmapResponse

export type CloudSuitabilityAction = IStoreAction<
  CloudSuitabilityActionTypes,
  CloudSuitabilityActionPayload
>;

export class CloudSuitabilityActions {
  public static getHeatmapDataStart(data: IHeatmapPayload): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_HEATMAP_DATA_STARTED,
      data,
    };
  }
  public static getHeatmapDataSuccess(data: IHeatmapResponse): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_HEATMAP_DATA_SUCCESS,
      data,
    };
  }
  public static getHeatmapDataError(error: Error): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_HEATMAP_DATA_ERROR,
      data: error,
    };
  }
  public static resetCloudSuitabilityStateData(): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.RESET_CLOUD_SUITABILITY_STATE
    };
  }
}
