import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import { IEngagementResponse } from '../../../models/cbo/ClientEngagementManagementModel';
import {
  ICboUserManagementObject,
  ICreateUserRequestBody,
  IClientListMapping,
  IEditUserRequestBody,
  IShowUserClientPayload,
} from '../../../models/cbo/UserManagementModel';
import UserManagementService from '../../../services/cbo/UserManagementService/UserManagementService';
import {
  UserManagementAction,
  UserManagementActions,
  UserManagementActionTypes,
} from '../../actions/cbo/UserManagementAction';
import { ToastActions } from '../../actions/ToastActions';
import { IEngagementListForAdminRequestPayload } from '../../../models/cbo/EngagementModels';
import { STORAGE_CONSTANTS, storage } from '../../../services/LocalStorage';

export function* getUserListWorker(action: UserManagementAction) {
  try {
    const response: ICboUserManagementObject = yield UserManagementService.getUserList(
      action.data as IEngagementListForAdminRequestPayload,
    );
    yield put(UserManagementActions.getUserListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(UserManagementActions.getUserListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getClientUserWorker(action: UserManagementAction) {
  try {
    const data = action.data as string;
    const response: IEngagementResponse[] = yield UserManagementService.getClientUserMapping(data);
    yield put(UserManagementActions.getClientUserSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(UserManagementActions.getClientUserError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getClientEngListWorker() {
  try {
    const response: IClientListMapping[] = yield UserManagementService.getClientEngList();
    yield put(UserManagementActions.getClientEngListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(UserManagementActions.getClientEngListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* addUserDataWorker(action: UserManagementAction) {
  try {
    const { data } = action;
    yield UserManagementService.addUser(data as ICreateUserRequestBody);
    yield put(UserManagementActions.addUserSuccess());
    yield put(
      ToastActions.showToast({
        description: 'User added successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      yield put(UserManagementActions.getUserListStart({ limit: 10, page: 1 }));
    } catch (error) {
      if (error instanceof Error) {
        yield put(UserManagementActions.getUserListError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(UserManagementActions.addUserError(error));
    }
  }
}

export function* editUserStatusDataWorker(action: UserManagementAction) {
  try {
    const { data } = action;
    yield UserManagementService.editUserStatus(data as ICboUserManagementObject);
    yield put(UserManagementActions.addUserSuccess());
    yield put(
      ToastActions.showToast({
        description: 'User Status Changed successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      yield put(UserManagementActions.getUserListStart({ limit: 10, page: 1 }));
    } catch (error) {
      if (error instanceof Error) {
        yield put(UserManagementActions.getUserListError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(UserManagementActions.addUserError(error));
    }
  }
}

export function* updateUserDataWorker(action: UserManagementAction) {
  try {
    const { data } = action;
    yield UserManagementService.editUser(data as IEditUserRequestBody);
    yield put(UserManagementActions.editUserSuccess());
    yield put(
      ToastActions.showToast({
        description: 'User edited successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      yield put(UserManagementActions.getUserListStart({ limit: 10, page: 1 }));
    } catch (error) {
      if (error instanceof Error) {
        yield put(UserManagementActions.getUserListError(error));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(UserManagementActions.editUserError(error));
    }
  }
}

export function* getRoleMetaDataWorker() {
  try {
    const response: string[] = yield UserManagementService.getRolesMetadata();
    yield put(UserManagementActions.getRoleMetaDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(UserManagementActions.getRoleMetaDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}
// @CBO-2834_AI_AUTO_NEW_CODE:generated saga function for show user's client
export function* showUserClientDataWorker(action: UserManagementAction) {
  try {
    const payload = action?.data as IShowUserClientPayload;
    const userId = storage.getItem(STORAGE_CONSTANTS.userId) ?? '';
    const response: string = yield UserManagementService.showUserClientService(payload);
    yield put(UserManagementActions.showUserClientSuccess());
    yield put(
      ToastActions.showToast({
        description: 'Client-User Mapping Updated Successfully!!!',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      yield put(UserManagementActions.getClientUserStart(userId));
    } catch (error) {
      if (error instanceof Error) {
        yield put(UserManagementActions.getClientUserError(error));
        yield put(
          ToastActions.showToast({
            message: 'Error!!!',
            description: error.message,
            type: NotificationTypes.ERROR,
          }),
        );
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(UserManagementActions.showUserClientError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* cboUserManagementWatcher() {
  yield takeEvery(UserManagementActionTypes.GET_USER_LIST_STARTED, getUserListWorker);
  yield takeEvery(UserManagementActionTypes.GET_CLIENT_ENG_LIST_STARTED, getClientEngListWorker);
  yield takeEvery(UserManagementActionTypes.ADD_USER_DATA_STARTED, addUserDataWorker);
  yield takeEvery(UserManagementActionTypes.EDIT_USER_STATUS_STARTED, editUserStatusDataWorker);
  yield takeEvery(UserManagementActionTypes.UPDATE_USER_DATA_STARTED, updateUserDataWorker);
  yield takeEvery(UserManagementActionTypes.GET_ROLE_METADATA_STARTED, getRoleMetaDataWorker);
  yield takeEvery(UserManagementActionTypes.GET_CLIENT_USER_STARTED, getClientUserWorker);
  yield takeEvery(UserManagementActionTypes.SHOW_USER_CLIENT_STARTED, showUserClientDataWorker);
}
