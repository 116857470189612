/* eslint-disable max-lines */
import { CloudServiceProvider } from '../../../../AppConstants';
import {
  IAnalyticsUserListResponse,
  IEngagementKeyAssetsData,
  IPursuitAnalyticsResponse,
  IPursuitEngagementResponse,
  IUserActivityListResponse,
  IUserAnalyticsPayload,
} from '../../../models/cbo/cboWebAnalyticsModel';
import { IStoreAction } from '../../StoreHelper';

export enum TrafficAnalyticsActionTypes {
  POST_VISITED_PAGE_ACTION_START = 'POST_VISITED_PAGE_ACTION_START',
  POST_VISITED_PAGE_ACTION_SUCCESS = 'POST_VISITED_PAGE_ACTION_SUCCESS',
  POST_VISITED_PAGE_ACTION_ERROR = 'POST_VISITED_PAGE_ACTION_ERROR',

  GET_MOST_VISITED_CAPABILITIES_START = 'GET_MOST_VISITED_CAPABILITIES_START',
  GET_MOST_VISITED_CAPABILITIES_SUCCESS = 'GET_MOST_VISITED_CAPABILITIES_SUCCESS',
  GET_MOST_VISITED_CAPABILITIES_ERROR = 'GET_MOST_VISITED_CAPABILITIES_ERROR',

  GET_CAPABILITIES_BY_PHASE_START = 'GET_CAPABILITIES_BY_PHASE_START',
  GET_CAPABILITIES_BY_PHASE_SUCCESS = 'GET_CAPABILITIES_BY_PHASE_SUCCESS',
  GET_CAPABILITIES_BY_PHASE_ERROR = 'GET_CAPABILITIES_BY_PHASE_ERROR',

  GET_KEY_ASSETS_COUNT_START = 'GET_KEY_ASSETS_COUNT_START',
  GET_KEY_ASSETS_COUNT_SUCCESS = 'GET_KEY_ASSETS_COUNT_SUCCESS',
  GET_KEY_ASSETS_COUNT_ERROR = 'GET_KEY_ASSETS_COUNT_ERROR',

  GET_PURSUIT_ENGAGEMENT_START = 'GET_PURSUIT_ENGAGEMENT_START',
  GET_PURSUIT_ENGAGEMENT_ERROR = 'GET_PURSUIT_ENGAGEMENT_ERROR',
  GET_PURSUIT_ENGAGEMENT_SUCCESS = 'GET_PURSUIT_ENGAGEMENT_SUCCESS',

  RESET_ANALYTICS_DATA = 'RESET_ANALYTICS_DATA',

  //CBO-3309:generated action types for user activity and user list
  GET_ANALYTICS_USER_LIST_START = 'GET_ANALYTICS_USER_LIST_START',
  GET_ANALYTICS_USER_LIST_SUCCESS = 'GET_ANALYTICS_USER_LIST_SUCCESS',
  GET_ANALYTICS_USER_LIST_ERROR = 'GET_ANALYTICS_USER_LIST_ERROR',
  GET_USER_ACTIVITY_START = 'GET_USER_ACTIVITY_START',
  GET_USER_ACTIVITY_SUCCESS = 'GET_USER_ACTIVITY_SUCCESS',
  GET_USER_ACTIVITY_ERROR = 'GET_USER_ACTIVITY_ERROR',
  GET_ACTIVE_PURSUITS_ANALYTICS_START = 'GET_ACTIVE_PURSUITS_ANALYTICS_START',
  GET_ACTIVE_PURSUITS_ANALYTICS_SUCCESS = 'GET_ACTIVE_PURSUITS_ANALYTICS_SUCCESS',
  GET_ACTIVE_PURSUITS_ANALYTICS_ERROR = 'GET_ACTIVE_PURSUITS_ANALYTICS_ERROR',
}

export interface IMostVisitedSectionPayload {
  startDate: string;
}
export interface IPursuitVsEngageemntPayload {
  category: string;
}

export interface IMostVisitedArchPayload {
  architectureType: string;
}

export enum SectionType {
  ASSET_AUTHORIZATION = 'assetAuthorization',
  VIEW_SUMMARY = 'viewSummary',
  USER_MANAGEMENT = 'userManagement',
  CLIENT_ENGAGEMENT_MANAGEMENT = 'clientEngagementManagement',
  TRUNORTH = 'TruNorth',
  APIVAULT = 'Api Vault',
  CB360 = 'CB360',
  CMS = 'Cloud Managed Services',
  COFTA = 'COFTA',
  CWB = 'Cloud Work Book',
  DBCUTTER = 'DB Cutter',
  DCA = 'DCA',
  DCP = 'DCP',
  INNOWAKE = 'Innowake Discovery',
  MONOLITHIC = 'Monolithic Cutter',
  OPERATEEDGE = 'Operate Edge',
  TURBOCODE = 'Turbo Code',
  TALKQE = 'TalkQE',
  DIGITALTESTER = 'Digital Tester',
  PROCESSBIONICS = 'Process Bionics',
  DEP = 'DEP',
  ENGAGEMENTMARKETPLACE = 'engagementMarketplace',
  PURSUITMARKETPLACE = 'pursuitMarketplace',
  CBOMARKETPLACESEARCH = 'cboMarketplaceSearch',
  ENG_DASHBOARD = 'engagementDashboard',
  PURSUIT_DASHBOARD = 'pursuitDashboard',
  ADDPURSUIT = 'addPursuit',
  EDITPURSUIT = 'editPursuit',
  DELETEPURSUIT = 'deletePursuit',
  DELETEENGAGEMENT = 'deleteEngagement',
  EDITENGAGEMENT = 'editEngagement',
  CAPABILITYMANAGEMENT = 'capabilityManagement',
  SAVEPURSUIT = 'savePursuit',
  PURSUIT_STATUS = 'updatedPursuitStatus',
  SAVE_PURSUIT_CONFIG = 'savePursuitConfig',
  SAVE_ENGAGEMENT_CONFIG = 'saveEngagementConfig',
  SAVE_ENG_MARKETPLACE_CONFIG = 'saveEngagementMarketplaceConfig',
  SAVE_PURSUIT_MARKETPLACE_CONFIG = 'savePursuitMarketplaceConfig',
  GETENGCONFIGMARKETPLACE = 'getEngConfigMarketplace',
  GETPURSUITCONFIGMARKETPLACE = 'getPursuitConfigMarketplace',
  SAVEPURSUITCONFIGMARKETPLACE = 'savePursuitConfigMarketplace', //CONFIGURE MARKETPLACE
  SAVEENGCONFIGMARKETPLACE = 'saveEngConfigMarketplace', //CONFIGURE MARKETPLACE
  VIEWPURSUITSUMMARY = 'viewPursuitSummary',
  VIEWENGAGEMENTSUMMARY = 'viewEngagementSummary',
  GETPURSUITASSETCONFIG = 'getPursuitAssetConfig',
  GETENGAGEMENTASSETCONFIG = 'getEngagementAssetConfig',
  SAVEPURSUITASSETCONFIG = 'savePursuitAssetConfig',
  SAVEENGAGEMENTASSETCONFIG = 'saveEngagementAssetConfig',
  PURSUIT_ASSET = 'pursuit-asset',
  ENG_ASSET = 'engagement-asset',
  ASSET = 'asset',
  LOGIN = 'Login',
}

export enum PageType {
  ENG_DASHBOARD = 'engagementDashboard',
  PURSUIT_DASHBOARD = 'pursuitDashboard',
  CAPABILITY_MANAGEMENT = 'capabilityManagement',
  CLIENT_ENGAGEMENT_MANAGEMENT = 'clientEngagementManagement',
  GETENGAGEMENTASSETCONFIG = 'getEngagementAssetConfig',
  GETPURSUITASSETCONFIG = 'getPursuitAssetConfig',
  VIEWENGAGEMENTSUMMARY = 'viewEngagementSummary',
  VIEWPURSUITSUMMARY = 'viewPursuitSummary',
  CBOMARKETPLACESEARCH = 'cboMarketplaceSearch',
  USER_MANAGEMENT = 'userManagement',
}

export interface ICapabilitiesByPhaseResponse {
  phase: string;
  percentage: number;
}

export interface IVisitedPagePayload {
  sectionType: SectionType | string;
  pageType?: string;
  activityId?: string;
  name?: string;
  id?: string;
  assetName?: string;
  user_name?: string;
  clientId?: string;
  clientName?: string;
}

export interface ICapabilitiesCount {
  totalCount: string;
  capabilityName: string;
}
export interface IMostVisitedCapabilitiesResponse {
  phase: string;
  capabilities: ICapabilitiesCount[];
}
export type TrafficAnalyticsActionPayload =
  | Error
  | string
  | IVisitedPagePayload
  | IMostVisitedCapabilitiesResponse[]
  | ICapabilitiesByPhaseResponse[]
  | IEngagementKeyAssetsData[]
  | IPursuitEngagementResponse
  | IPursuitVsEngageemntPayload
  | IUserAnalyticsPayload
  | IUserActivityListResponse
  | IAnalyticsUserListResponse
  | IPursuitAnalyticsResponse;

export type TrafficAnalyticsAction = IStoreAction<
  TrafficAnalyticsActionTypes,
  TrafficAnalyticsActionPayload
>;

export class TrafficAnalyticsActions {
  public static postVisitedPageStart(data: IVisitedPagePayload): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_START,
      data,
    };
  }
  public static postVisitedPageSuccess(): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_SUCCESS,
    };
  }
  public static postVisitedPageError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_ERROR,
      data: error,
    };
  }

  public static getMostVisitedCapabilitiesStart(data: string): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_CAPABILITIES_START,
      data,
    };
  }
  public static getMostVisitedCapabilitiesSuccess(
    data: IMostVisitedCapabilitiesResponse[],
  ): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_CAPABILITIES_SUCCESS,
      data,
    };
  }
  public static getMostVisitedCapabilitiesError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_CAPABILITIES_ERROR,
      data: error,
    };
  }

  public static getCapabilitiesByPhaseStart(data: string): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_CAPABILITIES_BY_PHASE_START,
      data,
    };
  }
  public static getCapabilitiesByPhaseSuccess(
    data: ICapabilitiesByPhaseResponse[],
  ): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_CAPABILITIES_BY_PHASE_SUCCESS,
      data,
    };
  }
  public static getCapabilitiesByPhasError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_CAPABILITIES_BY_PHASE_ERROR,
      data: error,
    };
  }

  public static getKeyAssetsCountStart(data: string): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_KEY_ASSETS_COUNT_START,
      data,
    };
  }
  public static getKeyAssetsCountSuccess(data: IEngagementKeyAssetsData[]): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_KEY_ASSETS_COUNT_SUCCESS,
      data,
    };
  }
  public static getKeyAssetsCountError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_KEY_ASSETS_COUNT_ERROR,
      data: error,
    };
  }

  public static getPursuitEngagementsDataStart(
    data: IPursuitVsEngageemntPayload,
  ): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_PURSUIT_ENGAGEMENT_START,
      data,
    };
  }
  public static getPursuitEngagementsDataSuccess(
    data: IPursuitEngagementResponse,
  ): TrafficAnalyticsAction {
    return { type: TrafficAnalyticsActionTypes.GET_PURSUIT_ENGAGEMENT_SUCCESS, data };
  }
  public static getPursuitEngagementsDataError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_PURSUIT_ENGAGEMENT_ERROR,
      data: error,
    };
  }
  public static resetAnalyticsData(): TrafficAnalyticsAction {
    return { type: TrafficAnalyticsActionTypes.RESET_ANALYTICS_DATA };
  }

  //CBO-3309:generated actions for user activity and user list
  public static getAnalyticsUserListStart(data: IUserAnalyticsPayload): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_ANALYTICS_USER_LIST_START,
      data,
    };
  }
  public static getAnalyticsUserListSuccess(
    data: IAnalyticsUserListResponse,
  ): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_ANALYTICS_USER_LIST_SUCCESS,
      data,
    };
  }
  public static getAnalyticsUserListError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_ANALYTICS_USER_LIST_ERROR,
      data: error,
    };
  }

  public static getUserActivityStart(data: IUserAnalyticsPayload): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_USER_ACTIVITY_START,
      data,
    };
  }
  public static getUserActivitySuccess(data: IUserActivityListResponse): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_USER_ACTIVITY_SUCCESS,
      data,
    };
  }
  public static getUserActivityError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_USER_ACTIVITY_ERROR,
      data: error,
    };
  }
  public static getActivePursuitsAnalyticsStart(
    data: IUserAnalyticsPayload,
  ): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_ACTIVE_PURSUITS_ANALYTICS_START,
      data,
    };
  }
  public static getActivePursuitsAnalyticsSuccess(
    data: IPursuitAnalyticsResponse,
  ): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_ACTIVE_PURSUITS_ANALYTICS_SUCCESS,
      data,
    };
  }
  public static getActivePursuitsAnalyticsError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_ACTIVE_PURSUITS_ANALYTICS_ERROR,
      data: error,
    };
  }
}
