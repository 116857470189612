import {
  ICboLoginResponse,
  IChangePasswordPayload,
  ICustomLoginPayload,
  ICustomLoginResponse,
  ILoginPayload,
  ILoginResponse,
  ITangraLoginPayload,
  ITangraLoginResponse,
} from '../../models/LoginModels';
import {  API_ROUTE_CBO } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { storage, STORAGE_CONSTANTS } from '../LocalStorage';
import { ServerType, ServiceType } from '../ServiceType.data';

const tangraServiceType = ServiceType.None;
const sessionServiceType = ServiceType.ANALYTICS;

export class LoginService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);

  private readonly tangraService: ApiServiceBase = new ApiService(
    tangraServiceType,
    ServerType.tangra,
  );

  private readonly sessionService: ApiServiceBase = new ApiService(
    sessionServiceType,
    ServerType.tangra,
  );

  public postTangraLogin(
    payload: ITangraLoginPayload,
  ): Promise<ICboLoginResponse> | ICboLoginResponse {
    return this.cboService.post({ route: [API_ROUTE_CBO.CBO_LOGIN] }, payload);
  }

  public getSessionToken(): Promise<string> | string {
    return this.cboService.post({ route: [API_ROUTE_CBO.GET_USER_ACTIVITY_DATA] }, {});
  }

  public postSessionToken(): Promise<string> | string {
    const sessionToken = storage.getItem(STORAGE_CONSTANTS.sessionToken);
    return this.sessionService.patch(
      { route: [API_ROUTE_CBO.GET_SESSION_TOKEN_DATA] },
      { Id: sessionToken },
    );
  }

  public postCustomLogin(
    payload: ICustomLoginPayload,
  ): Promise<ICustomLoginResponse> | ICustomLoginResponse {
    return this.cboService.post({ route: [API_ROUTE_CBO.POST_CUSTOM_LOGIN] }, payload);
  }

  public postChangePassword(
    payload: IChangePasswordPayload,
  ): Promise<ICustomLoginResponse> | ICustomLoginResponse {
    return this.cboService.post({ route: [API_ROUTE_CBO.POST_CHANGE_PASSWORD] }, payload);
  }
}

const loginService = new LoginService();
export default loginService;
