import {
  IJourneyCapabilitiesData,
  IJourneyCapabilitiesResponse,
} from '../../../models/cbo/EngagementModels';
import {
  IJourneyBuilderPayload,
  IJourneyList,
  IJourneyMarketplacePayload,
  IMarketplaceAssets,
  IPredefinedJourneyListMapping,
  ISubmitStatuspayload,
  IJourneyCapabilityListPayload,
  IRecommendedAssetsResponse,
  IJourneySaveResponse,
  IJourneyMapping,
} from '../../../models/cbo/JourneyBuilderModel';
import { IStoreAction } from '../../StoreHelper';

export enum JourneyBuilderActionTypes {
  GET_JOURNEY_LIST_DATA_STARTED = 'GET_JOURNEY_LIST_DATA_STARTED',
  GET_JOURNEY_LIST_DATA_SUCCESS = 'GET_JOURNEY_LIST_DATA_SUCCESS',
  GET_JOURNEY_LIST_DATA_ERROR = 'GET_JOURNEY_LIST_DATA_ERROR',
  GET_PREDEFINED_JOURNEY_LIST_STARTED = 'GET_PREDEFINED_JOURNEY_LIST_STARTED',
  GET_PREDEFINED_JOURNEY_LIST_SUCCESS = 'GET_PREDEFINED_JOURNEY_LIST_SUCCESS',
  GET_PREDEFINED_JOURNEY_LIST_ERROR = 'GET_PREDEFINED_JOURNEY_LIST_ERROR',
  GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_STARTED = 'GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_STARTED',
  GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_SUCCESS = 'GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_SUCCESS',
  GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_ERROR = 'GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_ERROR',
  GET_JOURNEY_MANDATORY_ASSETS_STARTED = ' GET_JOURNEY_MANDATORY_ASSETS_STARTED',
  GET_JOURNEY_MANDATORY_ASSETS_SUCCESS = ' GET_JOURNEY_MANDATORY_ASSETS_SUCCESS',
  GET_JOURNEY_MANDATORY_ASSETS_ERROR = ' GET_JOURNEY_MANDATORY_ASSETS_ERROR',
  POST_JOURNEY_BUILDER_SAVE_STARTED = 'POST_JOURNEY_BUILDER_SAVE_STARTED',
  POST_JOURNEY_BUILDER_SAVE_SUCCESS = 'POST_JOURNEY_BUILDER_SAVE_ERROR',
  POST_JOURNEY_BUILDER_SAVE_ERROR = 'POST_JOURNEY_BUILDER_SAVE_ERROR',
  PATCH_JOURNEY_BUILDER_UPDATE_STARTED = 'PATCH_JOURNEY_BUILDER_UPDATE_STARTED',
  PATCH_JOURNEY_BUILDER_UPDATE_SUCCESS = 'PATCH_JOURNEY_BUILDER_UPDATE_ERROR',
  PATCH_JOURNEY_BUILDER_UPDATE_ERROR = 'PATCH_JOURNEY_BUILDER_UPDATE_ERROR',
  DELETE_DOCUMENT_DATA_STARTED = 'DELETE_DOCUMENT_DATA_STARTED',
  DELETE_DOCUMENT_DATA_SUCCESS = 'DELETE_DOCUMENT_DATA_SUCCESS',
  DELETE_DOCUMENT_DATA_ERROR = 'DELETE_DOCUMENT_DATA_ERROR',
  POST_SUBMIT_STATUS_STARTED = 'POST_SUBMIT_STATUS_STARTED',
  POST_SUBMIT_STATUS_SUCCESS = 'POST_SUBMIT_STATUS_SUCCESS',
  POST_SUBMIT_STATUS_ERROR = 'POST_SUBMIT_STATUS_ERROR',
  GET_JOURNEY_RECOMMENDED_ASSETS_STARTED = 'GET_JOURNEY_RECOMMENDED_ASSETS_STARTED',
  GET_JOURNEY_RECOMMENDED_ASSETS_SUCCESS = 'GET_JOURNEY_RECOMMENDED_ASSETS_SUCCESS',
  GET_JOURNEY_RECOMMENDED_ASSETS_ERROR = 'GET_JOURNEY_RECOMMENDED_ASSETS_ERROR',

  GET_BUSINESS_OBJECTIVES__STARTED = 'GET_BUSINESS_OBJECTIVES__STARTED',
  GET_BUSINESS_OBJECTIVES_SUCCESS = 'GET_BUSINESS_OBJECTIVES__SUCCESS',
  GET_BUSINESS_OBJECTIVES_ERROR = 'GET_BUSINESS_OBJECTIVES__ERROR',

  RESET_SAVE_JOURNEY_DATA = 'RESET_SAVE_JOURNEY_DATA',
  GET_JOURNEY_MAPPING_STARTED = 'GET_JOURNEY_MAPPING_STARTED',
  GET_JOURNEY_MAPPING_SUCCESS = 'GET_JOURNEY_MAPPING_SUCCESS',
  GET_JOURNEY_MAPPING_ERROR = 'GET_JOURNEY_MAPPING_LIST_ERROR',
  DELETE_JOURNEY_MAPPING_STARTED = 'DELETE_JOURNEY_MAPPING_STARTED',
  DELETE_JOURNEY_MAPPING_SUCCESS = 'DELETE_JOURNEY_MAPPING_SUCCESS',
  DELETE_JOURNEY_MAPPING_ERROR = 'DELETE_JOURNEY_MAPPING_LIST_ERROR',

  RESET_JOURNEY_BUILDER_DATA = 'RESET_JOURNEY_BUILDER_DATA',
}

export type JourneyBuilderActionPayload =
  | Error
  | null
  | string
  | string[]
  | IJourneyList[]
  | IPredefinedJourneyListMapping[]
  | IJourneyCapabilitiesData[]
  | IJourneyCapabilitiesResponse
  | IJourneyBuilderPayload
  | ISubmitStatuspayload
  | IJourneyMarketplacePayload
  | IMarketplaceAssets[]
  | IJourneyCapabilityListPayload
  | IRecommendedAssetsResponse
  | IJourneySaveResponse
  | IJourneyMapping;

export type JourneyBuilderAction = IStoreAction<
  JourneyBuilderActionTypes,
  JourneyBuilderActionPayload
>;

export class JourneyBuilderActions {
  public static getJourneyListStart(): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_LIST_DATA_STARTED,
    };
  }
  public static getJourneyListSuccess(data: IJourneyList[]): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_LIST_DATA_SUCCESS,
      data,
    };
  }
  public static getJourneyListError(data: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_LIST_DATA_ERROR,
      data,
    };
  }

  public static getCapabilitiesForPredefinedJourneyStart(
    data: IJourneyCapabilityListPayload,
  ): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_STARTED,
      data,
    };
  }
  public static getCapabilitiesForPredefinedJourneySuccess(
    data: IJourneyCapabilitiesResponse,
  ): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_SUCCESS,
      data,
    };
  }
  public static getCapabilitiesForPredefinedJourneyError(data: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_CAPABILITES_FOR_PREDEFINED_JOURNEY_ERROR,
      data,
    };
  }

  public static getPredefinedJourneyListStart(): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_PREDEFINED_JOURNEY_LIST_STARTED,
    };
  }
  public static getPredefinedJourneyListSuccess(
    data: IPredefinedJourneyListMapping[],
  ): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_PREDEFINED_JOURNEY_LIST_SUCCESS,
      data,
    };
  }
  public static getPredefinedJourneyListError(data: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_PREDEFINED_JOURNEY_LIST_ERROR,
      data,
    };
  }

  public static getJourneyMappingtError(data: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_MAPPING_ERROR,
      data,
    };
  }

  public static getJourneyMappingSuccess(data: IJourneyMapping): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_MAPPING_SUCCESS,
      data,
    };
  }

  public static getJourneyMappingStart(data: string): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_MAPPING_STARTED,
      data,
    };
  }

  //Actions related to Marketplace Asset GET API call
  public static getJourneyMandatoryAssetsStart(
    data: IJourneyMarketplacePayload,
  ): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_MANDATORY_ASSETS_STARTED,
      data,
    };
  }
  public static getJourneyMandatoryAssetsSuccess(data: IMarketplaceAssets[]): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_MANDATORY_ASSETS_SUCCESS,
      data,
    };
  }
  public static getJourneyMandatoryAssetsError(data: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_MANDATORY_ASSETS_ERROR,
      data,
    };
  }
  public static getJourneyRecommendedAssetsStart(
    data: IJourneyMarketplacePayload,
  ): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_RECOMMENDED_ASSETS_STARTED,
      data,
    };
  }
  public static getJourneyRecommendedAssetsSuccess(
    data: IRecommendedAssetsResponse,
  ): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_RECOMMENDED_ASSETS_SUCCESS,
      data,
    };
  }
  public static getJourneyRecommendedAssetsError(data: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_JOURNEY_RECOMMENDED_ASSETS_ERROR,
      data,
    };
  }

  public static postJourneyBuilderSaveStart(data: IJourneyBuilderPayload): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.POST_JOURNEY_BUILDER_SAVE_STARTED,
      data,
    };
  }
  public static postJourneyBuilderSaveSuccess(data: IJourneySaveResponse): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.POST_JOURNEY_BUILDER_SAVE_SUCCESS,
      data,
    };
  }
  public static postJourneyBuilderSaveError(error: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.POST_JOURNEY_BUILDER_SAVE_ERROR,
      data: error,
    };
  }

  public static patchJourneyBuilderUpdateStart(data: IJourneyBuilderPayload): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.PATCH_JOURNEY_BUILDER_UPDATE_STARTED,
      data,
    };
  }
  public static patchJourneyBuilderUpdateSuccess(data: IJourneySaveResponse): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.PATCH_JOURNEY_BUILDER_UPDATE_SUCCESS,
      data,
    };
  }
  public static patchJourneyBuilderUpdateError(error: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.PATCH_JOURNEY_BUILDER_UPDATE_ERROR,
      data: error,
    };
  }

  public static resetJourneySaveData(): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.RESET_SAVE_JOURNEY_DATA,
    };
  }

  public static deleteDocumentDataStart(data: string): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.DELETE_DOCUMENT_DATA_STARTED,
      data,
    };
  }
  public static deleteDocumentDataSuccess(): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.DELETE_DOCUMENT_DATA_SUCCESS,
    };
  }
  public static deleteDocumentDataError(data: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.DELETE_DOCUMENT_DATA_ERROR,
      data,
    };
  }

  public static PostSubmitStatusStart(data: ISubmitStatuspayload): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.POST_SUBMIT_STATUS_STARTED,
      data,
    };
  }
  public static PostSubmitStatusSuccess(): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.POST_SUBMIT_STATUS_SUCCESS,
    };
  }
  public static PostSubmitStatusError(error: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.POST_SUBMIT_STATUS_ERROR,
      data: error,
    };
  }

  public static getBusinessObjectivesStart(): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_BUSINESS_OBJECTIVES__STARTED,
    };
  }
  public static getBusinessObjectivesSuccess(data: string[]): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_BUSINESS_OBJECTIVES_SUCCESS,
      data,
    };
  }
  public static getBusinessObjectivesError(data: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.GET_BUSINESS_OBJECTIVES_ERROR,
      data,
    };
  }
  public static deleteJourneyError(data: Error): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.DELETE_JOURNEY_MAPPING_ERROR,
      data,
    };
  }

  public static deleteJourneySuccess(data: IJourneySaveResponse): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.DELETE_JOURNEY_MAPPING_SUCCESS,
      data,
    };
  }

  public static deleteJourneyStart(data: string): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.DELETE_JOURNEY_MAPPING_STARTED,
      data,
    };
  }

  public static resetJourneyBuilderData(): JourneyBuilderAction {
    return {
      type: JourneyBuilderActionTypes.RESET_JOURNEY_BUILDER_DATA,
    };
  }
}
